import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import NoData from './NoData';

import styles from './TopFormFactors.styles';
function TopFormFactors({ topFormFactors, classes }) {
  return (
    <React.Fragment>
      <Typography variant="overline" align="center">
        Most Popular Form Factors
      </Typography>
      <Divider />
      {topFormFactors ? (
        <List className={classes.root}>
          {topFormFactors?.map((formFactorData, i) => (
            <ListItem key={i}>
              <ListItemText
                primary={formFactorData.formFactor.replaceAll('_', ' ')}
                secondary={formFactorData.noOfWearables + ' Wearables'}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <NoData />
      )}
    </React.Fragment>
  );
}

TopFormFactors.propTypes = {
  topFormFactors: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TopFormFactors);
