import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AccountSummary from './AccountSummary';
import AccountWearables from './AccountWearables';
import AccountActivity from './AccountActivity';

import styles from './AccountDetails.styles';

const AccountDetails = ({
  classes,
  accountData,
  wearablesData,
  processingWearables,
  getAccountActivity,
  processingAccountActivity,
  errorAccountActivity,
  accountActivityData,
  handleRevokeRefreshToken
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === 1 && !accountActivityData && !errorAccountActivity) {
      getAccountActivity();
    }
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.main}>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Account Summary" />
                <Tab label="Account Activity" />
              </Tabs>
              <div className={classes.tabBody}>
                {selectedTab === 0 && (
                  <>
                    <AccountSummary
                      {...accountData}
                      handleRevokeRefreshToken={handleRevokeRefreshToken}
                    />
                    {processingWearables ? (
                      <CircularProgress />
                    ) : (
                      <AccountWearables wearablesData={wearablesData} />
                    )}
                  </>
                )}
                {selectedTab === 1 && (
                  <AccountActivity
                    processingAccountActivity={processingAccountActivity}
                    errorAccountActivity={errorAccountActivity}
                    accountActivityData={accountActivityData}
                    getAccountActivity={getAccountActivity}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

AccountDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  accountData: PropTypes.shape({}).isRequired,
  wearablesData: PropTypes.arrayOf(PropTypes.object),
  processingWearables: PropTypes.bool,
  processingAccountActivity: PropTypes.bool,
  errorAccountActivity: PropTypes.string,
  accountActivityData: PropTypes.arrayOf(PropTypes.object),
  getAccountActivity: PropTypes.func.isRequired,
  handleRevokeRefreshToken: PropTypes.func.isRequired
};

export default withStyles(styles)(AccountDetails);
