const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  image: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    objectFit: 'contain',
    padding: 4,
    margin: '0 6px'
  }
});

export default styles;
