const styles = () => ({
  paper: {
    minHeight: 200,
    padding: '10px 20px',
    width: '100%',
    marginRight: 10,
    paddingBottom: 50
  }
});

export default styles;
