const styles = () => ({
  root: {
    minWidth: 275,
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },

  title: {
    fontSize: 14
  },
  detail: {
    fontSize: 16
  },
  iconContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center'
  },
  mobileDeviceDetailsContainer: {
    width: '90%'
  },
  mobileDeviceDetails: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

export default styles;
