const styles = (theme) => ({
  main: {
    padding: theme.spacing(3)
  },
  buttonsContainer: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  danger: {
    color: 'red'
  }
});

export default styles;
