import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import Login from './containers/login/LoginPage';
import Dashboard from './containers/dashboard/DashboardPage';
import OemsPage from './containers/oems/OemsPage';
import ClientsPage from './containers/clients/ClientsPage';
import ClientPage from './containers/clients/ClientPage';
import ClientEditPage from './containers/clients/ClientEditPage';
import ClientsCreatePage from './containers/clients/ClientsCreatePage';
import DevicesPage from './containers/devices/DevicesPage';
import DevicesCreate from './containers/devices/DevicesCreate';
import DeviceEditPage from './containers/devices/DeviceEditPage';
import DevicePage from './containers/devices/DevicePage';
import SupportWearables from './containers/support-wearables/SupportWearablesPage';
import WearableSearchPage from './containers/support-wearables/WearableSearchPage';
import SupportAccounts from './containers/support-accounts/SupportAccountsPage';
import AccountSearchPage from './containers/support-accounts/AccountSearchPage';
import EntitiesPage from './containers/entities/EntitiesPage';
import SystemPage from './containers/system/SystemPage';
import EntityPage from './containers/entities/EntityPage';
import EntitiesCreatePage from './containers/entities/EntitiesCreatePage';
import EntitiesEditPage from './containers/entities/EntitiesEditPage';
import MultosDetails from './containers/multos/MultosDetails';
import MultosConfigure from './containers/multos/MultosConfigure';
import SecurityDonglesPage from './containers/multos/SecurityDonglesPage';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return sessionStorage.getItem('tokenDetails') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
}

const AppRouter = ({ history }) => (
  <Router history={history}>
    <div>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/dashboard/oems" component={OemsPage} />
        <PrivateRoute exact path="/dashboard/clients" component={ClientsPage} />
        <PrivateRoute
          exact
          path="/dashboard/clients/create/:id"
          component={ClientsCreatePage}
        />
        <PrivateRoute
          exact
          path="/dashboard/clients/edit/:id"
          component={ClientEditPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/clients/client/:id"
          component={ClientPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/entities"
          component={EntitiesPage}
        />
        <PrivateRoute exact path="/dashboard/system" component={SystemPage} />
        <PrivateRoute
          exact
          path="/dashboard/entities/create"
          component={EntitiesCreatePage}
        />
        <PrivateRoute
          exact
          path="/dashboard/entities/:id"
          component={EntityPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/entities/edit/:id"
          component={EntitiesEditPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/devices/:oem"
          component={DevicesPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/devices/create/:oem"
          component={DevicesCreate}
        />
        <PrivateRoute
          exact
          path="/dashboard/device/brand/:brand/model/:model/version/:version"
          component={DevicePage}
        />
        <PrivateRoute
          exact
          path="/dashboard/devices/edit/brand/:brand/model/:model/version/:version"
          component={DeviceEditPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/support/wearables"
          component={SupportWearables}
        />
        <PrivateRoute
          exact
          path="/dashboard/support/accounts"
          component={SupportAccounts}
        />
        <PrivateRoute
          exact
          path="/dashboard/support/accounts/account/:email"
          component={AccountSearchPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/support/wearables/:type/:value"
          component={WearableSearchPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/utils/multos"
          component={SecurityDonglesPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/utils/multos/read/"
          component={MultosDetails}
        />
        <PrivateRoute
          exact
          path="/dashboard/utils/multos/configure/"
          component={MultosConfigure}
        />
        <PrivateRoute
          exact
          path="/dashboard/utils/multos/:uuid"
          component={MultosDetails}
        />

        <Route path="*" component={() => '404 NOT FOUND'} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
