const styles = () => ({
  loadingContainer: {
    height: '200px',
    margin: '8vh 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
});

export default styles;
