import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  CardContent,
  Typography,
  makeStyles,
  CircularProgress,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AlertDialog from '../../components/AlertDialog';
import ConfirmationTest from '../../components/ConfirmationTest';
import InputField from '../../components/InputField';
import { changeWearableEmailAction, getWearableAction } from './state/support-wearables-actions';

import styles from './WearableTools.styles';
import AdminContainer from '../../components/AdminContainerHOC';

const useStyles = makeStyles(styles);

const WearableTools = ({ email, asn }) => {
  const [newEmail, setNewEmail] = useState(email);
  const [editEmail, setEditEmail] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { processingChangeEmail, errorChangeWearableEmail, wearableEmailChanged } = useSelector((state) => state.supportWearables);

  useEffect(() => {
    if (errorChangeWearableEmail) {
      setNewEmail(email);
    }
    if (wearableEmailChanged) {
      dispatch(getWearableAction('asn', asn));
    }
  }, [wearableEmailChanged, errorChangeWearableEmail]);

  return (
    <CardContent className={classes.content}>
      <div className={classes.accountToolsContainer}>
        <div>
          <Typography color="textPrimary" variant="body2" gutterBottom>
            <strong>Wearable Tools</strong>
          </Typography>
        </div>
        <div className={classes.toolsContainer}>
          <List className={classes.list}>
            <AdminContainer type="internal">
              <ListItem role={undefined} divider>
                <ListItemText>
                  {!editEmail ? (
                    <span> Change wearable email address </span>
                  ) : (
                    <InputField
                      placeholder="New email address"
                      value={newEmail}
                      onChange={(event) => setNewEmail(event.target.value)}
                      className={classes.inputField}
                    />
                  )}
                </ListItemText>
                <ListItemSecondaryAction>
                  {!editEmail ? (
                    <IconButton edge="end" aria-label="next" onClick={() => setEditEmail(true)} disabled={processingChangeEmail}>
                      <ArrowRightIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        edge="end"
                        aria-label="next"
                        onClick={() => {
                          if (newEmail !== email) {
                            setShowAlert(true);
                          }
                          setEditEmail(false);
                        }}
                      >
                        {processingChangeEmail && <CircularProgress size={'2rem'} className={classes.processingChangeEmail} />}
                        <SaveIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="next" onClick={() => setEditEmail(false)} className={classes.cancelIcon}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </AdminContainer>
          </List>
          <AlertDialog
            showAlert={showAlert}
            disableActionButton={!confirmation}
            handleClose={() => {
              setShowAlert(false);
            }}
            title="Warning!"
            body1="Changing this wearable email will move the wearable to a new account. Do you still want to proceed? Please confirm by entering the new email again."
            component={<ConfirmationTest testString={newEmail} testResult={(result) => setConfirmation(result)} />}
            actionButtonLabel="confirm"
            handleAction={() => {
              if (confirmation) {
                setShowAlert(false);
                dispatch(changeWearableEmailAction(asn, newEmail));
              }
            }}
          />
        </div>
        {errorChangeWearableEmail && (
          <Typography variant="caption" color="error">
            {errorChangeWearableEmail || 'Error please try again later'}
          </Typography>
        )}
      </div>
    </CardContent>
  );
};

WearableTools.propTypes = {
  email: PropTypes.string.isRequired,
  asn: PropTypes.string
};

export default WearableTools;
