import React, { useEffect, useState } from 'react';
import { decodeJwt } from '../utils/cryptography';

const AdminContainer = ({ type, mode, children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [authorities, setAuthorities] = useState(null);

  useEffect(() => {
    function getRoleFromToken() {
      const token = sessionStorage.getItem('tokenDetails');
      if (token) {
        const { authorities, entity_id } = decodeJwt(token);

        if (entity_id) {
          setEntityId(entity_id);
        }

        if (authorities?.length > 0) {
          setAuthorities(authorities);
        }
      }
    }

    getRoleFromToken();
  }, []);

  useEffect(() => {
    if (authorities) {
      if (
        authorities.some(
          (e) =>
            e === 'ROLE_INTERNAL_ADMIN' &&
            type === 'internal' &&
            mode !== 'readOnly'
        )
      ) {
        setIsAdmin(true);
      }

      if (
        authorities.some(
          (e) =>
            e === 'ROLE_INTERNAL_ADMIN_READ_ONLY' &&
            type === 'internal' &&
            mode === 'readOnly'
        )
      ) {
        setIsAdmin(true);
      }

      if (
        authorities.some(
          (e) =>
            e === 'ROLE_EXTERNAL_ADMIN' &&
            type === 'external' &&
            mode !== 'readOnly'
        )
      ) {
        setIsAdmin(true);
      }

      if (
        authorities.some(
          (e) =>
            e === 'ROLE_EXTERNAL_ADMIN_READ_ONLY' &&
            type === 'external' &&
            mode === 'readOnly'
        )
      ) {
        setIsAdmin(true);
      }
    }
  }, [authorities]);

  return isAdmin
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, {
          entityid: entityId
        })
      )
    : null;
};

export default AdminContainer;
