import React from 'react';
import PropTypes from 'prop-types';

import HistoryEvent from '../../components/HistoryEvent';
import { withStyles } from '@material-ui/core';

import styles from './HistoryMonth.styles';

const HistoryMonth = ({ classes, data }) => {
  return (
    <div className={classes.mainContainer}>
      <HistoryEvent historyData={data.history} />
    </div>
  );
};

HistoryMonth.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    fromDate: PropTypes.number,
    toDate: PropTypes.number,
    history: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default withStyles(styles)(HistoryMonth);
