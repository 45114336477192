import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_FILTERED_WEARABLES_REQUEST,
  GET_FILTERED_WEARABLES_FAILURE,
  GET_FILTERED_WEARABLES_SUCCESS
} from './constants';

const defaultState = {
  countData: null,
  filteredWearables: [],
  processing: false,
  processingFilteredWearables: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        countData: action.response.data
      };
    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        processing: false,
        error: 'Error getting dashboard data'
      };
    case GET_FILTERED_WEARABLES_REQUEST:
      return {
        ...state,
        processingFilteredWearables: true,
        error: ''
      };
    case GET_FILTERED_WEARABLES_SUCCESS:
      return {
        ...state,
        processingFilteredWearables: false,
        error: '',
        filteredWearables: action.response.data
      };
    case GET_FILTERED_WEARABLES_FAILURE:
      return {
        ...state,
        processingFilteredWearables: false,
        error: 'Error getting dashboard data'
      };
    default:
      return state;
  }
}
