import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Typography, TablePagination } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import HeaderWithButton from '../../components/HeaderWithButton';

import imageNotFound from '../../images/imageNotFound.png';

import styles from './DevicesOverview.styles';

function handleImageLoadError(ev) {
  ev.target.src = imageNotFound;
}

const CollapsedRow = ({ row, classes, details, history }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableRow
        hover
        onClick={() => {
          row.versions > 1
            ? setOpen(!open)
            : history.push(
                `/dashboard/device/brand/${details[0].brand}/model/${details[0].model?.name}/version/${details[0].model?.version}`
              );
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={row.defaultImage}
              alt="device default"
              onError={handleImageLoadError}
            />
          </div>
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.versions}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table className={classes.table} aria-label="devices-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>TDP</TableCell>
                    <TableCell>TDP Identifier</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Variants</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((detailRow, i) => (
                    <TableRow
                      key={i}
                      hover
                      onClick={() => {
                        history.push(
                          `/dashboard/device/brand/${detailRow.brand}/model/${detailRow.model?.name}/version/${detailRow.model?.version}`
                        );
                      }}
                    >
                      <TableCell>{detailRow.model?.version}</TableCell>
                      <TableCell>{detailRow.tdpId}</TableCell>
                      <TableCell>{detailRow.tdpIdentifier}</TableCell>
                      <TableCell>{detailRow.type}</TableCell>
                      <TableCell>{detailRow.variants?.length}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsedRow.propTypes = {
  row: PropTypes.shape({
    defaultImage: PropTypes.string,
    name: PropTypes.string,
    versions: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired,
  details: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired
};

const DevicesOverview = ({ classes, entityName, devicesData, error }) => {
  function groupByObjectProperty(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      let key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const devicesByModel =
    devicesData && groupByObjectProperty(devicesData, 'modelDisplayName');

  const devicesByModelMapped =
    devicesByModel &&
    Object.keys(devicesByModel).map((e) => {
      return {
        defaultImage: devicesByModel[e][0].defaultImage,
        name: e,
        versions: devicesByModel[e].length.toString()
      };
    });

  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <HeaderWithButton
        headerText="Devices"
        buttonLabel="Add a device"
        onClick={() => history.push(`/dashboard/devices/create/${entityName}`)}
      />

      {devicesByModelMapped && (
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="devices-table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">Default Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Versions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devicesByModelMapped
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <CollapsedRow
                      history={history}
                      classes={classes}
                      row={row}
                      key={i}
                      details={devicesByModel[row.name]}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component="div"
            count={devicesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      {error && <Typography>{error}</Typography>}
    </div>
  );
};

DevicesOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  devicesData: PropTypes.arrayOf(PropTypes.object),
  entityName: PropTypes.string,
  error: PropTypes.string
};

export default withStyles(styles)(DevicesOverview);
