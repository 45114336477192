import React from 'react';
import PropTypes from 'prop-types';
import HistoryEvent from '../../components/HistoryEvent';

const ServiceHistory = ({ serviceHistory }) => {
  return <>{serviceHistory ? <HistoryEvent historyData={serviceHistory} /> : null}</>;
};

ServiceHistory.propTypes = {
  serviceHistory: PropTypes.arrayOf(PropTypes.object),
  errorServiceHistory: PropTypes.string
};

export default ServiceHistory;
