import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import DashboardHOC from '../../components/DashboardHOC';
import HeaderWithButton from '../../components/HeaderWithButton';
import DevicesOverview from '../devices/DevicesOverview';
import ClientsOverview from '../clients/ClientsOverview';

import { getEntityAction } from './state/entities-actions';
import { getDevicesAction } from '../devices/state/devices-actions';
import { getClientsAction } from '../clients/state/clients-actions';
import LoadingIndicator from '../../components/LoadingIndicator';

import styles from './EntityPage.styles';
import ThemePreview from './components/ThemePreview';
import ThemePalette from './components/ThemePalette';

import { exportToJsonFile } from '../../utils/utils';

const EntityPage = ({ match, classes, history }) => {
  const [showThemePreview, setShowThemePreview] = useState(false);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const entitiesState = useSelector((state) => state.entities);
  const devicesState = useSelector((state) => state.devices);
  const clientsState = useSelector((state) => state.clients);

  const { devicesData } = devicesState;
  const { clientsData } = clientsState;

  const { entity } = entitiesState;

  const { params } = match;

  useEffect(() => {
    stableDispatch(getEntityAction(params.id));
    if (params.id !== 'pending') {
      stableDispatch(getDevicesAction(params.id));
    }
    stableDispatch(getClientsAction(params.id));
  }, [params, stableDispatch]);

  return (
    <DashboardHOC>
      <HeaderWithButton
        headerText={`Entity Details`}
        buttonLabel={'edit'}
        onClick={() => history.push(`/dashboard/entities/edit/${entity.id}`)}
        buttonLabelSecondary={'Export entity'}
        onClickSecondary={() => exportToJsonFile(entity, `${entity.id}.json`)}
        ExtraButton={() => {
          if (entity?.theme && params.id !== 'pending')
            return (
              <Button className={classes.button} onClick={() => setShowThemePreview(!showThemePreview)} variant="outlined" color="primary">
                {`${showThemePreview ? 'Hide' : 'Show'} Entity Theme`}
              </Button>
            );
          return null;
        }}
      />
      <div>
        {entity?.name && (
          <Typography variant="h5" gutterBottom color="primary">
            {entity.name}
          </Typography>
        )}
      </div>

      <section>
        {showThemePreview && (
          <div className={classes.themePreviewContainer}>
            <div className={classes.themePaletteContainer}>
              <Typography variant="h6" gutterBottom>
                Theme Palette
              </Typography>
              <ThemePalette theme={entity.theme} logo={entity.logo} />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Theme Preview
              </Typography>
              <ThemePreview theme={entity.theme} />
            </div>
          </div>
        )}
        <div>
          {devicesState.processing ? (
            <LoadingIndicator title="Loading Devices" />
          ) : (
            params.id !== 'pending' && (
              <div className={classes.overviewContainer}>
                <DevicesOverview entityName={entity?.id} devicesData={devicesData} error={devicesState.error} />
              </div>
            )
          )}
          {clientsState.processing ? (
            <LoadingIndicator title="Loading Applications" />
          ) : (
            <div className={classes.overviewContainer}>
              <ClientsOverview entityId={params.id} clientsData={clientsData} error={clientsState.error} />
            </div>
          )}
        </div>
      </section>
    </DashboardHOC>
  );
};

EntityPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func
  }).isRequired
};

export default withStyles(styles)(EntityPage);
