const styles = (theme) => ({
  root: {
    maxWidth: '800px'
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  divider: { margin: '10px 0' },
  sectionDivider: {
    height: 3,
    margin: '20px 0'
  },
  image: {
    border: '1px solid #ddd',
    width: 343,
    height: 150,
    objectFit: 'contain'
  }
});

export default styles;
