import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import WearableHistory from './WearableHistory';
import ServiceDetails from './ServiceDetails';
import WearableSummary from './WearableSummary';

import styles from './WearableDetails.styles';

const WearableDetails = ({
  searchPeriod,
  classes,
  error,
  wearableData,
  wearableDetails,
  wearableDetailsProcessing,
  getHistory,
  hasMoreHistory,
  wearableHistoryData,
  processingHistory,
  processing
}) => {
  const [selectedTab, setSelectedTab] = React.useState(wearableData ? 0 : 1);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              <Typography variant="h6" gutterBottom>
                Wearable Details
              </Typography>
              <Tabs value={selectedTab} onChange={handleChange} indicatorColor="primary" textColor="primary">
                <Tab label="Summary" disabled={Boolean(!wearableData)} />
                <Tab label="Recent Wearable History" />
              </Tabs>
              <div className={classes.tabBody}>
                {selectedTab === 0 && wearableData && (
                  <div>
                    <WearableSummary
                      wearableImage={wearableDetails?.variants?.[0]?.image || wearableDetails?.defaultImage}
                      wearableDetailsProcessing={wearableDetailsProcessing}
                      {...wearableData}
                    />
                    <Typography variant="h6" gutterBottom>
                      Services
                    </Typography>
                    <ServiceDetails processing={processing} rows={wearableData?.services} />
                  </div>
                )}
                {selectedTab === 1 && (
                  <>
                    {error && (
                      <Typography align="center" color="error" gutterBottom>
                        {error}
                      </Typography>
                    )}
                    <WearableHistory
                      searchPeriod={searchPeriod}
                      processingHistory={processingHistory}
                      getHistory={getHistory}
                      hasMoreHistory={hasMoreHistory}
                      wearableHistoryData={wearableHistoryData}
                    />
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

WearableDetails.propTypes = {
  searchPeriod: PropTypes.object,
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  wearableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  wearableDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  getHistory: PropTypes.func.isRequired,
  hasMoreHistory: PropTypes.bool.isRequired,
  wearableHistoryData: PropTypes.arrayOf(PropTypes.object),
  processing: PropTypes.bool,
  processingHistory: PropTypes.bool,
  wearableDetailsProcessing: PropTypes.bool
};

export default withStyles(styles)(WearableDetails);
