const styles = {
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderTop: '1px solid #ddd'
  },
  accountToolsContainer: {
    width: '90%'
  },
  toolsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  inputField: { width: '80%' },
  list: { width: '50%' },
  cancelIcon: { color: '#f44336', marginLeft: 10 },
  processingChangeEmail: { position: 'absolute', top: '50%', left: '50%', marginTop: -16, marginLeft: -16, color: 'green' }
};

export default styles;
