import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './NoData.styles';

const NoData = ({ classes }) => {
  return (
    <div className={classes.noData}>
      <Typography variant="body2" gutterBottom>
        NO AVAILABLE DATA
      </Typography>
    </div>
  );
};

NoData.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NoData);
