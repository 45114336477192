import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Container, Paper, Typography, CircularProgress } from '@material-ui/core';

import DashboardHOC from '../../components/DashboardHOC';
import InputField from '../../components/InputField';
import LoadingIndicator from '../../components/LoadingIndicator';

import styles from './MultosDetails.styles';
import useSerialApi from '../../utils/serial-handler';

const MultosDetails = ({ classes }) => {
  const {
    readMultosDevice,
    updateClientCredentials,
    publicKey,
    multosClientId,
    multosSerial,
    clientSecret,
    setupInProgress, 
    status
  } = useSerialApi();

  const [multosSerialNumber, setMultosSerialNumber] = useState('');
  const [clientId, setClientID] = useState('');
  const [secret, setSecret] = useState('');

  return (
    <DashboardHOC>
      <Container component={Paper} className={classes.main}>
        <Typography variant="h6" gutterBottom>
          Multos Dongle Details
        </Typography>
        <Typography gutterBottom>
          <strong>Serial: </strong>
          {setupInProgress && !multosSerial ? <CircularProgress size={14} /> : multosSerial}
        </Typography>
        <Typography gutterBottom>
          <strong>Client ID: </strong>
          {setupInProgress && !multosClientId ? <CircularProgress size={14} /> : multosClientId}
        </Typography>
        <Typography gutterBottom>
          <strong>Client Secret: </strong>
          {setupInProgress && !clientSecret ? <CircularProgress size={14} /> : clientSecret}
        </Typography>
        <Typography gutterBottom>
          <strong>Public Key: </strong>
          {setupInProgress && !publicKey ? <CircularProgress size={14} /> : publicKey}
        </Typography>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => {
              try {
                readMultosDevice();
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Read device
          </Button>
        </div>
        {setupInProgress ? (
          <div className={classes.loadingStyle}>
            <LoadingIndicator title={status} />
          </div>
        ) : (
          <div>
                 <div>
              <Typography gutterBottom>Multos USB Serial Number</Typography>
              <InputField
                id="multosSerialNumber"
                name="multosSerialNumber"
                type="text"
                value={multosSerialNumber}
                onChange={(event) => setMultosSerialNumber(event.target.value)}
              />
            </div>
            <div>
              <Typography gutterBottom>Client Id</Typography>
              <InputField
                id="clientId"
                name="clientId"
                type="text"
                autoComplete="off"
                value={clientId}
                onChange={(event) => setClientID(event.target.value)}
              />
            </div>
            <div>
              <Typography gutterBottom>Secret</Typography>
              <InputField
                id="secret"
                name="secret"
                type="text"
                autoComplete="off"
                value={secret}
                onChange={(event) => setSecret(event.target.value)}
              />
            </div>
            <div className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={() => {
                  try {
                    const updateClientCredentialsResult =  updateClientCredentials(multosSerialNumber, clientId, secret);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                Write Credentials
              </Button>
            </div>
          </div>
        )}
      </Container>
    </DashboardHOC>
  );
};

MultosDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MultosDetails);
