import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import EnhancedTable from '../../../../components/enhanced-table/EnhancedTable';

import styles from './ErrorLogsTable.styles';

const ErrorLogsTable = ({ classes, errors, title }) => {
  const columns = [
    { title: 'Date', field: 'timestamp', type: 'datetime' },
    { title: 'Event', field: 'event' },
    { title: 'Message', field: 'message' },
    { title: 'Client / User', field: 'clientUser' },
    { title: 'Level', field: 'logLevel' }
  ];

  return (
    <div className={classes.mainContainer}>
      <Paper>
        <EnhancedTable
          title={title}
          columns={columns}
          options={{
            rowStyle: (row) => ({
              color: `${row.logLevel === 'CRITICAL' ? 'red' : '#484848'}`
            }),
            pageSizeOptions: [5, 10, 20, 50, 100]
          }}
          dataArr={errors}
          onRowClick={(event, rowData) => console.log({ rowData })}
        />
      </Paper>
    </div>
  );
};

ErrorLogsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string
};

export default withStyles(styles)(ErrorLogsTable);
