import {
  GET_SECURITY_DONGLES_REQUEST,
  GET_SECURITY_DONGLES_SUCCESS,
  GET_SECURITY_DONGLES_FAILURE,
  CREATE_SECURITY_DONGLES_REQUEST,
  CREATE_SECURITY_DONGLES_SUCCESS,
  CREATE_SECURITY_DONGLES_FAILURE
} from './constants';

const defaultState = {
  securityDonglesData: null,
  processing: false,
  error: '',
  createdDongleData: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_SECURITY_DONGLES_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_SECURITY_DONGLES_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        securityDonglesData: action.response.data
      };
    case GET_SECURITY_DONGLES_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting clients data'
      };
    case CREATE_SECURITY_DONGLES_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case CREATE_SECURITY_DONGLES_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        createdDongleData: action.response.data
      };
    case CREATE_SECURITY_DONGLES_FAILURE:
      return {
        ...state,
        processing: false,
        error: 'Error creating a new client.'
      };
    default:
      return state;
  }
}
