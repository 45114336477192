const styles = () => ({
  card: {
    maxWidth: 800
  },
  actions: {
    float: 'right'
  },
  input: {
    display: 'none'
  },
  uploadImage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '20px 0'
  },
  image: {
    width: 100,
    heigth: 100,
    objectFit: 'contain',
    margin: '0 20px'
  },
  variantContainer: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  variantInput: { margin: '0 10px' },
  addVariantButton: { marginTop: '15px' },
  error: {
    margin: '20px 0'
  }
});

export default styles;
