import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import HistoryEvent from '../../components/HistoryEvent';
import LoadingIndicator from '../../components/LoadingIndicator';

import styles from './AccountActivity.styles';

const AccountActivity = ({
  classes,
  accountActivityData,
  errorAccountActivity,
  processingAccountActivity
}) => {
  if (processingAccountActivity) {
    return (
      <div>
        <LoadingIndicator title="Getting Account History" />
      </div>
    );
  }

  if (errorAccountActivity) {
    return (
      <div>
        <Typography gutterBottom>{errorAccountActivity}</Typography>
      </div>
    );
  }

  if (accountActivityData) {
    return (
      <Paper className={classes.accountActivityContainer}>
        <HistoryEvent historyData={accountActivityData} />
      </Paper>
    );
  } else return null;
};

AccountActivity.propTypes = {
  accountActivityData: PropTypes.arrayOf(PropTypes.object),
  errorAccountActivity: PropTypes.string,
  processingAccountActivity: PropTypes.bool.isRequired
};

export default withStyles(styles)(AccountActivity);
