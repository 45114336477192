import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DashboardHOC from '../../components/DashboardHOC';
import WearableDetails from './WearableDetails';
import LoadingIndicator from '../../components/LoadingIndicator';

import { getWearableHistoryAction, getWearableAction, getWearableByBrandAction } from './state/support-wearables-actions';

const WearableSearchPage = ({ match }) => {
  const dispatch = useDispatch();

  const counter = useRef(10);
  const interval = useRef(10);

  const range = useRef({});

  const [wearableHistoryData, setWearableHistoryData] = useState([]);

  const supportWearablesState = useSelector((state) => state.supportWearables);
  const wearableDetailsState = useSelector((state) => state.wearableDetails);

  const { params } = match;

  function getHistory() {
    counter.current = counter.current + interval.current;

    if (counter.current <= 180 && (supportWearablesState.wearableData?.asn || params?.value)) {
      dispatch(
        getWearableHistoryAction(supportWearablesState.wearableData?.asn || params?.value, range.current.fromDate, range.current.toDate)
      );
    }
  }

  useEffect(() => {
    range.current.fromDate = moment()
      .subtract(counter.current, 'days')
      .valueOf();

    range.current.toDate = moment()
      .subtract(counter.current - (counter.current === 30 ? 10 : interval.current), 'days')
      .valueOf();
  });

  useEffect(() => {
    if (counter.current === 30) {
      interval.current = 30;
    }
  });

  const { wearableData, wearableHistory, processing, processingHistory, error } = supportWearablesState;

  useEffect(() => {
    dispatch(getWearableAction(params.type, params.value));
  }, [params]);

  useEffect(() => {
    if (supportWearablesState.wearableData?.device) {
      const { brand, model, version, variant } = supportWearablesState.wearableData?.device;
      dispatch(getWearableByBrandAction(brand, model, version, variant?.color));
    }
  }, [supportWearablesState.wearableData]);

  useEffect(() => {
    if (wearableHistory && wearableHistory.length > 0) {
      setWearableHistoryData((prev) => [
        ...prev,
        { fromDate: range.current.fromDate, toDate: range.current.toDate, history: [...wearableHistory] }
      ]);
    }

    if (supportWearablesState.wearableData?.asn && wearableHistory?.length === 0 && counter.current <= 180) {
      getHistory();
    }
  }, [wearableHistory]);

  return (
    <DashboardHOC>
      {processing ? (
        <LoadingIndicator title="Getting wearable details" />
      ) : (
        <WearableDetails
          searchPeriod={{
            fromDate: moment(range.current.fromDate).format('DD MMM YYYY'),
            toDate: moment(range.current.toDate).format('DD MMM YYYY')
          }}
          error={error}
          wearableData={wearableData}
          wearableDetails={wearableDetailsState.data}
          wearableDetailsProcessing={wearableDetailsState.processing}
          getHistory={getHistory}
          wearableHistoryData={wearableHistoryData}
          processing={processing}
          processingHistory={processingHistory}
          hasMoreHistory={counter.current <= 180}
        />
      )}
    </DashboardHOC>
  );
};

WearableSearchPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default WearableSearchPage;
