import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button, CircularProgress } from '@material-ui/core';
import {} from '@material-ui/styles';

import styles from './MaintenanceWhitelist.styles';

function MaintenanceWhitelist({
  processing,
  whitelist,
  onUpdateWhitelist,
  type,
  classes
}) {
  const [edit, setEdit] = useState();
  const [newValue, setNewValue] = useState('');
  const [addNewValue, setAddNewValue] = useState(false);
  const [list, setList] = useState(whitelist.sort());

  useEffect(() => {
    setList(whitelist);
  }, [whitelist]);

  const onUpdateValue = () => {
    const newArr = list.filter((e) => e !== edit);
    newArr.push(newValue);

    if (newValue !== edit) {
      onUpdateWhitelist(newArr.sort(), type);
    }
  };

  const onDeleteValue = (value) => {
    const newArr = list.filter((e) => e !== value);
    onUpdateWhitelist(newArr, type);
  };

  const onAddNewValue = (value) => {
    const newArr = [...list];
    newArr.push(value);
    onUpdateWhitelist(newArr.sort(), type);
  };

  return (
    <>
      {!addNewValue && (
        <Button
          className={classes.addNewButton}
          onClick={() => {
            setNewValue('');
            setEdit(null);
            setAddNewValue(true);
          }}
          variant="outlined"
        >
          Add new
        </Button>
      )}

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <div className={classes.listHeaderContainer}>
            <div>
              <ListSubheader component="div" id="nested-list-subheader">
                Whitelist {type}
              </ListSubheader>
            </div>
            <div>
              <ListSubheader component="div" id="nested-list-subheader">
                Action
              </ListSubheader>
            </div>
          </div>
        }
        className={classes.list}
      >
        {list.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem divider key={value} role={undefined} dense>
              {edit === value ? (
                <TextField
                  fullWidth
                  value={newValue}
                  id="input-field"
                  label={type === 'users' ? 'email' : 'client id'}
                  onChange={(e) => setNewValue(e.target.value)}
                />
              ) : (
                <ListItemText id={labelId} primary={`${value}`} />
              )}
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    if (edit === value) {
                      setEdit(null);
                      onUpdateValue(value);
                    } else {
                      setAddNewValue(false);
                      setNewValue(value);
                      setEdit(value);
                    }
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  {edit === value ? (
                    <SaveIcon className={classes.saveIcon} />
                  ) : (
                    <EditIcon />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => onDeleteValue(value)}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        {addNewValue && (
          <ListItem divider dense>
            <TextField
              fullWidth
              value={newValue}
              id="input-field"
              label={type === 'users' ? 'email' : 'client id'}
              onChange={(e) => setNewValue(e.target.value)}
            />

            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  onAddNewValue(newValue);
                  setAddNewValue(false);
                }}
                edge="end"
                aria-label="comments"
              >
                <SaveIcon className={classes.saveIcon} />
              </IconButton>
              <IconButton
                onClick={() => setAddNewValue(false)}
                edge="end"
                aria-label="comments"
              >
                <CancelIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
      {processing && <CircularProgress />}
    </>
  );
}

MaintenanceWhitelist.propTypes = {
  processing: PropTypes.bool.isRequired,
  whitelist: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdateWhitelist: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MaintenanceWhitelist);
