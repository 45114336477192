import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  GET_ACCOUNT_ACTIVITY_REQUEST,
  GET_ACCOUNT_ACTIVITY_SUCCESS,
  GET_ACCOUNT_ACTIVITY_FAILURE,
  REVOKE_REFRESH_TOKEN_REQUEST,
  REVOKE_REFRESH_TOKEN_SUCCESS,
  REVOKE_REFRESH_TOKEN_FAILURE,
  SEND_RESET_PASSWORD_EMAIL_REQUEST,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_FAILURE,
  RESET_ACCOUNT
} from './constants';

const url = getApiUrl();

export function resetAccountAction() {
  return {
    type: RESET_ACCOUNT
  };
}

export function getAccountAction(email) {
  return {
    types: [GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE],
    callAPI: () => getAccount(email)
  };
}

export function revokeRefreshTokenAction(email) {
  return {
    types: [REVOKE_REFRESH_TOKEN_REQUEST, REVOKE_REFRESH_TOKEN_SUCCESS, REVOKE_REFRESH_TOKEN_FAILURE],
    callAPI: () => revokeRefreshToken(email)
  };
}

export function getAccountActivityAction(email) {
  return {
    types: [GET_ACCOUNT_ACTIVITY_REQUEST, GET_ACCOUNT_ACTIVITY_SUCCESS, GET_ACCOUNT_ACTIVITY_FAILURE],
    callAPI: () => getAccountActivity(email)
  };
}

export function sendEmailPasswordResetAction(email, clientId) {
  return {
    types: [SEND_RESET_PASSWORD_EMAIL_REQUEST, SEND_RESET_PASSWORD_EMAIL_SUCCESS, SEND_RESET_PASSWORD_EMAIL_FAILURE],
    callAPI: () => sendEmailPasswordReset(email, clientId)
  };
}

const getAccount = (email) => {
  return httpClient(`${url}/admin/accounts/${email}`, {
    method: 'get'
  });
};

const revokeRefreshToken = (email) => {
  return httpClient(`${url}/admin/accounts/revoke`, {
    method: 'post',
    data: { email }
  });
};

const getAccountActivity = (email) => {
  return httpClient(`${url}/admin/accounts/logs/${email}`, {
    method: 'get'
  });
};

const sendEmailPasswordReset = (email, clientId) => {
  return httpClient(`${url}/security/accounts/resetPassword/${email}?clientId=${clientId}`, {
    method: 'get'
  });
};
