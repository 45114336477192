import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography, Divider, withStyles, Button } from '@material-ui/core';

import HeaderWithButton from '../../components/HeaderWithButton';
import ErrorText from '../../components/ErrorText';
import VariantsList from './VariantsList';

import imageNotFound from '../../images/imageNotFound.png';

import styles from './DeviceDetails.styles';

const DeviceDetails = ({ classes, deviceData, handleExportDevice, handleDeleteDevice }) => {
  let history = useHistory();
  const {
    brand,
    defaultImage,
    brandDisplayName,
    modelDisplayName,
    model,
    type,
    typeCode,
    tdpId,
    tdpIdentifier,
    variants,
    description,
    storeLink,
    brandMobileVideoTrailer,
    excludedFromWebsite,
    featuredOnWebsite,
    rcosNotSupported,
    tokenisationNotSupported,
    promoReadyNotSupported
  } = deviceData;

  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }
  return (
    <div className={classes.root}>
      <HeaderWithButton
        headerText={`Device Details`}
        buttonLabel={'edit'}
        onClick={() => history.push(`/dashboard/devices/edit/brand/${brand}/model/${model.name}/version/${model.version}`)}
        onClickSecondary={handleDeleteDevice}
        buttonLabelSecondary="Delete"
        warning
        ExtraButton={() => (
          <Button className={classes.button} onClick={handleExportDevice} variant="outlined" color="primary">
            {'Export as zip'}
          </Button>
        )}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Typography variant="body2" gutterBottom>
              Default Image
            </Typography>
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={`${defaultImage}?${performance.now()}`}
                alt="default img"
                onError={handleImageLoadError}
              />
            </div>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Brand
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {brandDisplayName}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Model
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {modelDisplayName}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Version
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {model.version}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Type
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {type}
            </Typography>
            <Divider className={classes.divider} />

            <Typography variant="body2" gutterBottom>
              Type Code
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {typeCode || <ErrorText error="missing" />}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              TDP
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {tdpId}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              TDP Identifier
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {tdpIdentifier || <ErrorText error="missing" />}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {description || <ErrorText error="missing" />}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Video Trailer
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {brandMobileVideoTrailer || <ErrorText error="missing" />}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Store Link
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {storeLink || <ErrorText error="missing" />}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Rcos supported
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {!rcosNotSupported ? 'yes' : 'no'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Tokenisation supported
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {!tokenisationNotSupported ? 'yes' : 'no'}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Promo Ready supported
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {!promoReadyNotSupported ? 'yes' : 'no'}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Excluded from website
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {excludedFromWebsite ? 'yes' : 'no'}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Featured on website
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {featuredOnWebsite ? 'yes' : 'no'}
            </Typography>
            <Divider className={classes.divider} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            {variants ? (
              <VariantsList variants={variants} />
            ) : (
              <Typography variant="body1" color="textPrimary" gutterBottom>
                No variants available
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

DeviceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleExportDevice: PropTypes.func.isRequired,
  handleDeleteDevice: PropTypes.func.isRequired,

  deviceData: PropTypes.shape({
    brand: PropTypes.string,
    defaultImage: PropTypes.string,
    brandDisplayName: PropTypes.string,
    modelDisplayName: PropTypes.string,
    model: PropTypes.shape({
      name: PropTypes.string,
      version: PropTypes.string
    }),
    description: PropTypes.string,
    storeLink: PropTypes.string,
    brandMobileVideoTrailer: PropTypes.string,
    excludedFromWebsite: PropTypes.bool,
    featuredOnWebsite: PropTypes.bool,
    rcosNotSupported: PropTypes.bool,
    promoReadyNotSupported: PropTypes.bool,
    type: PropTypes.string,
    typeCode: PropTypes.string,
    tdpId: PropTypes.number,
    tdpIdentifier: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default withStyles(styles)(DeviceDetails);
