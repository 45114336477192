import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import styles from './ErrorAlert.styles';

const ErrorAlert = ({ classes, title, error, size, ...restProps }) => {
  const width = {
    small: '200px',
    large: '600px'
  };

  return (
    <div
      className={classes.root}
      style={{
        width: size ? width[size] : '600px'
      }}
    >
      <Alert variant="outlined" severity="error" {...restProps}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {error}
      </Alert>
    </div>
  );
};

ErrorAlert.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ErrorAlert);
