import React from 'react';

import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import AddOutlined from '@material-ui/icons/AddOutlined';
import RemoveCircle from '@material-ui/icons/Remove';
import PowerSettingIcon from '@material-ui/icons/PowerSettingsNew';
import Cached from '@material-ui/icons/Cached';
import PersonAdd from '@material-ui/icons/PersonAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EmailIcon from '@material-ui/icons/Email';
import Info from '@material-ui/icons/Info';
import Contactless from '@material-ui/icons/Contactless';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const getIcon = (event) => {
  switch (event) {
    case 'Wearable_Added':
      return <AddOutlined color="primary" />;
    case 'Service_Added':
      return <AddOutlined color="primary" />;
    case 'Service_Personalised':
      return <CheckBoxIcon color="primary" />;
    case 'Requested_Activation':
      return <Cached color="primary" />;
    case 'Service_Activated':
      return <PowerSettingIcon color="primary" />;
    case 'Paired':
      return <LinkIcon color="primary" />;
    case 'Personalisation_Failed':
      return <ClearIcon color="error" />;
    case 'Service_Disabled':
      return <ToggleOffIcon color="error" />;
    case 'Service_Enabled':
      return <ToggleOnIcon color="secondary" />;
    case 'Wearable_Removed':
      return <RemoveCircle color="error" />;
    case 'Attempting_Personalisation':
      return <Cached ccolor="error" />;
    case 'Attempting_Clean':
      return <Cached color="error" />;
    case 'Unpaired':
      return <LinkOffIcon color="error" />;
    case 'Cleaned':
      return <CheckBoxIcon color="primary" />;
    case 'Service_Removed':
      return <DeleteIcon color="error" />;
    case 'Enabled_Externally':
      return <ToggleOnIcon color="primary" />;
    case 'Deleted_Externally':
      return <DeleteIcon color="error" />;
    case 'Transaction':
      return <Contactless color="primary" />;
    case 'Suspended_Externally':
      return <ToggleOffIcon color="error" />;

    //------------------------------------------

    case 'Signed_Up':
      return <PersonAdd color="primary" />;
    case 'Activated_Account':
      return <VerifiedUserIcon color="primary" />;
    case 'Sent_Sign_Up_Email':
      return <EmailIcon color="primary" />;
    case 'Sent_Reset_Password_Email':
      return <EmailIcon color="primary" />;
    case 'Password_Reset_Sucesfully':
      return <CheckBoxIcon color="primary" />;
    case 'Account_Deleted':
      return <DeleteIcon color="error" />;
    case 'Logged_In':
      return <VpnKeyIcon color="primary" />;
    case 'Logged_Out':
      return <ExitToAppIcon color="error" />;
    default:
      return <Info color="primary" />;
  }
};

export default getIcon;
