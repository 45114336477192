import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './ErrorText.styles';

const ErrorText = ({ error, classes }) => {
  return <span className={classes.errorText}>{error}</span>;
};

ErrorText.propTypes = {
  error: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ErrorText);
