const styles = () => ({
  content: {
    margin: '20px 0',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },

  title: {
    fontSize: 14
  },
  detail: {
    fontSize: 16
  }
});

export default styles;
