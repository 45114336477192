import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHOC from '../../components/DashboardHOC';
import { Typography, CircularProgress } from '@material-ui/core';
import { getSecurityDonglesAction } from './state/security-dongle-actions';
import SecurityDonglesOverviewTable from './SecurityDonglesOverviewTable';

const SecurityDonglesPage = () => {
  const dispatch = useDispatch();

  const securityDonglesState = useSelector((state) => state.securityDongles);
  console.log(securityDonglesState);

  const { securityDonglesData, processing, error } = securityDonglesState;

  useEffect(() => {
    dispatch(getSecurityDonglesAction());
  }, [dispatch]);

  return (
    <DashboardHOC>
      <div>{processing ? <CircularProgress /> : <SecurityDonglesOverviewTable securityDonglesData={securityDonglesData} error={error} />}</div>
    </DashboardHOC>
  );
};

SecurityDonglesPage.propTypes = {};

export default SecurityDonglesPage;
