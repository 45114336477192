const styles = () => ({
  mainContainer: {
    marginLeft: '10px',
    backgroundColor: 'white',
    height: '450px',
    width: '450px',
    padding: '40px'
  },
  valuesContainer: { display: 'flex', justifyContent: 'space-between' },
  values: {
    height: '20px',
    margin: '0 10px 10px 0'
  },
  logo: { width: '100px', height: '60px', objectFit: 'contain' },
  colorBox: {
    width: '50px',
    height: '20px',
    marginLeft: '10px',
    border: '1px solid #eee'
  },
  colorsContainer: { display: 'flex' }
});

export default styles;
