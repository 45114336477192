import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableRow from '@material-ui/core/TableRow';
import getIcon from '../containers/support-wearables/getEventIcon';

import styles from './HistoryEvent.styles';

const HistoryEvent = ({ classes, historyData }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {historyData.map((e, i, a) => (
        <div key={i} className={classes.root}>
          <div>
            {moment(e.timestamp * 1000).format('DD MMM YYYY') !== moment(a[i - 1]?.timestamp * 1000).format('DD MMM YYYY') && (
              <div style={{ marginTop: '20px' }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  {moment(e.timestamp * 1000).format('DD MMM YYYY')}
                </Typography>
              </div>
            )}
            <TableContainer>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography gutterBottom>{`${moment(e.timestamp * 1000).format('HH:mm:ss')}`}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <div className={classes.tableCellIconContainer}>
                        <div className={classes.iconContainer}>{getIcon(e.event)}</div>
                        <Typography gutterBottom>{`${e.event.replace(/_/g, ' ')}`}</Typography>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpen((prev) => [i, !prev[1]])}>
                        {open[0] === i && open[1] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell} colSpan={6}>
                      <Collapse in={open[0] === i && open[1]} timeout="auto" unmountOnExit>
                        <div>
                          <Typography className={classes.message} gutterBottom>
                            {e.message}
                          </Typography>
                        </div>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ))}
    </>
  );
};

HistoryEvent.propTypes = {
  historyData: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HistoryEvent);
