import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import HistoryMonth from './HistoryMonth';
import { Paper, withStyles } from '@material-ui/core';

import LoadingIndicator from '../../components/LoadingIndicator';

import styles from './WearableHistory.styles';

const WearableHistory = ({ classes, getHistory, wearableHistoryData, processingHistory, hasMoreHistory, searchPeriod }) => {
  const { wearableHistory } = useSelector((state) => state.supportWearables);
  const { fromDate, toDate } = searchPeriod || {};

  return (
    <Paper className={classes.paper}>
      {wearableHistoryData?.length > 0 ? (
        <div className={classes.wearableHistoryDataContainer}>
          {wearableHistoryData.map((e, i) => (
            <HistoryMonth data={e} key={i} />
          ))}
        </div>
      ) : (
        !processingHistory && (
          <Button className={classes.mv20} onClick={() => getHistory()} variant="contained" color="primary">
            {'Fetch Wearable History'}
          </Button>
        )
      )}
      {!hasMoreHistory && !processingHistory && (
        <div className={classes.mv20}>
          {wearableHistoryData[0]?.history && <Typography color="primary">{`${'No more logs available'}`}</Typography>}
        </div>
      )}

      {hasMoreHistory && !processingHistory && wearableHistory?.length > 0 && (
        <Button disabled={!hasMoreHistory} className={classes.mv20} onClick={() => getHistory()} variant="contained" color="primary">
          {'Fetch More'}
        </Button>
      )}
      {processingHistory && <LoadingIndicator title={`Getting wearable history from ${fromDate} to ${toDate}`} />}
    </Paper>
  );
};

WearableHistory.propTypes = {
  searchPeriod: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string
  }),
  classes: PropTypes.object.isRequired,
  getHistory: PropTypes.func.isRequired,
  wearableHistoryData: PropTypes.arrayOf(PropTypes.object),
  processingHistory: PropTypes.bool,
  hasMoreHistory: PropTypes.bool.isRequired
};

export default withStyles(styles)(WearableHistory);
