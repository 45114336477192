const styles = (theme) => ({
  main: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tabBody: { margin: '20px 0' }
});

export default styles;
