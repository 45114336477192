import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography, Divider } from '@material-ui/core';
import StyledTableCell from './components/StyledTableCell';

import {
  resetServiceHistory,
  resetWearableAction
} from '../support-wearables/state/support-wearables-actions';

import styles from './AccountWearables.styles';

const AccountWearables = ({ classes, wearablesData }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  if (!wearablesData || wearablesData?.length === 0) {
    return <Typography>No wearables available for this account</Typography>;
  }
  return (
    <>
      <Divider />
      <Typography className={classes.title} variant="h6" gutterBottom>
        Wearables
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="account-wearables">
          <TableHead>
            <TableRow>
              <StyledTableCell>Asn</StyledTableCell>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell>Model</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Created</StyledTableCell>
              <StyledTableCell>Modified</StyledTableCell>
              <StyledTableCell>Services</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wearablesData.map((row) => (
              <TableRow
                key={row.asn}
                hover
                onClick={() => {
                  dispatch(resetServiceHistory());
                  dispatch(resetWearableAction());
                  history.push(`/dashboard/support/wearables/asn/${row.asn}`);
                }}
              >
                <TableCell>{row.asn}</TableCell>
                <TableCell>{row.device.brand}</TableCell>
                <TableCell>{row.device.model}</TableCell>
                <TableCell>{row.device?.variant?.color}</TableCell>
                <TableCell>{`${moment(row.createdDate).format(
                  'DD/MM/YYYY HH:mm:ss'
                )}`}</TableCell>
                <TableCell>{`${moment(row.modifiedDate).format(
                  'DD/MM/YYYY HH:mm:ss'
                )}`}</TableCell>
                <TableCell>{row?.services?.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

AccountWearables.propTypes = {
  classes: PropTypes.object.isRequired,
  wearablesData: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(AccountWearables);
