import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles
} from '@material-ui/core';

import imageNotFound from '../../images/imageNotFound.png';

import styles from './VariantsList.styles';

const VariantsList = ({ classes, variants }) => {
  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        Variants
      </Typography>
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell>Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variants.map((variant, i) => (
                <TableRow key={i}>
                  <TableCell>{variant.color}</TableCell>
                  <TableCell>
                    <div className={classes.variantImageContainer}>
                      <img
                        className={classes.image}
                        src={`${variant.image}?${performance.now()}`}
                        onError={handleImageLoadError}
                        alt="variant"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

VariantsList.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(VariantsList);
