import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress, withStyles } from '@material-ui/core';

import styles from './LoadingIndicator.styles';

const LoadingIndicator = ({ classes, title, ...props }) => {
  return (
    <div className={classes.loadingContainer}>
      {title && <Typography>{title}</Typography>}
      <CircularProgress {...props} />
    </div>
  );
};

LoadingIndicator.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingIndicator);
