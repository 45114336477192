import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHOC from '../../components/DashboardHOC';
import { Typography, CircularProgress } from '@material-ui/core';
import { getOemsAction } from './state/oems-actions';
import OemsOverview from './OemsOverview';

const OemsPage = () => {
  const dispatch = useDispatch();
  const oemsState = useSelector((state) => state.oems);

  const { oemsData, processing, error } = oemsState;

  useEffect(() => {
    dispatch(getOemsAction());
  }, [dispatch]);
  return (
    <DashboardHOC>
      <div>
        <Typography variant="h6" gutterBottom>
          OEMS Overview
        </Typography>

        {processing ? (
          <CircularProgress />
        ) : (
          <OemsOverview oemsData={oemsData} error={error} />
        )}
      </div>
    </DashboardHOC>
  );
};

OemsPage.propTypes = {};

export default OemsPage;
