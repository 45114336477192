import {
  GET_ENTITIES_REQUEST,
  GET_ENTITIES_SUCCESS,
  GET_ENTITIES_FAILURE,
  GET_ENTITY_REQUEST,
  GET_ENTITY_SUCCESS,
  GET_ENTITY_FAILURE,
  POST_ENTITY_REQUEST,
  POST_ENTITY_SUCCESS,
  POST_ENTITY_FAILURE,
  UPDATE_ENTITY_REQUEST,
  UPDATE_ENTITY_SUCCESS,
  UPDATE_ENTITY_FAILURE,
  CREATE_ENTITY_RESET
} from './constants';

const defaultState = {
  entities: null,
  entity: null,
  createdEntityData: null,
  updatedEntityData: null,
  processing: false,
  processingUpdatingEntity: false,
  error: '',
  errorUpdatingEntity: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_ENTITIES_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_ENTITIES_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        entities: action.response.data
      };
    case GET_ENTITIES_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting clients data'
      };
    case GET_ENTITY_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_ENTITY_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        entity: action.response.data
      };
    case GET_ENTITY_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting client data'
      };
    case POST_ENTITY_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case POST_ENTITY_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        createdEntityData: action.response.data
      };
    case POST_ENTITY_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting client data'
      };
    case UPDATE_ENTITY_REQUEST:
      return {
        ...state,
        processingUpdatingEntity: true,
        error: ''
      };
    case UPDATE_ENTITY_SUCCESS:
      return {
        ...state,
        processingUpdatingEntity: false,
        errorUpdatingEntity: '',
        updatedEntityData: action.response.data
      };
    case UPDATE_ENTITY_FAILURE:
      return {
        ...state,
        processingUpdatingEntity: false,
        errorUpdatingEntity: action.error?.message || 'Error updating entity'
      };
    case CREATE_ENTITY_RESET:
      return {
        ...state,
        createdEntityData: null,
        updatedEntityData: null
      };
    default:
      return state;
  }
}
