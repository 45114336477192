import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Alert } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

import styles from './SearchAccountsForm.styles';

const validationSchema = yup.object().shape({
  searchValue: yup
    .string()
    .email('Invalid email')
    .required('Email is required!')
});

const SearchAccountsForm = ({ classes, handleSubmit, error }) => {
  return (
    <Container maxWidth="sm">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Search Accounts
            </Typography>
            <br></br>
            <Formik
              initialValues={{ searchValue: '' }}
              onSubmit={(values) => {
                const { searchValue } = values;

                handleSubmit('email', searchValue.replace(/\s+/g, ''));
              }}
              validationSchema={validationSchema}
            >
              {({ errors, setFieldValue }) => (
                <Form>
                  <Grid container direction="row">
                    <div className={classes.emailLabel}>
                      <Typography variant="body1" gutterBottom>
                        Email:{' '}
                      </Typography>
                    </div>
                    <Field
                      error={!!errors.searchValue}
                      type="input"
                      name="searchValue"
                      as={TextField}
                      onChange={(e) => {
                        setFieldValue(
                          'searchValue',
                          e.target.value.replace(/\s+/g, '')
                        );
                      }}
                      className={classes.textField}
                    />
                    <IconButton type="submit" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Grid>
                  <ErrorMessage name="searchValue">
                    {(msg) => (
                      <div className={classes.errorContainer}>
                        <Typography variant="subtitle1" color="error">
                          {msg}
                        </Typography>
                      </div>
                    )}
                  </ErrorMessage>
                </Form>
              )}
            </Formik>
            {error && (
              <div>
                <Alert variant="outlined" severity="error">
                  {error}
                </Alert>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

SearchAccountsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default withStyles(styles)(SearchAccountsForm);
