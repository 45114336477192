const styles = () => ({
  table: {
    minWidth: 650
  },
  container: {
    maxHeight: 440
  }
});

export default styles;
