import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Typography, TablePagination } from '@material-ui/core';
import clsx from 'clsx';

import imageNotFound from '../../images/imageNotFound.png';

import styles from './EntitiesOverview.styles';

const ClientsOverview = ({ classes, entities, error }) => {
  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {entities && Array.isArray(entities) ? (
        <Paper>
          <TableContainer className={classes.container}>
            <Table className={classes.table} aria-label="Clients-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Logo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entities.some((entity) => entity.id === 'pending') &&
                  entities
                    .filter((entity) => entity.id === 'pending')
                    .map((entity, i) => (
                      <TableRow
                        className={clsx(classes.highlighted, classes.tableRow)}
                        key={i}
                        hover
                        onClick={() => {
                          history.push(`/dashboard/entities/${entity.id}`);
                        }}
                      >
                        <TableCell>{entity.name}</TableCell>
                        <TableCell>{entity.type}</TableCell>
                        <TableCell style={{ padding: 0, margin: 0 }}>
                          {entity.logo && (
                            <img className={classes.image} src={entity.logo} alt="entity logo" onError={handleImageLoadError} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                {entities
                  .filter((entity) => entity.id !== 'pending')
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .sort((a, b) => a.id.localeCompare(b.id))
                  .map((entity, i) => (
                    <TableRow
                      className={classes.tableRow}
                      key={i}
                      hover
                      onClick={() => {
                        history.push(`/dashboard/entities/${entity.id}`);
                      }}
                    >
                      <TableCell>{entity.name}</TableCell>
                      <TableCell>{entity.type}</TableCell>
                      <TableCell style={{ padding: 0, margin: 0 }}>
                        {entity.logo && (
                          <img className={classes.image} src={entity.logo} alt="entity logo" onError={handleImageLoadError} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 35, 50]}
            component="div"
            count={entities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Typography>No entities available or error fetching the entities. Please try again later or add an entity.</Typography>
      )}

      {error && <Typography>{error}</Typography>}
    </div>
  );
};

ClientsOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  entities: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string
};

export default withStyles(styles)(ClientsOverview);
