const styles = () => ({
  table: {
    minWidth: 650
  },
  downloadIcon: {
    cursor: 'pointer'
  }
});

export default styles;
