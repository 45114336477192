export const GET_SYSTEM_ERROR_LOGS_REQUEST = 'GET_SYSTEM_ERROR_LOGS_REQUEST';
export const GET_SYSTEM_ERROR_LOGS_SUCCESS = 'GET_SYSTEM_ERROR_LOGS_SUCCESS';
export const GET_SYSTEM_ERROR_LOGS_FAILURE = 'GET_SYSTEM_ERROR_LOGS_FAILURE';

export const GET_SYSTEM_MAINTENANCE_REQUEST = 'GET_SYSTEM_MAINTENANCE_REQUEST';
export const GET_SYSTEM_MAINTENANCE_SUCCESS = 'GET_SYSTEM_MAINTENANCE_SUCCESS';
export const GET_SYSTEM_MAINTENANCE_FAILURE = 'GET_SYSTEM_MAINTENANCE_FAILURE';

export const POST_SYSTEM_MAINTENANCE_REQUEST =
  'POST_SYSTEM_MAINTENANCE_REQUEST';
export const POST_SYSTEM_MAINTENANCE_SUCCESS =
  'POST_SYSTEM_MAINTENANCE_SUCCESS';
export const POST_SYSTEM_MAINTENANCE_FAILURE =
  'POST_SYSTEM_MAINTENANCE_FAILURE';

export const POST_SYSTEM_MAINTENANCE_WHITELISTS_REQUEST =
  'POST_SYSTEM_MAINTENANCE_WHITELISTS_REQUEST';
export const POST_SYSTEM_MAINTENANCE_WHITELISTS_SUCCESS =
  'POST_SYSTEM_MAINTENANCE_WHITELISTS_SUCCESS';
export const POST_SYSTEM_MAINTENANCE_WHITELISTS_FAILURE =
  'POST_SYSTEM_MAINTENANCE_WHITELISTS_FAILURE';
