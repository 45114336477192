import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import imageNotFound from '../../images/imageNotFound.png';
import NoData from './NoData';

import styles from './TopIssuers.styles';
function TopIssuers({ topIssuers, classes }) {
  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }
  return (
    <React.Fragment>
      <Typography variant="overline" align="center">
        Top 5 Issuers
      </Typography>
      <Divider />
      {topIssuers ? (
        <List className={classes.root}>
          {topIssuers?.map((issuerData, i) => (
            <ListItem key={i}>
              <ListItemAvatar>
                <img
                  alt="top issuers"
                  onError={handleImageLoadError}
                  className={classes.image}
                  src={issuerData.issuerLogoUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={issuerData.issuer}
                secondary={issuerData.noOfRegistrations + ' Registrations'}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <NoData />
      )}
    </React.Fragment>
  );
}

TopIssuers.propTypes = {
  topIssuers: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TopIssuers);
