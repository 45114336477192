import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Sidebar from './Sidebar';

import { ENV } from '../env';
import { decodeJwt } from '../utils/cryptography';
import { signOutAction } from '../containers/login/state/sign-in-actions';

import styles from './DashboardHOC.styles';
import AdminContainer from './AdminContainerHOC';

function DashboardHOC({ children, classes }) {
  let history = useHistory();
  const token = sessionStorage.getItem('tokenDetails');
  let entity = '';
  if (token) {
    const jwt = decodeJwt(token);
    entity = jwt.entity_id;
  }

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onLogout() {
    dispatch(signOutAction());
    history.push('/');
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {`${ENV} `}
          </Typography>
          <div className={classes.entityName}>
            <Typography align={'center'} variant={'h6'}>
              {entity?.replace('_', ' ').toUpperCase()}
            </Typography>
            <AdminContainer type="internal">
              <strong>INTERNAL ADMIN</strong>
            </AdminContainer>
            <AdminContainer type="external">
              <strong>EXTERNAL ADMIN</strong>
            </AdminContainer>
            <AdminContainer type="internal" mode="readOnly">
              <strong>INTERNAL ADMIN READ ONLY</strong>
            </AdminContainer>
            <AdminContainer type="external" mode="readOnly">
              <strong> EXTERNAL ADMIN READ ONLY</strong>
            </AdminContainer>
          </div>

          <div>
            <div></div>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  onLogout();
                  handleClose();
                }}
              >
                Sign out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}

DashboardHOC.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(DashboardHOC);
