const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  label: { marginRight: 35, padding: '6px 16px' }
});

export default styles;
