import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import DashboardHOC from '../../components/DashboardHOC';
import DeviceDetails from './DeviceDetails';
import AlertDialog from '../../components/AlertDialog';
import ConfirmationTest from '../../components/ConfirmationTest';

import { getDeviceAction, deleteDeviceAction } from './state/devices-actions';

import styles from './DevicePage.styles';

const DevicePage = ({ classes, match, history }) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deletable, setDeletable] = useState(false);
  const [exportDeviceError, setExportDeviceError] = useState();

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  const devicesState = useSelector((state) => state.devices);
  const { deviceData, processingDevice, errorDevice } = devicesState;

  const { params } = match;

  useEffect(() => {
    stableDispatch(getDeviceAction(params.brand, params.model, params.version));
  }, [params, stableDispatch]);

  function handleDeleteDevice() {
    if (deletable) {
      dispatch(deleteDeviceAction(params.brand, params.model, params.version));
      history.goBack();
    }
  }

  const createZipFile = async () => {
    setExportDeviceError(null);
    const zip = new JSZip();
    zip.file(`${deviceData.model?.name}__${deviceData.model?.version}.json`, JSON.stringify(deviceData));

    const defaultImage = await fetch(deviceData.defaultImage);
    const defaultImageData = await defaultImage.blob();

    zip.file(`${deviceData?.model.name}.png`, defaultImageData);

    let list;
    if (deviceData.variants?.length > 0) {
      const otherFiles = zip.folder('variants');
      list = deviceData.variants?.map(async (item, index) => {
        const response = await fetch(item.image);
        const data = await response.blob();

        otherFiles.file(`${item.image.substring(item.image.lastIndexOf('/') + 1)}`, data);
        return data;
      });

      Promise.all(list)
        .then(function() {
          zip.generateAsync({ type: 'blob' }).then(function(content) {
            saveAs(content, `${deviceData.brand}_${deviceData.model?.name}_${deviceData.model?.version}__admin`);
          });
        })
        .catch((e) => {
          setExportDeviceError(e);
        });
    } else {
      zip.generateAsync({ type: 'blob' }).then(function(content) {
        saveAs(content, `${deviceData.brand}_${deviceData.model?.name}_${deviceData.model?.version}__admin`);
      });
    }
  };

  return (
    <DashboardHOC>
      {processingDevice && <CircularProgress />}
      {deviceData && (
        <DeviceDetails
          handleExportDevice={() => createZipFile()}
          deviceData={deviceData}
          handleDeleteDevice={() => setShowDeleteAlert(true)}
        />
      )}
      {errorDevice && (
        <Alert
          className={classes.errorAlert}
          severity="error"
          variant="outlined"
          action={
            <Button color="inherit" size="small" onClick={() => history.goBack()}>
              go back
            </Button>
          }
        >
          {exportDeviceError || errorDevice}
        </Alert>
      )}
      <AlertDialog
        showAlert={showDeleteAlert}
        handleClose={() => setShowDeleteAlert(false)}
        title="Warning!"
        body1="Please confirm deleting this device by entering the text below"
        component={
          <ConfirmationTest
            testString={params.brand + ' ' + params.model + ' ' + params.version}
            testResult={(result) => setDeletable(result)}
          />
        }
        actionButtonLabel="submit"
        handleAction={() => {
          handleDeleteDevice();
        }}
      />
    </DashboardHOC>
  );
};

DevicePage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      version: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default withStyles(styles)(DevicePage);
