const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: '100%'
  },
  overviewServiceContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  colorBox: {
    width: 20,
    height: 20,
    marginLeft: 8,
    marginTop: 4,
    borderRadius: 4,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 8
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

export default styles;
