import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import HeaderWithButton from '../../components/HeaderWithButton';
import ErrorAlert from '../../components/ErrorAlert';
import AlertDialog from '../../components/AlertDialog';
import ConfirmationTest from '../../components/ConfirmationTest';
import MaintenanceMode from './components/maintenance-mode/MaintenanceMode';
import MaintenanceWhitelist from './components/maintenance-mode/MaintenanceWhitelist';

import { getSystemMaintenanceAction, postSystemMaintenanceAction, postSystemMaintenanceWhitelistsAction } from './state/system-actions';

import styles from './SystemTools.styles';

const SystemTools = ({ classes }) => {
  const [showMaintenanceAlert, setShowMaintenanceAlert] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(false);
  const [showUsersWhitelist, setShowUsersWhitelist] = useState(false);
  const [showClientsWhitelist, setShowClientsWhitelist] = useState(false);
  const systemState = useSelector((state) => state.system);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSystemMaintenanceAction());
  }, []);

  const handleChange = () => {
    setShowMaintenanceAlert(true);
  };

  const onUpdateWhitelist = (updatedList, type) => {
    let updatedWhitelists;

    if (type === 'clients') {
      updatedWhitelists = {
        clients: updatedList,
        users: systemState.usersWhitelist
      };
    } else {
      updatedWhitelists = {
        clients: systemState.clientsWhitelist,
        users: updatedList
      };
    }

    dispatch(postSystemMaintenanceWhitelistsAction(updatedWhitelists));
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <HeaderWithButton headerText="System Tools" />

        <MaintenanceMode
          processing={systemState.processingGetSystemMaintenance || systemState.processingPostSystemMaintenance}
          maintenanceMode={systemState.maintenanceMode}
          handleChange={handleChange}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          {!showClientsWhitelist && (
            <Button onClick={() => setShowUsersWhitelist(!showUsersWhitelist)}>
              {`${showUsersWhitelist ? 'close list' : 'users whitelist'} `}
            </Button>
          )}
          {!showUsersWhitelist && (
            <Button onClick={() => setShowClientsWhitelist(!showClientsWhitelist)}>
              {`${showClientsWhitelist ? 'close list' : 'clients whitelist'} `}
            </Button>
          )}
        </div>
        {showClientsWhitelist && systemState.clientsWhitelist && (
          <MaintenanceWhitelist
            processing={systemState.processingPostSystemMaintenanceWhitelists}
            type="clients"
            whitelist={systemState.clientsWhitelist}
            onUpdateWhitelist={onUpdateWhitelist}
          />
        )}
        {showUsersWhitelist && systemState.usersWhitelist && (
          <MaintenanceWhitelist
            processing={systemState.processingPostSystemMaintenanceWhitelists}
            type="users"
            whitelist={systemState.usersWhitelist}
            onUpdateWhitelist={onUpdateWhitelist}
          />
        )}
      </Paper>
      <AlertDialog
        showAlert={showMaintenanceAlert}
        disableActionButton={!confirmationResult}
        handleClose={() => setShowMaintenanceAlert(false)}
        title="Warning!"
        body1="Please confirm updating the maintenance mode by typing the text below:"
        component={<ConfirmationTest testString={'update maintenance'} testResult={(result) => setConfirmationResult(result)} />}
        actionButtonLabel="confirm"
        handleAction={() => {
          if (confirmationResult) {
            dispatch(postSystemMaintenanceAction(!systemState.maintenanceMode));
            setShowMaintenanceAlert(false);
          }
        }}
      />
      {(systemState.errorGetSystemMaintenance || systemState.errorPostSystemMaintenance) && (
        <ErrorAlert
          error={systemState.errorGetSystemMaintenance || systemState.errorPostSystemMaintenance || 'Error getting maintenance state'}
        />
      )}
    </div>
  );
};

SystemTools.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(SystemTools);
