const styles = (theme) => ({
  mainContainer: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column'
  },
  primaryColor: { color: theme.primaryColor },
  demoOutlinedPrimary: {
    background: 'white',
    '&:hover': {
      background: 'white'
    },
    borderRadius: 20,
    height: 40,
    width: 120,
    padding: '0 30px',
    marginBottom: 10
  },
  cardNumberContainer: { margin: '40px 0' },
  cardNumberTitle: {
    fontSize: '20px',
    color: theme.primaryTextColor
  },
  formControl: { width: '263px' },
  eligibilityMainContainer: { margin: '20px 0' },
  innerContainer: { margin: '10px 0' },
  noPadding: { padding: 0 },
  icon: {
    backgroundColor: theme.primaryColor || 'black',
    width: '24px',
    padding: '2px',
    textAlign: 'center',
    height: '24px',
    fontSize: '12px',
    color: 'white',
    borderRadius: '50%'
  },
  buttonsContainer: {
    margin: '10px 0'
  },
  buttonOutlined: {
    color: theme.primaryColor,
    border: `1px solid ${theme.primaryColor}`
  },
  secondaryButton: {
    color: theme.primaryColor,
    border: 0
  }
});

export default styles;
