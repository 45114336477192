const styles = () => ({
  card: {
    maxWidth: 800
  },
  actions: {
    float: 'right'
  },
  input: {
    display: 'none'
  },
  defaultImageUpload: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px 0'
  },
  image: {
    width: 50,
    heigth: 50,
    objectFit: 'contain',
    margin: '0 20px'
  },
  imageError: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    ligneHeight: 1.66,
    letterSpacing: '0.03333em'
  },
  variantsContainer: { padding: '0 10px' },
  variantContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px 0'
  },
  variantImageContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  variantInput: { margin: '0 10px' },
  addVariantButton: { marginTop: '15px' },
  error: {
    margin: '20px 0'
  }
});

export default styles;
