import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DashboardHOC from '../../components/DashboardHOC';
import { getEntitiesAction } from './state/entities-actions';
import EntitiesOverview from './EntitiesOverview';
import LoadingIndicator from '../../components/LoadingIndicator';
import HeaderWithButton from '../../components/HeaderWithButton';

const EntitiesPage = () => {
  let history = useHistory();

  const dispatch = useDispatch();
  const entitiesState = useSelector((state) => state.entities);

  const { entities, processing, error } = entitiesState;

  useEffect(() => {
    dispatch(getEntitiesAction());
  }, [dispatch]);

  return (
    <DashboardHOC>
      <div>
        <HeaderWithButton headerText="Entities" buttonLabel="Add a entity" onClick={() => history.push(`/dashboard/entities/create`)} />

        {processing ? <LoadingIndicator title="Loading Entities Data" /> : <EntitiesOverview entities={entities} error={error} />}
      </div>
    </DashboardHOC>
  );
};

export default EntitiesPage;
