import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import DashboardHOC from '../../components/DashboardHOC';
import SearchWearablesForm from './SearchWearablesForm';

import { resetWearableAction } from './state/support-wearables-actions';

const SupportWearablesPage = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetWearableAction());
  }, [dispatch]);

  const handleSubmit = (type, value) => {
    if (type === 'email') {
      history.push(`/dashboard/support/accounts/account/${value}`);
    } else {
      history.push(`/dashboard/support/wearables/${type}/${value}`);
    }
  };

  return (
    <DashboardHOC>
      <SearchWearablesForm
        handleSubmit={(type, value) => handleSubmit(type, value)}
      />
    </DashboardHOC>
  );
};

SupportWearablesPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default SupportWearablesPage;
