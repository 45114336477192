import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Typography, makeStyles } from '@material-ui/core';

import styles from './MobileDeviceDetails.styles';

const useStyles = makeStyles(styles);

const MobileDeviceDetails = ({ decodedDevice }) => {
  const { brand, deviceId, model, systemVersion, version } = decodedDevice;
  const classes = useStyles();

  return (
    <CardContent className={classes.content}>
      <div className={classes.mobileDeviceDetailsContainer}>
        <div>
          <Typography color="textPrimary" variant="body2" gutterBottom>
            <strong> Mobile Device Details</strong>
          </Typography>
        </div>
        <div className={classes.mobileDeviceDetails}>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Brand
            </Typography>
            <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
              {brand}
            </Typography>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Device Id
            </Typography>
            <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
              {deviceId}
            </Typography>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Model
            </Typography>
            <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
              {model}
            </Typography>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              System Version
            </Typography>
            <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
              {systemVersion}
            </Typography>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Version
            </Typography>
            <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
              {version}
            </Typography>
          </div>
        </div>
      </div>
    </CardContent>
  );
};

MobileDeviceDetails.propTypes = {
  decodedDevice: PropTypes.shape({
    brand: PropTypes.string,
    deviceId: PropTypes.string,
    model: PropTypes.string,
    systemVersion: PropTypes.string,
    version: PropTypes.string
  }).isRequired
};

export default MobileDeviceDetails;
