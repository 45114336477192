import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  InputLabel,
  Input,
  FormHelperText,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Divider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './ThemePreview.styles';

const ThemePreview = ({ classes, theme }) => {
  return (
    <div
      className={classes.mainContainer}
      style={{
        backgroundColor: theme.backgroundColor,
        padding: '20px',
        width: '450px',
        height: '450px'
      }}
    >
      <Divider orientation="vertical" flexItem />

      <div className={classes.eligibilityMainContainer}>
        <Typography variant="body1" color="primary" gutterBottom>
          Card Eligibility Page
        </Typography>
        <div className={classes.innerContainer}>
          <Stepper className={classes.noPadding}>
            <Step active className={classes.noPadding}>
              <StepLabel
                icon={
                  <div
                    className={classes.icon}
                    style={{ backgroundColor: theme.primaryColor }}
                  >
                    1
                  </div>
                }
              >
                Check Card Eligibility
              </StepLabel>
            </Step>
          </Stepper>
          <div className={classes.cardNumberContainer}>
            <Typography
              className={classes.cardNumberTitle}
              style={{ color: theme.primaryTextColor }}
              variant="body1"
            >
              Enter your card number
            </Typography>
            <FormControl className={classes.formControl}>
              <InputLabel shrink hidden style={{ color: theme.primaryColor }}>
                Card Number
              </InputLabel>
              <Input value="5500 0000 0000 0004" />
            </FormControl>
            <FormHelperText error>Error</FormHelperText>
            <Typography
              variant="subtitle2"
              style={{ color: theme.primaryColor }}
            >
              Click here if you don&apos;t know the card number
            </Typography>
          </div>
        </div>
      </div>
      <Typography variant="body1" color="primary" gutterBottom>
        Buttons
      </Typography>
      <div className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          className={`${classes.demoOutlinedPrimary}`}
          style={{
            color: theme.primaryColor,
            border: `1px solid ${theme.primaryColor}`
          }}
        >
          next
        </Button>
        <Button
          className={`${classes.demoOutlinedPrimary} ${classes.secondaryButton}`}
          style={{ color: theme.primaryColor }}
        >
          back
        </Button>
      </div>
    </div>
  );
};

ThemePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.shape({
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    primaryTextColor: PropTypes.string,
    secondaryTextColor: PropTypes.string,
    backgroundColor: PropTypes.string
  })
};

export default withStyles(styles)(ThemePreview);
