const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  image: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  noData: {
    margin: '10px 0',
    color: 'red'
  }
});

export default styles;
