import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import LoadingIndicator from '../../components/LoadingIndicator';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import TopWearables from '../../components/dashboards/TopWearables';
import TopIssuers from '../../components/dashboards/TopIssuers';
import TopFormFactors from '../../components/dashboards/TopFormFactors';
import AdminContainer from '../../components/AdminContainerHOC';

import styles from './DashboardOverview.styles';

function EnhancedTableHead(props) {
  const { order, orderBy, headCells, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => {
          if (headCell)
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const DashboardOverview = ({
  classes,
  countData,
  fetchFilteredWearables,
  processingFilteredWearables,
  filteredWearables
}) => {
  const [modalTitle, setModalTitle] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'device') {
      if (b[orderBy].brand < a[orderBy].brand) {
        return -1;
      }
      if (b[orderBy].brand > a[orderBy].brand) {
        return 1;
      }
      return 0;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchFilteredDevices = (title, filtered) => {
    setModalTitle(title);
    if (title === 'Tokens Expiring Soon!') {
      setOrderBy('activationCountdown');
    } else {
      setOrderBy('createdDate');
    }
    fetchFilteredWearables(filtered);
    handleClickOpen();
  };

  const headCells = [
    { id: 'asn', numeric: false, disablePadding: true, label: 'ASN' },
    { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    {
      id: 'device',
      numeric: true,
      disablePadding: false,
      label: 'Device'
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: 'Registered At'
    },
    modalTitle === 'Tokens Expiring Soon!' && {
      id: 'activationCountdown',
      numeric: false,
      disablePadding: true,
      label: 'Expiring in (days)'
    }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={4}>
        <Paper className={classes.paper}>
          <div className={classes.overviewServiceContainer}>
            <Typography color="secondary" variant="overline" gutterBottom>
              Wearables
            </Typography>
            {countData?.wearablesDashboardData?.totalNumberOfWearables ? (
              <>
                <Typography variant="h4">
                  {countData?.wearablesDashboardData?.totalNumberOfWearables}
                </Typography>
                <Divider className={classes.divider} />
                <Link
                  underline="always"
                  href="#"
                  onClick={() => {
                    fetchFilteredDevices(
                      'Waiting to be personalised',
                      'processing'
                    );
                  }}
                >
                  {countData?.servicesDashboardData?.noOfProcessingServices} -
                  WAITING TO BE PERSONALISED
                </Link>
                <Link
                  underline="always"
                  href="#"
                  onClick={() => {
                    fetchFilteredDevices('Expired Activations', 'expired');
                  }}
                >
                  {
                    countData?.wearablesDashboardData
                      ?.wearablesWithActivationExpiredServices
                  }
                  - EXPIRED ACTIVATIONS
                </Link>
                <Link
                  underline="always"
                  href="#"
                  onClick={() => {
                    fetchFilteredDevices('Tokens Expiring Soon!', 'expiring');
                  }}
                >
                  {
                    countData?.wearablesDashboardData
                      ?.wearablesWithActivationExpiringServices
                  }
                  - EXPIRING ACTIVATIONS
                </Link>
                <Link
                  underline="always"
                  href="#"
                  onClick={() => {
                    fetchFilteredDevices(
                      'Wearables without a service',
                      'missing'
                    );
                  }}
                >
                  {
                    countData?.wearablesDashboardData
                      ?.noOfWearablesWithoutAService
                  }
                  - WEARABLES WITHOUT A SERVICE
                </Link>
                <Typography variant="caption">
                  {
                    countData?.wearablesDashboardData
                      ?.noOfRegistrationsThisMonth
                  }
                  - REGISTRATIONS THIS MONTH
                </Typography>
                <Typography variant="caption">
                  {
                    countData?.wearablesDashboardData
                      ?.noOfRegistrationsLastMonth
                  }
                  - REGISTRATIONS LAST MONTH
                </Typography>
                <Typography variant="caption">
                  {countData?.wearablesDashboardData?.noOfRegistrationsThisYear}
                  - REGISTRATIONS THIS YEAR
                </Typography>
              </>
            ) : (
              <Typography component="p" variant="h4">
                None
              </Typography>
            )}
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Paper className={classes.paper}>
          <div className={classes.overviewServiceContainer}>
            <Typography color="secondary" variant="overline" gutterBottom>
              SERVICES
            </Typography>
            {countData?.servicesDashboardData?.noOfServices ? (
              <>
                <Typography variant="h4">
                  {countData?.servicesDashboardData?.noOfServices}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="caption">
                  {countData?.servicesDashboardData?.noOfActiveServices} -
                  ACTIVE SERVICES
                </Typography>
                <Link
                  underline="always"
                  href="#"
                  onClick={() => {
                    fetchFilteredDevices(
                      'Not yet activated wearables',
                      'inactive'
                    );
                  }}
                >
                  {countData?.servicesDashboardData?.noOfInactiveServices} -
                  INACTIVE SERVICES
                </Link>
              </>
            ) : (
              <Typography component="p" variant="h4">
                None
              </Typography>
            )}
          </div>
        </Paper>
      </Grid>
      <AdminContainer type="internal">
        <Grid item xs={12} md={12} lg={4}>
          <Paper className={classes.paper}>
            <div className={classes.overviewServiceContainer}>
              <Typography color="secondary" variant="overline" gutterBottom>
                ACCOUNTS
              </Typography>
              {countData?.accountsDashboardData?.noOfAccounts ? (
                <>
                  <Typography variant="h4">
                    {countData?.accountsDashboardData?.noOfAccounts}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Typography variant="caption">
                    {countData?.accountsDashboardData?.noOfActiveAccounts} -
                    ACTIVE ACCOUNTS
                  </Typography>
                  <Typography variant="caption">
                    {countData?.accountsDashboardData?.noOfNewAccountsThisMonth}{' '}
                    - NEW ACCOUNTS THIS MONTH
                  </Typography>
                  <Typography variant="caption">
                    {countData?.accountsDashboardData?.noOfNewAccountsLastMonth}{' '}
                    - NEW ACCOUNTS LAST MONTH
                  </Typography>
                  <Typography variant="caption">
                    {countData?.accountsDashboardData?.noOfNewAccountsThisYear}{' '}
                    - NEW ACCOUNTS THIS YEAR
                  </Typography>
                </>
              ) : (
                <Typography component="p" variant="h4">
                  None
                </Typography>
              )}
            </div>
          </Paper>
        </Grid>
      </AdminContainer>
      <Grid item xs={6} md={6} lg={6}>
        <Paper className={classes.paper}>
          <TopWearables
            topWearables={countData?.wearablesDashboardData?.topDevices}
          />
        </Paper>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <Paper className={classes.paper}>
          <TopIssuers
            topIssuers={countData?.servicesDashboardData?.topIssuers}
          />
        </Paper>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <Paper className={classes.paper}>
          <TopFormFactors
            topFormFactors={countData?.wearablesDashboardData?.topFormFactors}
          />
        </Paper>
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {modalTitle}
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            {processingFilteredWearables ? (
              <LoadingIndicator title="Loading Data" />
            ) : (
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <EnhancedTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(
                    filteredWearables,
                    getComparator(order, orderBy)
                  ).map((row) => (
                    <TableRow key={row.asn}>
                      <TableCell component="th" scope="row">
                        {row.asn}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{`${row.device.brand} - ${row.device.model} - ${row.device.variant?.color}`}</TableCell>
                      <TableCell>{`${moment(row.createdDate).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )}`}</TableCell>
                      {modalTitle === 'Tokens Expiring Soon!' && (
                        <TableCell align="right">
                          {row.activationCountdown}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

DashboardOverview.propTypes = {
  filteredWearables: PropTypes.arrayOf(PropTypes.object),
  processingFilteredWearables: PropTypes.bool.isRequired,
  fetchFilteredDevices: PropTypes.func,
  fetchFilteredWearables: PropTypes.func,
  classes: PropTypes.object.isRequired,
  countData: PropTypes.shape({
    noOfWearables: PropTypes.number,
    noOfUsers: PropTypes.number,
    wearablesDashboardData: PropTypes.shape({
      totalNumberOfWearables: PropTypes.number,
      noOfProcessingServices: PropTypes.number
    }),
    accountsDashboardData: PropTypes.shape({
      noOfAccounts: PropTypes.number,
      noOfActiveAccounts: PropTypes.number,
      wearablesWithActivationExpiredServices: PropTypes.number,
      noOfWearablesWithoutAService: PropTypes.number,
      topDevices: PropTypes.arrayOf(PropTypes.object),
      topFormFactors: PropTypes.arrayOf(PropTypes.object)
    }),
    servicesDashboardData: PropTypes.shape({
      noOfProcessingServices: PropTypes.number,
      topIssuers: PropTypes.arrayOf(PropTypes.object)
    })
  })
};

export default withStyles(styles)(DashboardOverview);
