import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import DashboardHOC from '../../components/DashboardHOC';
import LoadingIndicator from '../../components/LoadingIndicator';
import AlertDialog from '../../components/AlertDialog';
import ConfirmationTest from '../../components/ConfirmationTest';
import AccountDetails from './AccountDetails';
import {
  getAccountAction,
  getAccountActivityAction,
  revokeRefreshTokenAction
} from './state/support-accounts-actions';
import { getWearableAction } from '../support-wearables/state/support-wearables-actions';
import { Typography } from '@material-ui/core';

import styles from './AccountSearchPage.styles';

const AccountPage = ({
  classes,
  match: {
    params: { email }
  },
  history
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [showConfirmationTest, setShowConfirmationTest] = useState(false);
  const [confirmationTestResult, setConfirmationTestResult] = useState(false);
  const supportAccountsState = useSelector((state) => state.supportAccounts);
  const supportWearablesState = useSelector((state) => state.supportWearables);

  useEffect(() => {
    stableDispatch(getAccountAction(email));
  }, [stableDispatch, email, supportAccountsState.refreshTokenRevoked]);

  useEffect(() => {
    if (supportAccountsState.accountData) {
      stableDispatch(getWearableAction('email', email));
    }
  }, [stableDispatch, supportAccountsState.accountData, email]);

  function getAccountActivity() {
    dispatch(getAccountActivityAction(email));
  }

  function handleRevokeRefreshToken() {
    if (confirmationTestResult) {
      dispatch(revokeRefreshTokenAction(email));
      setShowConfirmationTest(false);
    }
  }

  // TODO Create a prettier, sharable error component to use across all errors

  if (supportAccountsState.errorAccountData || supportWearablesState.error)
    return (
      <DashboardHOC>
        <Typography>
          {supportAccountsState.errorAccountData || supportWearablesState.error}
        </Typography>
      </DashboardHOC>
    );

  return (
    <DashboardHOC>
      <AlertDialog
        showAlert={showConfirmationTest}
        handleClose={() => setShowConfirmationTest(false)}
        title="Are you sure?"
        body1="Please confirm revoking token by typing the email below"
        component={
          <ConfirmationTest
            testString={email}
            testResult={(result) => setConfirmationTestResult(result)}
          />
        }
        actionButtonLabel="submit"
        handleAction={() => {
          handleRevokeRefreshToken();
        }}
      />
      {supportAccountsState.processingAccountData ||
      supportWearablesState.processing ? (
        <div className={classes.circularProgressContainer}>
          <LoadingIndicator
            title={`Getting ${
              supportAccountsState.processingAccountActivity
                ? 'Account'
                : 'Wearables'
            } Details`}
          />
        </div>
      ) : (
        supportWearablesState.wearablesData && (
          <AccountDetails
            handleRevokeRefreshToken={() => setShowConfirmationTest(true)}
            getAccountActivity={() => getAccountActivity()}
            accountData={supportAccountsState.accountData}
            processingAccountActivity={
              supportAccountsState.processingAccountActivity
            }
            errorAccountActivity={supportAccountsState.errorAccountActivity}
            accountActivityData={supportAccountsState.accountActivityData}
            wearablesData={supportWearablesState.wearablesData}
            processingWearables={supportWearablesState.processing}
          />
        )
      )}

      {supportAccountsState.errorAccountData && (
        <Alert
          className={classes.errorAlert}
          severity="error"
          variant="outlined"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => history.goBack()}
            >
              go back
            </Button>
          }
        >
          {supportAccountsState.errorAccountData}
        </Alert>
      )}
    </DashboardHOC>
  );
};

AccountPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      email: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default withStyles(styles)(AccountPage);
