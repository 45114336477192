import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHOC from '../../components/DashboardHOC';
import { Typography, CircularProgress } from '@material-ui/core';
import { getClientsAction } from './state/clients-actions';
import ClientsOverview from './ClientsOverview';

const ClientsPage = () => {
  const dispatch = useDispatch();
  const clientsState = useSelector((state) => state.clients);

  const { clientsData, processing, error } = clientsState;

  useEffect(() => {
    dispatch(getClientsAction());
  }, [dispatch]);
  return (
    <DashboardHOC>
      <div>
        <Typography variant="h6" gutterBottom>
          Clients Overview
        </Typography>

        {processing ? (
          <CircularProgress />
        ) : (
          <ClientsOverview clientsData={clientsData} error={error} />
        )}
      </div>
    </DashboardHOC>
  );
};

ClientsPage.propTypes = {};

export default ClientsPage;
