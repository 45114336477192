import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid, CardActions, Divider, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import DashboardHOC from '../../components/DashboardHOC';
import { Card, CardContent, Typography } from '@material-ui/core';
import { updateEntityAction, getEntityAction, resetCreatedEntity } from './state/entities-actions';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import ThemePreview from './components/ThemePreview';
import ErrorAlert from '../../components/ErrorAlert';
import LoadingIndicator from '../../components/LoadingIndicator';
import { convertFileToBase64 } from '../../utils/utils';

import styles from './EntitiesEditPage.styles';

const EntitiesEditPage = ({ classes, match }) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let history = useHistory();
  const entitiesState = useSelector((state) => state.entities);
  const { processing, error } = entitiesState;

  const { entity } = entitiesState;

  const { params } = match;

  useEffect(() => {
    if (!entity) {
      stableDispatch(getEntityAction(params.id));
    }
  }, [entity, params, stableDispatch]);

  useEffect(() => {
    if (entitiesState.updatedEntityData) {
      history.replace(`/dashboard/entities`);
      stableDispatch(resetCreatedEntity());
    }
  }, [entitiesState, stableDispatch, history]);

  return (
    <DashboardHOC>
      {processing && <LoadingIndicator title="Retrieving entity details" />}
      {entity && (
        <div>
          <HeaderWithButton headerText="Edit Entity" />
          <Formik
            initialValues={{
              logoPreviewUrl: null,
              name: entity.name || '',
              logo: entity.logo || '',
              coverImage: entity.coverImage || '',
              description: entity.description || '',
              storeLink: entity.storeLink || '',
              contactEmail: entity?.contact?.email || '',
              contactPhone: entity?.contact?.phone || '',
              contactWebsite: entity?.contact?.website || '',
              excludedFromWebsite: entity.excludedFromWebsite || false,
              featuredOnWebsite: entity.featuredOnWebsite || false,
              type: 'OEM',
              theme: {
                errorColor: entity?.theme?.errorColor || '',
                font: entity?.theme?.font || '',
                hoverColor: entity?.theme?.hoverColor || '',
                primaryColor: entity?.theme?.primaryColor || '',
                primaryTextColor: entity?.theme?.primaryTextColor || '',
                secondaryColor: entity?.theme?.secondaryColor || '',
                secondaryTextColor: entity?.theme?.secondaryTextColor || '',
                selectedColor: entity?.theme?.selectedColor || '',
                successColor: entity?.theme?.successColor || ''
              }
            }}
            onSubmit={(values, { setSubmitting }) => {
              const {
                name,
                logo,
                theme,
                type,
                storeLink,
                description,
                contactEmail,
                contactPhone,
                contactWebsite,
                coverImage,
                excludedFromWebsite,
                featuredOnWebsite
              } = values;
              dispatch(
                updateEntityAction(entity.id, {
                  name,
                  logo: logo || null,
                  theme,
                  type,
                  description,
                  storeLink,
                  contact: {
                    email: contactEmail,
                    phone: contactPhone,
                    website: contactWebsite
                  },
                  coverImage: coverImage || null,
                  excludedFromWebsite,
                  featuredOnWebsite
                })
              );
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Card className={classes.card}>
                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6} lg={6}>
                        <div className={classes.nameLogoContainer}>
                          <div>
                            <InputField
                              error={Boolean(errors.name && touched.name && errors.name)}
                              disabled
                              fullWidth
                              id="name"
                              label="Name"
                              name="name"
                              helperText={errors.name}
                              autoFocus
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                          </div>
                          <div className={classes.uploadImage}>
                            <div className={classes.mh20}>
                              <Typography variant="body1" gutterBottom>
                                Logo
                              </Typography>
                            </div>
                            <input
                              name="file"
                              accept="image/*"
                              multiple={false}
                              onChange={async (event) => {
                                const file = event.currentTarget.files[0];
                                const base64Image = await convertFileToBase64(file);
                                setFieldValue(`logo`, base64Image);

                                setFieldValue(`logoPreviewUrl`, URL.createObjectURL(file));
                              }}
                              className={classes.input}
                              id="logo"
                              type="file"
                            />
                            {!values.logoPreviewUrl && (
                              <div className={classes.image}>
                                <label htmlFor="logo">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                  </IconButton>
                                </label>
                              </div>
                            )}

                            {entity.logo && !values.logoPreviewUrl && (
                              <div>
                                <img alt="logo preview" className={classes.logo} src={entity.logo} />
                              </div>
                            )}
                            {values.logoPreviewUrl && (
                              <div>
                                <img alt="logo preview" className={classes.logo} src={values.logoPreviewUrl} />
                              </div>
                            )}
                            {values.logoPreviewUrl && (
                              <IconButton
                                aria-label="remove picture"
                                component="span"
                                onClick={() => {
                                  setFieldValue('logoPreviewUrl', '');
                                  setFieldValue('logo', '');
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <Typography gutterBottom>
                          Excluded from website{' '}
                          <Checkbox
                            onChange={handleChange}
                            id="excludedFromWebsite"
                            value={values.excludedFromWebsite}
                            checked={values.excludedFromWebsite}
                          />
                        </Typography>
                        <div>
                          <InputField
                            error={Boolean(errors.type && touched.type && errors.type)}
                            disabled
                            fullWidth
                            id="type"
                            label="Type"
                            name="type"
                            helperText={errors.type}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.type}
                          />
                        </div>
                        <div>
                          <InputField
                            error={Boolean(errors.description && touched.description && errors.description)}
                            required={!values.excludedFromWebsite}
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                            helperText={errors.description}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                        </div>
                        <div>
                          <InputField
                            error={Boolean(errors.storeLink && touched.storeLink && errors.storeLink)}
                            required={!values.excludedFromWebsite}
                            fullWidth
                            id="storeLink"
                            label="Store link"
                            name="storeLink"
                            helperText={errors.storeLink}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.storeLink}
                          />
                        </div>
                        <div>
                          <InputField
                            error={Boolean(errors.contactEmail && touched.contactEmail && errors.contactEmail)}
                            required={!values.excludedFromWebsite}
                            fullWidth
                            id="contactEmail"
                            label="Email"
                            name="contactEmail"
                            helperText={errors.contactEmail}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactEmail}
                          />
                        </div>
                        <div>
                          <InputField
                            error={Boolean(errors.contactPhone && touched.contactPhone && errors.contactPhone)}
                            required={!values.excludedFromWebsite}
                            fullWidth
                            id="contactPhone"
                            label="Phone"
                            name="contactPhone"
                            helperText={errors.contactPhone}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactPhone}
                          />
                        </div>
                        <div>
                          <InputField
                            error={Boolean(errors.contactWebsite && touched.contactWebsite && errors.contactWebsite)}
                            required={!values.excludedFromWebsite}
                            fullWidth
                            id="contactWebsite"
                            label="Website"
                            name="contactWebsite"
                            helperText={errors.contactWebsite}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactWebsite}
                          />
                        </div>

                        <Typography gutterBottom>
                          Featured on website{' '}
                          <Checkbox
                            onChange={handleChange}
                            id="featuredOnWebsite"
                            value={values.featuredOnWebsite}
                            checked={values.featuredOnWebsite}
                          />
                        </Typography>
                        <div className={classes.uploadImage}>
                          <div className={classes.mh20}>
                            <Typography variant="body1" gutterBottom>
                              Cover Image
                            </Typography>
                          </div>
                          <input
                            name="file"
                            accept="image/*"
                            multiple={false}
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              const base64Image = await convertFileToBase64(file);
                              setFieldValue(`coverImage`, base64Image);

                              setFieldValue(`coverImagePreviewUrl`, URL.createObjectURL(file));
                            }}
                            className={classes.input}
                            id="coverImage"
                            type="file"
                          />
                          {!values.coverImagePreviewUrl && (
                            <div className={classes.image}>
                              <label htmlFor="coverImage">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                            </div>
                          )}

                          {entity.coverImage && !values.coverImagePreviewUrl && (
                            <div>
                              <img alt="coverImage preview" className={classes.logo} src={entity.coverImage} />
                            </div>
                          )}
                          {values.coverImagePreviewUrl && (
                            <div>
                              <img alt="coverImage preview" className={classes.logo} src={values.coverImagePreviewUrl} />
                            </div>
                          )}
                          {values.coverImagePreviewUrl && (
                            <IconButton
                              aria-label="remove picture"
                              component="span"
                              onClick={() => {
                                setFieldValue('coverImagePreviewUrl', '');
                                setFieldValue('coverImage', '');
                              }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}></Grid>
                    </Grid>
                    <Divider style={{ margin: '20px 0' }} />

                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography variant="h6" gutterBottom>
                          Theme Settings
                        </Typography>
                        <div>
                          <InputField
                            error={Boolean(errors.font && touched.font && errors.font)}
                            fullWidth
                            id="theme.font"
                            label="Font"
                            name="theme.font"
                            helperText={errors.font}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.font}
                          />
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.primaryColor && touched.primaryColor && errors.primaryColor)}
                            id="theme.primaryColor"
                            label="primaryColor"
                            name="theme.primaryColor"
                            helperText={errors.primaryColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.primaryColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.primaryColor}`,
                              borderColor: `${values.theme.primaryColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.primaryTextColor && touched.primaryTextColor && errors.primaryTextColor)}
                            id="theme.primaryTextColor"
                            label="primaryTextColor"
                            name="theme.primaryTextColor"
                            helperText={errors.primaryTextColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.primaryTextColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.primaryTextColor}`,
                              borderColor: `${values.theme.primaryTextColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.secondaryColor && touched.secondaryColor && errors.secondaryColor)}
                            id="theme.secondaryColor"
                            label="secondaryColor"
                            name="theme.secondaryColor"
                            helperText={errors.secondaryColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.secondaryColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.secondaryColor}`,
                              borderColor: `${values.theme.secondaryColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.secondaryTextColor && touched.secondaryTextColor && errors.secondaryTextColor)}
                            id="theme.secondaryTextColor"
                            label="secondaryTextColor"
                            name="theme.secondaryTextColor"
                            helperText={errors.secondaryTextColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.secondaryTextColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.secondaryTextColor}`,
                              borderColor: `${values.theme.secondaryTextColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.errorColor && touched.errorColor && errors.errorColor)}
                            id="theme.errorColor"
                            label="errorColor"
                            name="theme.errorColor"
                            helperText={errors.errorColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.errorColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.errorColor}`,
                              borderColor: `${values.theme.errorColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.hoverColor && touched.hoverColor && errors.hoverColor)}
                            id="theme.hoverColor"
                            label="hoverColor"
                            name="theme.hoverColor"
                            helperText={errors.hoverColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.hoverColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.hoverColor}`,
                              borderColor: `${values.theme.hoverColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.selectedColor && touched.selectedColor && errors.selectedColor)}
                            id="theme.selectedColor"
                            label="selectedColor"
                            name="theme.selectedColor"
                            helperText={errors.selectedColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.selectedColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.selectedColor}`,
                              borderColor: `${values.theme.selectedColor}`
                            }}
                          ></div>
                        </div>
                        <div className={classes.colorContainer}>
                          <InputField
                            error={Boolean(errors.successColor && touched.successColor && errors.successColor)}
                            id="theme.successColor"
                            label="successColor"
                            name="theme.successColor"
                            helperText={errors.successColor}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.theme.successColor}
                          />
                          <div
                            className={classes.colorBox}
                            style={{
                              backgroundColor: `${values.theme.successColor}`,
                              borderColor: `${values.theme.successColor}`
                            }}
                          ></div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <ThemePreview theme={values.theme} />
                      </Grid>
                    </Grid>
                    <CardActions className={classes.actions}>
                      <Button type="submit" variant="contained" color="primary" disabled={processing}>
                        Submit
                      </Button>
                      <Button color="secondary" onClick={handleReset}>
                        Clear
                      </Button>
                    </CardActions>
                  </CardContent>
                </Card>
              </form>
            )}
          </Formik>

          {processing ? <LoadingIndicator title="Processing entities data" /> : null}
        </div>
      )}
      {error && <ErrorAlert title={'Error retrieving entity data'} error={error} />}
    </DashboardHOC>
  );
};

EntitiesEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  })
};

export default withStyles(styles)(EntitiesEditPage);
