import imageCompression from 'browser-image-compression';
import imageNotFound from '../images/imageNotFound.png';
import { ENV } from '../env';

export const convertFileToBase64 = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true
  };

  const compressedFile = await imageCompression(file, options);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(compressedFile);

    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
  });
};

export const b64ImagetoBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export async function getBase64ImageFromUrl(url) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data.split(',')[1]);
    };
  });
}

export function autorityNameFormat(name) {
  switch (name) {
    case 'ROLE_CORE':
      return 'Core';
    case 'ROLE_WEB':
      return 'Web/Iframe';
    case 'ROLE_APPLIANCE':
      return 'Appliance';
    case 'ROLE_ADMIN_WEB':
      return 'Admin Web';
    case 'ROLE_MOBILE':
      return 'Mobile';
    default:
      return name;
  }
}

export function handleImageLoadError(ev) {
  ev.target.src = imageNotFound;
}

export function exportToJsonFile(data, filename) {
  const jsonStr = JSON.stringify(data);
  const json = JSON.parse(jsonStr);

  const isPngLink = (str) => str && typeof str === 'string' && str.endsWith('.png');

  const processObject = (obj) => {
    return Object.keys(obj).map((key) => {
      const value = obj[key];
      if (isPngLink(value)) {
        return fetch(value)
          .then((response) => response.blob())
          .then((blob) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                obj[key] = reader.result?.split(',')[1];
                resolve();
              };
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          })
          .catch((error) => {
            console.error('Error downloading PNG:', error);
          });
      } else if (typeof value === 'object') {
        return processObject(value);
      } else {
        return Promise.resolve();
      }
    });
  };

  const promises = processObject(json).flat();

  Promise.all(promises)
    .then(() => {
      const modifiedJsonStr = JSON.stringify({
        meta: { origin: filename.split('.'[0]), env: ENV, date: new Date(Date.now()).toLocaleString() },
        ...json
      });
      const blob = new Blob([modifiedJsonStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('Error processing JSON object:', error);
    });
}

export async function importFromJsonFile(event) {
  const file = event.target.files[0];

  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;
        const jsonData = JSON.parse(contents);
        resolve(jsonData);
      };
      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  }
}
