export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';
export const GET_ACCOUNT_ACTIVITY_REQUEST = 'GET_ACCOUNT_ACTIVITY_REQUEST';
export const GET_ACCOUNT_ACTIVITY_SUCCESS = 'GET_ACCOUNT_ACTIVITY_SUCCESS';
export const GET_ACCOUNT_ACTIVITY_FAILURE = 'GET_ACCOUNT_ACTIVITY_FAILURE';
export const REVOKE_REFRESH_TOKEN_REQUEST = 'REVOKE_REFRESH_TOKEN_REQUEST';
export const REVOKE_REFRESH_TOKEN_SUCCESS = 'REVOKE_REFRESH_TOKEN_SUCCESS';
export const REVOKE_REFRESH_TOKEN_FAILURE = 'REVOKE_REFRESH_TOKEN_FAILURE';
export const SEND_RESET_PASSWORD_EMAIL_REQUEST = 'SEND_RESET_PASSWORD_EMAIL_REQUEST';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_FAILURE = 'SEND_RESET_PASSWORD_EMAIL_FAILURE';
export const RESET_ACCOUNT = 'RESET_ACCOUNT';
