import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';
import moment from 'moment';

import {
  GET_SYSTEM_ERROR_LOGS_REQUEST,
  GET_SYSTEM_ERROR_LOGS_SUCCESS,
  GET_SYSTEM_ERROR_LOGS_FAILURE,
  GET_SYSTEM_MAINTENANCE_REQUEST,
  GET_SYSTEM_MAINTENANCE_SUCCESS,
  GET_SYSTEM_MAINTENANCE_FAILURE,
  POST_SYSTEM_MAINTENANCE_REQUEST,
  POST_SYSTEM_MAINTENANCE_SUCCESS,
  POST_SYSTEM_MAINTENANCE_FAILURE,
  POST_SYSTEM_MAINTENANCE_WHITELISTS_REQUEST,
  POST_SYSTEM_MAINTENANCE_WHITELISTS_SUCCESS,
  POST_SYSTEM_MAINTENANCE_WHITELISTS_FAILURE
} from './constants';

const url = getApiUrl();

export function getErrorLogsAction() {
  return {
    types: [
      GET_SYSTEM_ERROR_LOGS_REQUEST,
      GET_SYSTEM_ERROR_LOGS_SUCCESS,
      GET_SYSTEM_ERROR_LOGS_FAILURE
    ],
    callAPI: () => getErrorLogs()
  };
}

export function getSystemMaintenanceAction() {
  return {
    types: [
      GET_SYSTEM_MAINTENANCE_REQUEST,
      GET_SYSTEM_MAINTENANCE_SUCCESS,
      GET_SYSTEM_MAINTENANCE_FAILURE
    ],
    callAPI: () => getSystemMaintenance()
  };
}

export function postSystemMaintenanceAction(state) {
  return {
    types: [
      POST_SYSTEM_MAINTENANCE_REQUEST,
      POST_SYSTEM_MAINTENANCE_SUCCESS,
      POST_SYSTEM_MAINTENANCE_FAILURE
    ],
    callAPI: () => postSystemMaintenance(state)
  };
}

export function postSystemMaintenanceWhitelistsAction(whitelists) {
  return {
    types: [
      POST_SYSTEM_MAINTENANCE_WHITELISTS_REQUEST,
      POST_SYSTEM_MAINTENANCE_WHITELISTS_SUCCESS,
      POST_SYSTEM_MAINTENANCE_WHITELISTS_FAILURE
    ],
    callAPI: () => postSystemMaintenanceWhitelists(whitelists)
  };
}

const getErrorLogs = () => {
  return httpClient(
    `${url}/admin/system/errorlogs?endTime=${moment().valueOf()}&startTime=${moment()
      .subtract(1, 'month')
      .valueOf()}`,
    {
      method: 'get'
    }
  );
};

const getSystemMaintenance = () => {
  return httpClient(`${url}/admin/system/maintenance`, {
    method: 'get'
  });
};

const postSystemMaintenance = (state) => {
  return httpClient(`${url}/admin/system/maintenance`, {
    method: 'post',
    data: {
      setMaintenance: state
    }
  });
};

const postSystemMaintenanceWhitelists = (whitelists) => {
  return httpClient(`${url}/admin/system/maintenance/whitelists`, {
    method: 'post',
    data: {
      whitelists
    }
  });
};
