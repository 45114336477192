const styles = () => ({
  table: {
    minWidth: 650
  },
  image: {
    height: 30,
    width: 100,
    objectFit: 'contain'
  },
  highlighted: {
    border: '2px solid black'
  },
  tableRow: { cursor: 'pointer' }
});

export default styles;
