const styles = () => ({
  actions: {
    float: 'right'
  },
  mh20: { margin: '0 20px' },
  nameLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  image: {
    maxHeight: '100px',
    maxWidth: '200px',
    margin: '0 16px'
  },
  input: {
    display: 'none'
  },
  uploadImage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  colorContainer: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  colorBox: {
    width: 100,
    height: 40,
    marginLeft: 8,
    marginTop: 4,
    borderRadius: 4,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    border: '1px solid rgb(212, 212, 212)'
  },
  submit: {
    marginTop: '2vh'
  }
});

export default styles;
