import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import DashboardHOC from '../../components/DashboardHOC';
import ClientDetails from './ClientDetails';

import { getClientAction } from './state/clients-actions';

import styles from './ClientPage.styles';

const ClientPage = ({ classes, match, history }) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const clientsState = useSelector((state) => state.clients);
  const { clientData, processingClient, errorClient } = clientsState;

  const { params } = match;

  useEffect(() => {
    stableDispatch(getClientAction(params.id));
  }, [params, stableDispatch]);

  return (
    <DashboardHOC>
      {processingClient && <CircularProgress />}
      {clientData && <ClientDetails clientData={clientData} />}
      {errorClient && (
        <Alert
          className={classes.errorAlert}
          severity="error"
          variant="outlined"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => history.goBack()}
            >
              go back
            </Button>
          }
        >
          {errorClient}
        </Alert>
      )}
    </DashboardHOC>
  );
};

ClientPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClientPage);
