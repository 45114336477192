import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 12
    },
    button: {
      fontSize: 14,
      fontWeight: 800
    }
  },
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      light: "#0066ff",
      main: "#000000",
      contrastText: "#ffcc00"
    },
    text: {
      primary: "#484848"
    }
  }
});

export default theme;
