import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Typography, TablePagination } from '@material-ui/core';

import styles from './OemsOverview.styles';

const OemsOverview = ({ classes, oemsData, error }) => {
  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {oemsData && Array.isArray(oemsData) ? (
        <Paper>
          <TableContainer className={classes.container}>
            <Table className={classes.table} aria-label="oems-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Number of devices</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {oemsData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow
                      key={i}
                      hover
                      onClick={() => {
                        history.push(`/dashboard/devices/${row.oem}`);
                      }}
                    >
                      <TableCell>{row.oem}</TableCell>
                      <TableCell>{row.numberOfDevices}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component="div"
            count={oemsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Typography>
          No clients available or error fetching the clients. Please add a
          client or try again later.
        </Typography>
      )}

      {error && <Typography>{error}</Typography>}
    </div>
  );
};

OemsOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  oemsData: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string
};

export default withStyles(styles)(OemsOverview);
