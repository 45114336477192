import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardHOC from '../../components/DashboardHOC';
import SearchAccountsForm from './SearchAccountsForm';
import { resetAccountAction } from './state/support-accounts-actions';
import { resetWearableAction } from '../support-wearables/state/support-wearables-actions';

const SupportAccountsPage = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAccountAction());
    dispatch(resetWearableAction());
  }, [dispatch]);

  const handleSubmit = (type, value) => {
    history.push(`/dashboard/support/accounts/account/${value}`);
  };

  return (
    <DashboardHOC>
      <SearchAccountsForm
        handleSubmit={(type, value) => handleSubmit(type, value)}
      />
    </DashboardHOC>
  );
};

SupportAccountsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default SupportAccountsPage;
