import {
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  GET_ACCOUNT_ACTIVITY_REQUEST,
  GET_ACCOUNT_ACTIVITY_SUCCESS,
  GET_ACCOUNT_ACTIVITY_FAILURE,
  REVOKE_REFRESH_TOKEN_REQUEST,
  REVOKE_REFRESH_TOKEN_SUCCESS,
  REVOKE_REFRESH_TOKEN_FAILURE,
  SEND_RESET_PASSWORD_EMAIL_REQUEST,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_FAILURE,
  RESET_ACCOUNT
} from './constants';

const defaultState = {
  accountData: null,
  processingAccountData: false,
  accountActivityData: null,
  processingAccountActivity: false,
  errorAccountData: '',
  errorAccountActivity: '',
  refreshTokenRevoked: false,
  processingSendResetPasswordEmail: false,
  errorSendResetPasswordEmail: '',
  resetPasswordEmailSent: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_ACCOUNT_REQUEST:
      return {
        ...state,
        processingAccountData: true,
        errorAccountData: ''
      };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        processingAccountData: false,
        errorAccountData: '',
        accountActivityData: null,
        accountData: action.response.data
      };
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        processingAccountData: false,
        errorAccountData: 'Error getting account details or this account was not found'
      };
    case REVOKE_REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        processingRevokeRefreshToken: true,
        errorRevokeRefreshToken: ''
      };
    case REVOKE_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        processingRevokeRefreshToken: false,
        errorRevokeRefreshToken: '',
        refreshTokenRevoked: true
      };
    case REVOKE_REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        processingRevokeRefreshToken: false,
        refreshTokenRevoked: false,
        errorRevokeRefreshToken: action.error?.message || 'Error revoking refresh token'
      };
    case GET_ACCOUNT_ACTIVITY_REQUEST:
      return {
        ...state,
        processingAccountActivity: true,
        errorAccountActivity: ''
      };
    case GET_ACCOUNT_ACTIVITY_SUCCESS:
      return {
        ...state,
        processingAccountActivity: false,
        errorAccountActivity: '',
        accountActivityData: action.response.data.cloudWatchLogs
      };
    case GET_ACCOUNT_ACTIVITY_FAILURE:
      return {
        ...state,
        processingAccountActivity: false,
        errorAccountActivity: action.error?.message || 'Error getting account activity'
      };
    case SEND_RESET_PASSWORD_EMAIL_REQUEST:
      return {
        ...state,
        processingSendResetPasswordEmail: true,
        errorSendResetPasswordEmail: '',
        resetPasswordEmailSent: false
      };
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        processingSendResetPasswordEmail: false,
        errorSendResetPasswordEmail: '',
        resetPasswordEmailSent: true
      };
    case SEND_RESET_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        processingSendResetPasswordEmail: false,
        errorSendResetPasswordEmail: 'Error sending reset password email'
      };
    case RESET_ACCOUNT:
      return defaultState;
    default:
      return state;
  }
}
