import {
  GET_OEMS_REQUEST,
  GET_OEMS_SUCCESS,
  GET_OEMS_FAILURE
} from './constants';

const defaultState = {
  oemsData: null,
  processing: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_OEMS_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_OEMS_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        oemsData: action.response.data
      };
    case GET_OEMS_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting oems data'
      };
    default:
      return state;
  }
}
