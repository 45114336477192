const styles = (theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  image: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    objectFit: 'contain',
    margin: '0 6px'
  },
  issuerNameImageContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  serviceHistoryContainer: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mv20: { margin: '20px 0' },
  pv0: { paddingBottom: 0, paddingTop: 0 },
  tooltip: {
    maxWidth: '20%'
  },
  infoIcon: { fontSize: 16, color: '#4a88bd', marginLeft: 5 },
  serviceStateContainer: { cursor: 'help', display: 'flex', justifyContent: 'center', alignItems: 'center' }
});

export default styles;
