const styles = () => ({
  errorAlert: { maxWidth: '600px', marginTop: 20 },
  card: {
    maxWidth: 800
  },
  actions: {
    float: 'right'
  },
  smallInput: { maxWidth: 300 },
  textField: {
    margin: '10px 0'
  },
  image: {
    border: '1px solid #ddd',
    width: 343,
    height: 150,
    objectFit: 'contain'
  },
  input: {
    display: 'none'
  }
});

export default styles;
