const styles = (theme) => ({
  root: {
    width: '100%',
    margin: '10px 0'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  tableCell: { paddingBottom: 0, paddingTop: 0 },
  tableCellIconContainer: {
    width: '300px',
    marginLeft: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  iconContainer: { marginRight: '10px' },
  message: { maxWidth: '700px', wordWrap: 'break-word' }
});

export default styles;
