import React from 'react';

import DashboardHOC from '../../components/DashboardHOC';

import HeaderWithButton from '../../components/HeaderWithButton';
import ErrorsPage from './ErrorsPage';
import SystemTools from './SystemTools';

const SystemPage = () => {
  return (
    <DashboardHOC>
      <div>
        <HeaderWithButton headerText="System" />
        <SystemTools />
        <ErrorsPage />
      </div>
    </DashboardHOC>
  );
};

export default SystemPage;
