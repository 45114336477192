import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Divider, withStyles } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { setInitialServicePermsAction, updateServicePermsAction } from './state/support-wearables-actions';

import styles from './ServicePermissions.styles';

function ServicePermissions({ asn, token, permissions, classes }) {
  const dispatch = useDispatch();

  const { servicePerms, errorServicePerms, processingServicePerms } = useSelector((state) => state.supportWearables);

  useEffect(() => {
    if (!servicePerms) dispatch(setInitialServicePermsAction(permissions));
  }, []);

  const handleChange = (event) => {
    const permissions = { ...servicePerms, [event.target.name]: event.target.checked };

    dispatch(updateServicePermsAction(asn, token, permissions));
  };

  return (
    <div>
      <FormControl component="fieldset" error={Boolean(errorServicePerms)} className={classes.container}>
        <FormLabel component="h3" className={classes.label}>
          Service Permissions:
        </FormLabel>
        {processingServicePerms ? (
          <CircularProgress size={20} />
        ) : (
          <div>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={servicePerms?.canDelete || false} onChange={handleChange} name="canDelete" />}
                label="Allow Delete "
              />
            </FormGroup>
            <FormHelperText>{errorServicePerms}</FormHelperText>
          </div>
        )}
      </FormControl>
      <Divider />
    </div>
  );
}

ServicePermissions.propTypes = {
  classes: PropTypes.object,
  asn: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    canDelete: PropTypes.bool
  })
};

export default withStyles(styles)(ServicePermissions);
