import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { signInAction } from './state/sign-in-actions';

import { ENV } from '../../env';

import styles from './LoginPage.styles';

function SignIn({ classes, location, history }) {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.signIn);

  useEffect(() => {
    const { state } = location;

    // TODO refactor repetive props.state.location code

    function fetchLoginToken() {
      const loginToken = sessionStorage.getItem('tokenDetails');

      if (loginToken) {
        if (state?.from?.pathname) {
          history.replace(state.from.pathname);
        } else {
          history.replace('/dashboard');
        }
      }
    }
    fetchLoginToken();
  }, [history, location]);

  useEffect(() => {
    const { state } = location;

    if (loginState.user) {
      if (state?.from?.pathname) {
        history.replace(state.from.pathname);
      } else {
        history.replace('/dashboard');
      }
    }
  }, [loginState.user, history, location]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}></Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(signInAction(values.email, values.password));
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                error={Boolean(errors.email && touched.email && errors.email)}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                helperText={errors.email}
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loginState.processing}
              >
                Sign In
              </Button>
            </form>
          )}
        </Formik>
        <div className={classes.envContainer}>
          <Typography component="h2" variant="h6">
            {`${ENV.charAt(0).toUpperCase() + ENV.slice(1)} Environment`}
          </Typography>
        </div>

        {loginState.error && (
          <div className={classes.form}>
            <Alert variant="outlined" severity="error">
              {loginState.error}
            </Alert>
          </div>
        )}
        {loginState.processing && <CircularProgress />}
      </div>
    </Container>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string
      })
    })
  }).isRequired
};

export default withStyles(styles)(SignIn);
