import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import imageNotFound from '../../images/imageNotFound.png';
import NoData from './NoData';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  image: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    objectFit: 'contain',
    padding: 4,
    margin: '0 6px'
  }
}));

export default function TopWearables({ topWearables }) {
  const classes = useStyles();
  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }
  return (
    <React.Fragment>
      <Typography variant="overline" align="center">
        Top 5 Selling Products
      </Typography>
      <Divider />
      {topWearables ? (
        <List className={classes.root}>
          {topWearables?.map((wearableData, i) => (
            <ListItem key={i}>
              <ListItemAvatar>
                <img
                  alt="wearable"
                  onError={handleImageLoadError}
                  className={classes.image}
                  src={wearableData.device.defaultImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  wearableData.device.brandDisplayName +
                  ' - ' +
                  wearableData.device.modelDisplayName
                }
                secondary={wearableData.noOfWearables + ' Wearables'}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <NoData />
      )}
    </React.Fragment>
  );
}

TopWearables.propTypes = {
  topWearables: PropTypes.arrayOf(PropTypes.object)
};
