import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Button, CardActions, MenuItem, Card, CardContent, IconButton, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';

import DashboardHOC from '../../components/DashboardHOC';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import AlertDialog from '../../components/AlertDialog';
import LoadingIndicator from '../../components/LoadingIndicator';

import { autorityNameFormat } from '../../utils/utils';
import { updateClientAction, resetUpdatedClient, getClientAction } from './state/clients-actions';
import { getSecurityDonglesAction } from '../multos/state/security-dongle-actions';
import useSerialApi from '../../utils/serial-handler';
import { getEntitiesAction } from '../entities/state/entities-actions';
import { convertFileToBase64 } from '../../utils/utils';

import styles from './ClientEditPage.styles';

const ClientEditPage = ({ classes, match }) => {
  const { updateClientCredentials } = useSerialApi();

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let history = useHistory();
  const clientsState = useSelector((state) => state.clients);
  const { clientData, processing, errorUpdateClient, processingUpdateClient, updatedClientData } = clientsState;
  const securityDonglesState = useSelector((state) => state.securityDongles);
  const { securityDonglesData, error } = securityDonglesState;

  const [showAlertSecret, setShowAlertSecret] = useState(false);
  const [secretRevealed, setSecretRevealed] = useState(true);

  const entitiesState = useSelector((state) => state.entities);
  const { entities, processing: processingGetEntities, error: errorGetEntities } = entitiesState;

  useEffect(() => {
    if (!entities) {
      dispatch(getEntitiesAction());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSecurityDonglesAction('available'));

    if (!clientData) {
      stableDispatch(getClientAction(match.params.id));
    }
  }, [clientData, stableDispatch, match]);

  useEffect(() => {
    return () => {
      stableDispatch(resetUpdatedClient());
    };
  }, [stableDispatch]);

  useEffect(() => {
    if (updatedClientData) {
      history.replace(`/dashboard/clients/client/${match.params.id}`);
    }
  }, [updatedClientData, history, match]);

  const URLregex = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const validationSchema = yup.object().shape({
    appStoreName: yup.string(),
    supportEmail: yup.string().email('Enter a valid email'),
    supportUrl: yup.string().matches(URLregex, 'Enter correct url!'),
    androidAppUrl: yup.string().matches(URLregex, 'Enter correct url!'),
    iosAppUrl: yup.string().matches(URLregex, 'Enter correct url!')
  });

  return (
    <DashboardHOC>
      {clientData && !processing ? (
        <div>
          <HeaderWithButton headerText={`Edit an Application`} />
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              entityId: clientData?.entityId,
              name: clientData?.name,
              clientId: match.params.id,
              clientType: clientData?.authorities[0].authority || '',
              adminPin: clientData?.adminPin || '',
              operatorPin: clientData?.operatorPin || '',
              clientSecret: '',
              appStoreName: clientData?.mobileAppDetails?.appStoreName || '',
              androidAppUrl: clientData?.mobileAppDetails?.androidAppUrl || '',
              iosAppUrl: clientData?.mobileAppDetails?.iosAppUrl || '',
              supportEmail: clientData?.mobileAppDetails?.supportEmail || '',
              supportUrl: clientData?.mobileAppDetails?.supportUrl || '',
              emailBanner: clientData?.mobileAppDetails?.emailBanner || '',
              newBannerImage: '',
              newBannerImagePreview: '',
              securityModuleSerialNumber: clientData?.securityModuleSerialNumber || '',
              securityModuleRequired: clientData?.securityModuleRequired || false
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const {
                entityId,
                clientType,
                adminPin,
                operatorPin,
                clientSecret,
                name,
                appStoreName,
                androidAppUrl,
                iosAppUrl,
                supportEmail,
                emailBanner,
                supportUrl,
                newBannerImage,
                securityModuleSerialNumber,
                securityModuleRequired
              } = values;

              const clientModel = {
                name,
                entityId,
                clientType: clientType === 'ROLE_APPLIANCE' ? 'APPLIANCE' : clientType,
                secretRequired: clientType === 'ROLE_APPLIANCE',
                adminPin: clientType === 'ROLE_APPLIANCE' ? adminPin : null,
                operatorPin: clientType === 'ROLE_APPLIANCE' ? operatorPin : null,
                clientSecret: clientType === 'ROLE_APPLIANCE' ? clientSecret : null,
                mobileAppDetails: {
                  appStoreName,
                  androidAppUrl,
                  iosAppUrl,
                  supportEmail,
                  supportUrl,
                  emailBanner: newBannerImage || emailBanner
                },
                securityModuleRequired,
                securityModuleSerialNumber:
                  securityModuleSerialNumber && securityModuleSerialNumber !== 'none' ? securityModuleSerialNumber : null
              };

              if (
                clientData?.securityModuleSerialNumber &&
                securityModuleSerialNumber !== null &&
                clientData?.securityModuleSerialNumber !== securityModuleSerialNumber &&
                securityModuleRequired
              ) {
                const updateClientCredentialsResult = await updateClientCredentials(
                  securityModuleSerialNumber,
                  clientData.clientId,
                  clientSecret
                );
                if (updateClientCredentialsResult) {
                  dispatch(updateClientAction(match.params.id, clientModel));
                }
              } else {
                dispatch(updateClientAction(match.params.id, clientModel));
              }

              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Card className={classes.card}>
                    <CardContent>
                      <div>
                        <InputField
                          select
                          required
                          id="entityId"
                          label="EntityId"
                          value={values.entityId}
                          onChange={handleChange('entityId')}
                          helperText={touched.entityId ? errors.entityId : ''}
                          error={touched.entityId && Boolean(errors.type)}
                          margin="dense"
                          variant="outlined"
                        >
                          {entities?.map((entity) => (
                            <MenuItem key={entity.id} value={entity.id}>
                              {entity.name}
                            </MenuItem>
                          ))}
                        </InputField>
                        <InputField
                          className={classes.smallInput}
                          error={Boolean(errors.name && touched.name && errors.name)}
                          required
                          id="name"
                          label="Name"
                          name="name"
                          helperText={errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          inputProps={{ maxLength: 32 }}
                          fullWidth
                        />
                      </div>
                      <div>
                        <InputField disabled fullWidth id="clientId" label="Client Id" name="clientId" value={values.clientId} />
                      </div>
                      <div>
                        <InputField
                          disabled
                          fullWidth
                          id="clientType"
                          label="Client Type"
                          name="clientType"
                          value={autorityNameFormat(values.clientType)}
                        />
                      </div>
                      {values.clientType === 'ROLE_MOBILE' && (
                        <>
                          <div className={classes.textField}>
                            <Typography>Mobile App Details</Typography>
                          </div>

                          <div>
                            <InputField
                              className={classes.smallInput}
                              error={Boolean(errors.appStoreName && touched.appStoreName && errors.appStoreName)}
                              required
                              id="appStoreName"
                              label="App Store Name"
                              name="appStoreName"
                              helperText={errors.appStoreName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.appStoreName}
                              fullWidth
                            />
                          </div>
                          <div>
                            <InputField
                              className={classes.smallInput}
                              error={Boolean(errors.supportEmail && touched.supportEmail && errors.supportEmail)}
                              required
                              id="supportEmail"
                              label="Support Email"
                              name="supportEmail"
                              helperText={errors.supportEmail}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.supportEmail}
                              inputProps={{ maxLength: 32 }}
                              fullWidth
                            />
                          </div>
                          <div>
                            <InputField
                              error={Boolean(errors.supportUrl && touched.supportUrl && errors.supportUrl)}
                              required
                              id="supportUrl"
                              label="Support Url"
                              name="supportUrl"
                              helperText={errors.supportUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.supportUrl}
                              fullWidth
                            />
                          </div>
                          <div>
                            <InputField
                              error={Boolean(errors.androidAppUrl && touched.androidAppUrl && errors.androidAppUrl)}
                              required
                              id="androidAppUrl"
                              label="Android App Url"
                              name="androidAppUrl"
                              helperText={errors.androidAppUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.androidAppUrl}
                              fullWidth
                            />
                          </div>
                          <div>
                            <InputField
                              error={Boolean(errors.iosAppUrl && touched.iosAppUrl && errors.iosAppUrl)}
                              required
                              id="iosAppUrl"
                              label="iOS App Url"
                              name="iosAppUrl"
                              helperText={errors.iosAppUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.iosAppUrl}
                              fullWidth
                            />
                          </div>
                          <Typography className={classes.textField} variant="body2" gutterBottom>
                            Email Banner
                          </Typography>
                          <Typography variant="subtitle2">
                            <strong>To match the UI, the image must have a ratio of 1/2.3</strong>
                          </Typography>

                          <input
                            name="file"
                            accept="image/*"
                            multiple={false}
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              event.currentTarget.value = ''; // this is to re-trigger onChange after delete if the same file is selected!!
                              const base64Image = await convertFileToBase64(file);
                              setFieldValue(`newBannerImage`, base64Image);

                              setFieldValue(`newBannerImagePreview`, URL.createObjectURL(file));
                            }}
                            className={classes.input}
                            id="newBannerImage"
                            type="file"
                          />
                          <div>
                            <label htmlFor="newBannerImage">
                              <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                              </IconButton>
                            </label>
                          </div>
                          {!values.newBannerImagePreview && values.emailBanner && (
                            <div>
                              <img alt="" className={classes.image} src={`${values.emailBanner}`} />
                            </div>
                          )}

                          {values.newBannerImagePreview && (
                            <>
                              <div>
                                <img alt="" className={classes.image} src={`${values.newBannerImagePreview}`} />
                              </div>

                              <IconButton
                                aria-label="remove picture"
                                component="span"
                                onClick={() => {
                                  setFieldValue('newBannerImagePreview', '');
                                  setFieldValue('newBannerImage', '');
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                      {values.clientType === 'ROLE_APPLIANCE' && (
                        <div>
                          <div>
                            <InputField
                              className={classes.smallInput}
                              error={Boolean(errors.adminPin && touched.adminPin && errors.adminPin)}
                              required
                              id="adminPin"
                              label="Admin Pin (Change env)"
                              name="adminPin"
                              helperText={errors.adminPin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.adminPin}
                              inputProps={{ maxLength: 6 }}
                            />
                          </div>
                          <div>
                            <InputField
                              className={classes.smallInput}
                              error={Boolean(errors.operatorPin && touched.operatorPin && errors.operatorPin)}
                              required
                              id="operatorPin"
                              label="Operator Pin (unlock appliance)"
                              name="operatorPin"
                              helperText={errors.operatorPin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.operatorPin}
                              inputProps={{ maxLength: 6 }}
                            />
                          </div>
                          <div>
                            {/*
                            <FormControl className={clsx(classes.textField)} variant="outlined">
                              {!secretRevealed && (
                                <>
                                  <InputLabel htmlFor="clientSecret">Client Secret</InputLabel>
                                  <OutlinedInput
                                    className={classes.smallInput}
                                    margin="dense"
                                    id="clientSecret"
                                    disabled
                                    type="password"
                                    autoComplete="off"
                                    label="Client Secret"
                                    name="clientSecret"
                                    onClick={() => {
                                      if (!secretRevealed) {
                                        setShowAlertSecret(true);
                                        setSecretRevealed(true);
                                      }
                                    }}
                                    endAdornment={
                                      secretRevealed ? null : (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                              if (!secretRevealed) {
                                                setShowAlertSecret(true);
                                                setSecretRevealed(true);
                                              }
                                            }}
                                          >
                                            {showAlertSecret ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.clientSecret}
                                  />
                                </>
                              )}
                              {!secretRevealed ? (
                                <Typography variant="subtitle2" gutterBottom>
                                  You can reveal the client secret only once. Please use it carefully.
                                </Typography>
                              ) : (
                                <>
                                  <Typography variant="subtitle2" gutterBottom>
                                    You can generate a new Secret Key using the button below
                                  </Typography>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      setFieldValue('clientSecret', randomPassword());
                                      setSecretRevealed(false);
                                    }}
                                  >
                                    Generate New Client Secret Key
                                  </Button>
                                </>
                              )}
                            </FormControl>
                                  */}
                            <AlertDialog
                              showAlert={showAlertSecret}
                              handleClose={() => setShowAlertSecret(false)}
                              title="Warning!"
                              body1="This is the only time this secret access key can be viewed. You cannot recover it later. You can always generate a new key"
                              body2={`Your Secret: ${values.clientSecret}`}
                            />
                          </div>
                          <div>
                            <InputField
                              className={classes.smallInput}
                              required={values.securityModuleRequired ? true : false}
                              select
                              id="securityModuleSerialNumber"
                              label="Security Module"
                              value={values.securityModuleSerialNumber}
                              onChange={handleChange('securityModuleSerialNumber')}
                              helperText={touched.securityModuleSerialNumber ? errors.securityModuleSerialNumber : ''}
                              error={touched.securityModuleSerialNumber && Boolean(errors.type)}
                              margin="dense"
                              variant="outlined"
                            >
                              <MenuItem value={'none'}>None</MenuItem>
                              <MenuItem value={clientData.securityModuleSerialNumber}>{clientData.securityModuleSerialNumber}</MenuItem>

                              {securityDonglesData?.map((securityDongle) => (
                                <MenuItem key={securityDongle.serialNumber} value={securityDongle.serialNumber}>
                                  {securityDongle.serialNumber}
                                </MenuItem>
                              ))}
                            </InputField>
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.securityModuleRequired}
                                  onChange={() => {
                                    // setRequiresSecurityModule(!values.securityModuleRequired);
                                    setFieldValue('securityModuleRequired', !values.securityModuleRequired);
                                  }}
                                  name="securityModuleRequired"
                                  color="primary"
                                />
                              }
                              label="Requires Security Module"
                            />
                          </div>
                        </div>
                      )}
                      <CardActions className={classes.actions}>
                        <Button type="submit" variant="contained" color="primary" disabled={processing || processingUpdateClient}>
                          Submit
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setSecretRevealed(true);
                            handleReset();
                          }}
                        >
                          Clear
                        </Button>
                      </CardActions>
                    </CardContent>
                  </Card>
                  <pre>{JSON.stringify(errors, null, 2)}</pre>
                </form>
              );
            }}
          </Formik>
          {errorUpdateClient && (
            <Alert className={classes.errorAlert} severity="error" variant="outlined">
              {errorUpdateClient}
            </Alert>
          )}
          {processingUpdateClient && <LoadingIndicator />}
        </div>
      ) : (
        <LoadingIndicator title="Retrieving client data" />
      )}
    </DashboardHOC>
  );
};

ClientEditPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClientEditPage);
