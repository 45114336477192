import { ENV } from '../env';

const getApiUrl = () => {
  switch (ENV) {
    case 'development':
      return 'https://www.dev-manage-mii.uk/api';
    case 'staging':
      return 'https://www.staging-manage-mii.uk/api';
    case 'demo':
      return 'https://www.demo-manage-mii.uk/api';
    case 'production':
      return 'https://www.manage-mii.uk/api';
    default:
      return 'https://www.manage-mii.uk/api';
  }
};

export const createServiceConfigIdUrl = (configId) => {
  const AWS_S3_URL = 's3.eu-west-2.amazonaws.com';

  const envUrl = {
    demo: 'demo-managemii',
    development: 'dev-managemii',
    staging: 'staging-managemii',
    production: 'managemii'
  };

  return `https://${envUrl[ENV]}-wearables.${AWS_S3_URL}/service/service-configuration/${configId}.json`;
};

export default getApiUrl;
