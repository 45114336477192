import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Paper, Typography, TablePagination, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CloudDownload } from '@material-ui/icons';

import HeaderWithButton from '../../components/HeaderWithButton';

import styles from './SecurityDonglesOverview.styles';
import { exportToJsonFile } from '../../utils/utils';

const SecurityDonglesOverview = ({ classes, securityDonglesData, error }) => {
  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <HeaderWithButton
        headerText="Security Modules"
        buttonLabel="Read a device"
        buttonLabelSecondary="Setup a device"
        onClick={() => history.push('/dashboard/utils/multos/read/')}
        onClickSecondary={() => history.push('/dashboard/utils/multos/configure/')}
      />
      {securityDonglesData && Array.isArray(securityDonglesData) ? (
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="Clients-table">
              <TableHead>
                <TableRow>
                  <TableCell>Serial Number</TableCell>
                  {/*<TableCell>Type</TableCell>*/}
                  <TableCell>Modulus</TableCell>
                  <TableCell>Assigned To</TableCell>
                  <TableCell>Export</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {securityDonglesData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .sort((a, b) => a.serialNumber - b.serialNumber)
                  .map((securityModule, i) => (
                    <TableRow key={i} hover>
                      <TableCell>{securityModule.serialNumber}</TableCell>
                      {/*<TableCell>{securityModule.type}</TableCell>*/}
                      <TableCell>{securityModule.modulus ? 'TRUE' : 'NONE'}</TableCell>
                      <TableCell
                        component="a"
                        href="#"
                        onClick={() => {
                          if (securityModule.assignedTo && securityModule.assignedTo.includes('@')) {
                            history.push(`/dashboard/support/accounts/account/${securityModule.assignedTo}`);
                          } else {
                            history.push(`/dashboard/clients/client/${securityModule.assignedTo}`);
                          }
                        }}
                      >
                        {securityModule.assignedToName !== undefined ? `${securityModule.assignedToName}` : securityModule.assignedTo}
                      </TableCell>
                      <TableCell>
                        <CloudDownload
                          className={classes.downloadIcon}
                          onClick={() => exportToJsonFile(securityModule, `multos-${securityModule.serialNumber}.json`)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={securityDonglesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Typography>No data available or error fetching applications.</Typography>
      )}

      {error && <Typography>{error}</Typography>}
    </div>
  );
};

SecurityDonglesOverview.propTypes = {
  securityDonglesData: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SecurityDonglesOverview);
