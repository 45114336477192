import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPLOAD_CLIENT_REQUEST,
  UPLOAD_CLIENT_SUCCESS,
  UPLOAD_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  CREATE_CLIENT_RESET,
  UPDATE_CLIENT_RESET
} from './constants';

const url = getApiUrl();

export function resetCreatedClient() {
  return {
    type: CREATE_CLIENT_RESET
  };
}

export function resetUpdatedClient() {
  return {
    type: UPDATE_CLIENT_RESET
  };
}

export function getClientAction(clientId) {
  return {
    types: [GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE],
    callAPI: () => getClient(clientId)
  };
}

export function updateClientAction(clientId, clientModel) {
  return {
    types: [UPDATE_CLIENT_REQUEST, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_FAILURE],
    callAPI: () => updateClient(clientId, clientModel)
  };
}

export function deleteClientAction(clientId) {
  return {
    types: [DELETE_CLIENT_REQUEST, DELETE_CLIENT_SUCCESS, DELETE_CLIENT_FAILURE],
    callAPI: () => deleteClient(clientId)
  };
}

export function getClientsAction(entityId) {
  return {
    types: [GET_CLIENTS_REQUEST, GET_CLIENTS_SUCCESS, GET_CLIENTS_FAILURE],
    callAPI: () => getClients(entityId)
  };
}

export function createClientAction(clientModel) {
  return {
    types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE],
    callAPI: () => createClient(clientModel)
  };
}

export function uploadClientAction(client) {
  return {
    types: [UPLOAD_CLIENT_REQUEST, UPLOAD_CLIENT_SUCCESS, UPLOAD_CLIENT_FAILURE],
    callAPI: () => uploadClient(client)
  };
}

const getClients = (entityId) => {
  return httpClient(`${url}/admin/clients?entity=${entityId}`, {
    method: 'get'
  });
};

const getClient = (clientId) => {
  return httpClient(`${url}/admin/clients/${clientId}`, {
    method: 'get'
  });
};

const createClient = (clientModel) => {
  return httpClient(`${url}/admin/clients`, {
    method: 'post',
    data: clientModel
  });
};

const uploadClient = (client) => {
  console.log({ client });
  return httpClient(`${url}/admin/clients/import`, {
    method: 'post',
    data: client
  });
};

const updateClient = (clientId, clientModel) => {
  return httpClient(`${url}/admin/clients/${clientId}`, {
    method: 'put',
    data: clientModel
  });
};

const deleteClient = (clientId) => {
  return httpClient(`${url}/admin/clients/${clientId}`, {
    method: 'delete'
  });
};
