import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  CREATE_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS, 
  DELETE_DEVICE_FAILURE,
  CREATE_DEVICE_RESET
} from './constants';

const defaultState = {
  devicesData: null,
  processing: false,
  error: '',
  deviceData: null,
  processingDevice: false,
  errorDevice: '',
  createdDeviceData: null,
  processingCreateDevice: false,
  updatedClientData: null,
  errorUpdateDevice: '',
  processingUpdateDevice: false,
  errorCreateDevice: '',
  processingDeleteDevice: false,
  errorDeleteDevice: '',
  registeredVariants: null
};

function parseVariants(str) {
  const start = str.indexOf('[');
  const end = str.indexOf(']');
  const variantsStr = str.substring(start + 1, end);
  return variantsStr.split(',');
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_DEVICES_REQUEST:
      return {
        ...state,
        processing: true,
        error: '',
        errorUpdateDevice: ''
      };
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        devicesData: action.response.data
      };
    case GET_DEVICES_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting devices data'
      };
    case GET_DEVICE_REQUEST:
      return {
        ...state,
        processingDevice: true,
        errorDevice: ''
      };
    case GET_DEVICE_SUCCESS:
      return {
        ...state,
        processingDevice: false,
        errorDevice: '',
        deviceData: action.response.data
      };
    case GET_DEVICE_FAILURE:
      return {
        ...state,
        processingDevice: false,
        errorDevice: action.error?.message || 'Error getting device data'
      };
    case CREATE_DEVICE_REQUEST:
      return {
        ...state,
        processingCreateDevice: true,
        errorCreateDevice: ''
      };
    case CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        processingCreateDevice: false,
        errorCreateDevice: '',
        createdDeviceData: action.response.data
      };
    case CREATE_DEVICE_FAILURE:
      return {
        ...state,
        processingCreateDevice: false,
        errorCreateDevice:
          action.error?.message || 'Error creating a new device'
      };

    case CREATE_DEVICE_RESET:
      return {
        ...state,
        createdDeviceData: null,
        updatedDeviceData: null
      };
    case UPDATE_DEVICE_REQUEST:
      return {
        ...state,
        processingUpdateDevice: true,
        errorUpdateDevice: '',
        registeredVariants: null
      };
    case UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        processingUpdateDevice: false,
        errorUpdateDevice: '',
        registeredVariants: null,
        updatedDeviceData: action.response.data
      };
    case UPDATE_DEVICE_FAILURE: {
      const isVariantReadOnly = action.error.includes(
        'These variants are registered'
      );

      if (isVariantReadOnly) parseVariants(action.error);

      return {
        ...state,
        processingUpdateDevice: false,
        errorUpdateDevice: isVariantReadOnly
          ? 'Some of the variants are registered and cannot be changed'
          : 'Error updating device',
        registeredVariants: isVariantReadOnly
          ? parseVariants(action.error)
          : null
      };    
    }
    case DELETE_DEVICE_REQUEST:
        return {
          ...state,
          processingUpdateDevice: true,
          errorUpdateDevice: ''
        };
      case DELETE_DEVICE_SUCCESS:
        return {
          ...state,
          processingUpdateDevice: false,
          errorUpdateDevice: '',
        };
      case DELETE_DEVICE_FAILURE:
        return {
          ...state,
          processingDeleteDevice: false,
          errorDeleteDevice: action.error || 'Error deleting device'
        };
    default:
      return state;
  }
}
