import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT
} from './constants';

const defaultState = {
  user: null,
  processing: false,
  error: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case SIGN_IN_SUCCESS:
      if (action.response.data?.access_token) {
        sessionStorage.setItem(
          'tokenDetails',
          JSON.stringify(action.response.data.access_token)
        );
        sessionStorage.setItem('scope', action.response.data.scope);
      }
      return {
        ...state,
        processing: false,
        user: action.response
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        processing: false,
        error: 'Invalid email or password'
      };
    case SIGN_OUT:
      sessionStorage.removeItem('tokenDetails');
      return defaultState;
    default:
      return state;
  }
}
