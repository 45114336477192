import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_FILTERED_WEARABLES_REQUEST,
  GET_FILTERED_WEARABLES_FAILURE,
  GET_FILTERED_WEARABLES_SUCCESS
} from './constants';

const url = getApiUrl();

export function getDashboardAction(entitiyId) {
  return {
    types: [
      GET_DASHBOARD_REQUEST,
      GET_DASHBOARD_SUCCESS,
      GET_DASHBOARD_FAILURE
    ],
    callAPI: () => getDashboard(entitiyId)
  };
}

export function getFilteredWearablesAction(filter) {
  return {
    types: [
      GET_FILTERED_WEARABLES_REQUEST,
      GET_FILTERED_WEARABLES_SUCCESS,
      GET_FILTERED_WEARABLES_FAILURE
    ],
    callAPI: () => getFilteredWearables(filter)
  };
}

const getDashboard = (entitiyId) => {
  const entityFilter = entitiyId !== undefined ? `?entity=${entitiyId}` : '';
  return httpClient(`${url}/admin/dashboard${entityFilter}`, {
    method: 'get'
  });
};

const getFilteredWearables = (filter) => {
  return httpClient(`${url}/admin/dashboard/wearables?serviceState=${filter}`, {
    method: 'get'
  });
};
