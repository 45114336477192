import httpClient from '../../../axios';
import getApiUrl, { createServiceConfigIdUrl } from '../../../config/url';

import {
  GET_WEARABLE_BY_BRAND_REQUEST,
  GET_WEARABLE_BY_BRAND_SUCCESS,
  GET_WEARABLE_BY_BRAND_FAILURE,
  GET_WEARABLE_REQUEST,
  GET_WEARABLE_SUCCESS,
  GET_WEARABLE_FAILURE,
  GET_WEARABLE_HISTORY_REQUEST,
  GET_WEARABLE_HISTORY_SUCCESS,
  GET_WEARABLE_HISTORY_FAILURE,
  GET_SERVICE_HISTORY_REQUEST,
  GET_SERVICE_HISTORY_SUCCESS,
  GET_SERVICE_HISTORY_FAILURE,
  RESET_WEARABLE,
  RESET_SERVICE_HISTORY,
  GET_SERVICE_CONFIG_REQUEST,
  GET_SERVICE_CONFIG_SUCCESS,
  GET_SERVICE_CONFIG_FAILURE,
  UPDATE_SERVICE_PERMS_REQUEST,
  UPDATE_SERVICE_PERMS_SUCCESS,
  UPDATE_SERVICE_PERMS_FAILURE,
  UPDATE_WEARABLE_PERMS_REQUEST,
  UPDATE_WEARABLE_PERMS_SUCCESS,
  UPDATE_WEARABLE_PERMS_FAILURE,
  CHANGE_WEARABLE_EMAIL_REQUEST,
  CHANGE_WEARABLE_EMAIL_SUCCESS,
  CHANGE_WEARABLE_EMAIL_FAILURE,
  SET_INITIAL_SERVICE_PERM
} from './constants';

const url = getApiUrl();

// ACTIONS

export function resetWearableAction() {
  return {
    type: RESET_WEARABLE
  };
}

export function resetServiceHistory() {
  return {
    type: RESET_SERVICE_HISTORY
  };
}

export function getWearableByBrandAction(brand, model, version, color) {
  return {
    types: [GET_WEARABLE_BY_BRAND_REQUEST, GET_WEARABLE_BY_BRAND_SUCCESS, GET_WEARABLE_BY_BRAND_FAILURE],
    callAPI: () => getWearableByBrand(brand, model, version, color)
  };
}

export function getWearableAction(type, value) {
  return {
    types: [GET_WEARABLE_REQUEST, GET_WEARABLE_SUCCESS, GET_WEARABLE_FAILURE],
    callAPI: () => getWearable(type, value)
  };
}

export function getWearableHistoryAction(asn, startTime, endTime) {
  return {
    types: [GET_WEARABLE_HISTORY_REQUEST, GET_WEARABLE_HISTORY_SUCCESS, GET_WEARABLE_HISTORY_FAILURE],
    callAPI: () => getWearableHistory(asn, startTime, endTime)
  };
}

export function getServiceHistoryAction(token, startTime, endTime) {
  return {
    types: [GET_SERVICE_HISTORY_REQUEST, GET_SERVICE_HISTORY_SUCCESS, GET_SERVICE_HISTORY_FAILURE],
    callAPI: () => getServiceHistory(token, startTime, endTime),
    payload: { token }
  };
}

export function getServiceConfigAction(configId) {
  return {
    types: [GET_SERVICE_CONFIG_REQUEST, GET_SERVICE_CONFIG_SUCCESS, GET_SERVICE_CONFIG_FAILURE],
    callAPI: () => getServiceConfig(configId)
  };
}

export function updateServicePermsAction(asn, token, perms) {
  return {
    types: [UPDATE_SERVICE_PERMS_REQUEST, UPDATE_SERVICE_PERMS_SUCCESS, UPDATE_SERVICE_PERMS_FAILURE],
    callAPI: () => updateServicePerms(asn, token, perms),
    payload: { payload: perms }
  };
}

export function setInitialServicePermsAction(payload) {
  return {
    type: SET_INITIAL_SERVICE_PERM,
    payload
  };
}

export function updateWearablePermsAction(asn, perms) {
  return {
    types: [UPDATE_WEARABLE_PERMS_REQUEST, UPDATE_WEARABLE_PERMS_SUCCESS, UPDATE_WEARABLE_PERMS_FAILURE],
    callAPI: () => updateWearablePerms(asn, perms)
  };
}

export function changeWearableEmailAction(asn, newEmail) {
  return {
    types: [CHANGE_WEARABLE_EMAIL_REQUEST, CHANGE_WEARABLE_EMAIL_SUCCESS, CHANGE_WEARABLE_EMAIL_FAILURE],
    callAPI: () => changeWearableEmail(asn, newEmail)
  };
}

// APIS

const getAccount = (email) => {
  return httpClient(`${url}/admin/accounts/${email}`, {
    method: 'get'
  });
};

const getWearableByBrand = (brand, model, version, color) => {
  const colorStr = color ? `?color=${color}` : '';

  return httpClient(`${url}/discover/devices/brand/${brand}/model/${model}/version/${version}${colorStr}`, {
    method: 'get'
  });
};

const getWearable = (type, value) => {
  return httpClient(`${url}/admin/wearables/${type}/${value}`, {
    method: 'get'
  });
};

const getWearableHistory = (asn, startTime, endTime) => {
  return httpClient(`${url}/admin/wearables/logs/${asn}?startTime=${startTime}&endTime=${endTime}`, {
    method: 'get'
  });
};

const getServiceHistory = (token, startTime, endTime) => {
  return httpClient(`${url}/admin/wearables/logs/${token}?startTime=${startTime}&endTime=${endTime}`, {
    method: 'get'
  });
};

const getServiceConfig = (configId) => {
  return httpClient(`${createServiceConfigIdUrl(configId)}`, {
    method: 'get'
  });
};

const updateServicePerms = (asn, token, perms) => {
  return httpClient(`${url}/admin/wearables/update-permission/${asn}/${token}`, {
    method: 'post',
    data: perms
  });
};

const updateWearablePerms = (asn, perms) => {
  return httpClient(`${url}/admin/wearables/update-permission/${asn}`, {
    method: 'post',
    data: perms
  });
};

const changeWearableEmail = async (asn, newEmail) => {
  try {
    const userAccount = await getAccount(newEmail);

    if (userAccount?.data?.enabled === 1) {
      return httpClient(`${url}/admin/wearables/${asn}`, {
        method: 'post',
        data: { newEmail }
      });
    }
    if (userAccount?.data?.enabled === 0) {
      return Promise.reject(new Error(`The account ${newEmail} is not active, please ask the user to activate it using Manage-Mii.`));
    }
    return Promise.reject(new Error('Cannot verify if the new account is activated.'));
  } catch (error) {
    if (error.response.status === 404) {
      return Promise.reject(
        new Error(`No account exists with the email: ${newEmail}. Please ask the user to create an account and active it.`)
      );
    } else return Promise.reject(new Error('Cannot verify if the new account is available'));
  }
};
