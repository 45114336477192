import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DashboardHOC from '../../components/DashboardHOC';
import { CircularProgress } from '@material-ui/core';
import { getDevicesAction } from './state/devices-actions';
import DevicesOverview from './DevicesOverview';

const DevicesPage = ({ match }) => {
  const { params } = match;

  const dispatch = useDispatch();
  const devicesState = useSelector((state) => state.devices);

  useEffect(() => {
    dispatch(getDevicesAction(params.oem));
  }, [dispatch, params.oem]);

  const { devicesData, processing, error } = devicesState;

  return (
    <div>
      <DashboardHOC>
        <div>
          {processing ? (
            <CircularProgress />
          ) : (
            <DevicesOverview
              entity={params.oem}
              devicesData={devicesData}
              error={error}
            />
          )}
        </div>
      </DashboardHOC>
    </div>
  );
};

DevicesPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      oem: PropTypes.string
    })
  }).isRequired
};

export default DevicesPage;
