import { combineReducers } from 'redux';
import signIn from '../containers/login/state/sign-in-reducers';
import dashboard from '../containers/dashboard/state/dashboard-reducers';
import supportWearables, {
  getWearableByBrandReducer
} from '../containers/support-wearables/state/support-wearables-reducers';

import supportAccounts from '../containers/support-accounts/state/support-accounts-reducers';
import oems from '../containers/oems/state/oems-reducers';
import devices from '../containers/devices/state/devices-reducers';
import clients from '../containers/clients/state/clients-reducers';
import entities from '../containers/entities/state/entities-reducers';
import system from '../containers/system/state/system-reducers';
import securityDongles from "../containers/multos/state/security-dongle-reducers";

const reducers = combineReducers({
  signIn,
  dashboard,
  supportWearables,
  wearableDetails: getWearableByBrandReducer,
  supportAccounts,
  oems,
  devices,
  clients,
  entities,
  system, 
  securityDongles
});

export default reducers;
