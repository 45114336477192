import React from 'react';
import { PropTypes } from 'prop-types';
import { CardContent, Typography, makeStyles, Button, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import { sendEmailPasswordResetAction } from './state/support-accounts-actions';

import styles from './AccountTools.styles';

const useStyles = makeStyles(styles);

const AccountTools = ({ email, clientId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { processingSendResetPasswordEmail, errorSendResetPasswordEmail, resetPasswordEmailSent } = useSelector(
    (state) => state.supportAccounts
  );

  return (
    <CardContent className={classes.content}>
      <div className={classes.accountToolsContainer}>
        <div>
          <Typography color="textPrimary" variant="body2" gutterBottom>
            <strong>Account Tools</strong>
          </Typography>
        </div>
        <div className={classes.toolsContainer}>
          <div className={classes.button}>
            <Button variant="text" size="small" color="primary" onClick={() => dispatch(sendEmailPasswordResetAction(email, clientId))}>
              send password reset email
            </Button>
          </div>
          {resetPasswordEmailSent && (
            <div>
              <CheckIcon style={{ color: 'green' }} />
            </div>
          )}
          {processingSendResetPasswordEmail && (
            <div>
              <CircularProgress size={'1rem'} />
            </div>
          )}
        </div>
        {errorSendResetPasswordEmail && (
          <Typography variant="caption" color="error">
            Error, please try again later
          </Typography>
        )}
      </div>
    </CardContent>
  );
};

AccountTools.propTypes = {
  email: PropTypes.string.isRequired,
  clientId: PropTypes.string
};
AccountTools.defaultProps = {
  clientId: 'ed61e026-0add-4ea3-b924-107b55c3cbab' // replace from api when available
};
export default AccountTools;
