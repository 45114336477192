import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { updateWearablePermsAction } from './state/support-wearables-actions';

function WearablePermissions({ asn, permissions }) {
  const [perm, setPerm] = React.useState({
    canMobilePerso: false
  });
  const dispatch = useDispatch();
  const { errorWearablePerms, processingWearablePerms } = useSelector(
    (state) => state.supportWearables
  );

  useEffect(() => {
    if (permissions) {
      setPerm(permissions);
    }
  }, [permissions]);

  useEffect(() => {
    if (errorWearablePerms) {
      setPerm({
        canMobilePerso: permissions?.canMobilePerso || false
      });
    }
  }, [errorWearablePerms, permissions]);

  const handleChange = (event) => {
    const permissions = { ...perm, [event.target.name]: event.target.checked };
    dispatch(updateWearablePermsAction(asn, permissions));
    setPerm(permissions);
  };

  const { canMobilePerso } = perm;

  if (processingWearablePerms) return <CircularProgress size={20} />;

  return (
    <div>
      <FormControl component="fieldset" error={Boolean(errorWearablePerms)}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={canMobilePerso}
                onChange={handleChange}
                name="canMobilePerso"
              />
            }
            label="Allow Mobile Perso"
          />
        </FormGroup>
        <FormHelperText>{errorWearablePerms}</FormHelperText>
      </FormControl>
    </div>
  );
}

WearablePermissions.propTypes = {
  asn: PropTypes.string.isRequired,
  error: PropTypes.string,
  permissions: PropTypes.shape({
    canMobilePerso: PropTypes.bool
  })
};

export default WearablePermissions;
