import React, { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getErrorLogsAction } from './state/system-actions';
import ErrorLogsTable from './components/error-logs-table/ErrorLogsTable';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorAlert from '../../components/ErrorAlert';

const ErrorsPage = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const systemState = useSelector((state) => state.system);

  const { errorLogs, processing, error } = systemState;

  // TODO display error retrieving logs

  useEffect(() => {
    stableDispatch(getErrorLogsAction());
  }, [stableDispatch]);

  return (
    <div>
      {error && <ErrorAlert title={'Error retrieving logs'} error={error} />}
      {processing && <LoadingIndicator title={'Retrieving error logs'} />}
      {errorLogs?.enrolmentLogs && (
        <div>
          <ErrorLogsTable
            title={'Registration & Personalisation Errors'}
            errors={errorLogs.enrolmentLogs}
          />
        </div>
      )}

      {errorLogs?.wearableLogs && (
        <div>
          <ErrorLogsTable
            title={'Wearable Management Errors'}
            errors={errorLogs.wearableLogs}
          />
        </div>
      )}
    </div>
  );
};

ErrorsPage.propTypes = {};

export default ErrorsPage;
