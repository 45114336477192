import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, InputAdornment } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InputField from './InputField';

import styles from './ConfirmationTest.styles';

const ConfirmationTest = ({ testString, testResult, classes }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    function verifyStringMatch() {
      if (inputValue === testString) {
        testResult(true);
      } else testResult(false);
    }
    verifyStringMatch();
  }, [inputValue, testResult, testString]);

  return (
    <div>
      <Typography align="center" gutterBottom>
        {testString}
      </Typography>
      <InputField
        id="testInput"
        name="testInput"
        type="text"
        autoComplete="off"
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        InputProps={{
          endAdornment:
            inputValue.length > 0 ? (
              <InputAdornment position="start">
                {inputValue === testString && inputValue.length > 0 ? (
                  <CheckCircleIcon className={classes.checkIcon} />
                ) : (
                  <CancelIcon className={classes.cancelIcon} />
                )}
              </InputAdornment>
            ) : null
        }}
      />
    </div>
  );
};

ConfirmationTest.propTypes = {
  testString: PropTypes.string.isRequired,
  testResult: PropTypes.func,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ConfirmationTest);
