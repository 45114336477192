import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AlertDialog({
  showAlert,
  disableActionButton,
  title,
  body1,
  body2,
  actionButtonLabel,
  closeButtonLabel,
  hideButtons,
  handleAction,
  handleClose,
  component
}) {
  return (
    <div>
      <Dialog open={showAlert} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{body1}</DialogContentText>
          <DialogContentText id="alert-dialog-description">{body2}</DialogContentText>
          {component}
        </DialogContent>
        {!hideButtons && (
          <DialogActions>
            {!disableActionButton && (
              <Button onClick={handleAction} color="primary" autoFocus>
                {actionButtonLabel}
              </Button>
            )}
            <Button onClick={handleClose} color="primary" autoFocus>
              {closeButtonLabel}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  showAlert: PropTypes.bool.isRequired,
  disableActionButton: PropTypes.bool,
  title: PropTypes.string,
  body1: PropTypes.string.isRequired,
  body2: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func,
  actionButtonLabel: PropTypes.string,
  component: PropTypes.element,
  closeButtonLabel: PropTypes.string,
  hideButtons: PropTypes.bool
};

AlertDialog.defaultProps = {
  disableActionButton: false,
  closeButtonLabel: 'Close'
};

export default AlertDialog;
