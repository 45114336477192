const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divider: { width: 1, height: 20, margin: 10 },
  selectInput: { minWidth: 130 },
  textField: { minWidth: 240 },
  errorContainer: { textAlign: 'center' },
  emailLabel: { alignSelf: 'center', margin: '0 10px' }
});

export default styles;
