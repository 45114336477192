import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './MaintenanceMode.styles';

const MaintenanceMode = ({
  maintenanceMode,
  processing,
  classes,
  handleChange
}) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: maintenanceMode ? 'red' : 'black' }}
              checked={maintenanceMode}
              onChange={handleChange}
              name="maintenanceMode"
            />
          }
          label="Maintenance Mode"
        />
      </FormGroup>
      {processing && (
        <div className={classes.processingContainer}>
          <span>processing maintenance request </span>
          <CircularProgress size={20} className={classes.circularProgress} />
        </div>
      )}
    </>
  );
};

MaintenanceMode.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  maintenanceMode: PropTypes.bool.isRequired
};

export default withStyles(styles)(MaintenanceMode);
