import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography, withStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import styles from './ServiceConfig.styles';

const ServiceConfig = ({ serviceConfig, classes, psd2 }) => {
  return (
    <>
      <div className={classes.content}>
        <div>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Currency
          </Typography>
          <Typography
            className={classes.detail}
            variant="body2"
            component="p"
            gutterBottom
          >
            {serviceConfig.currency}
          </Typography>
          {psd2?.currentAmount && (
            <>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Current Amount
              </Typography>
              <Typography
                className={classes.detail}
                variant="body2"
                component="p"
                gutterBottom
              >
                {psd2.currentAmount}
              </Typography>
            </>
          )}
        </div>
        <div>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Low Value Transaction Limit
          </Typography>
          <Typography
            className={classes.detail}
            variant="body2"
            component="p"
            gutterBottom
          >
            {serviceConfig.lowValueTransactionLimit}
          </Typography>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            PSD2 Limit
          </Typography>
          <Typography
            className={classes.detail}
            variant="body2"
            component="p"
            gutterBottom
          >
            {serviceConfig.psd2Limit}
          </Typography>
        </div>
        <div>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            PSD2 Mode
          </Typography>
          <Typography
            className={classes.detail}
            variant="body2"
            component="p"
            gutterBottom
          >
            {serviceConfig.psd2Mode}
          </Typography>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Transactions History Enabled
          </Typography>
          <div>
            {serviceConfig.transactionHistoryEnabled ? (
              <CheckCircleOutlineIcon color="primary" />
            ) : (
              <HighlightOffIcon color="error" />
            )}
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

ServiceConfig.propTypes = {
  classes: PropTypes.object.isRequired,
  serviceConfig: PropTypes.object.isRequired,
  psd2: PropTypes.object
};

export default withStyles(styles)(ServiceConfig);
