export const GET_WEARABLE_REQUEST = 'GET_WEARABLE_REQUEST';
export const GET_WEARABLE_SUCCESS = 'GET_WEARABLE_SUCCESS';
export const GET_WEARABLE_FAILURE = 'GET_WEARABLE_FAILURE';
export const GET_WEARABLE_BY_BRAND_REQUEST = 'GET_WEARABLE_BY_BRAND_REQUEST';
export const GET_WEARABLE_BY_BRAND_SUCCESS = 'GET_WEARABLE_BY_BRAND_SUCCESS';
export const GET_WEARABLE_BY_BRAND_FAILURE = 'GET_WEARABLE_BY_BRAND_FAILURE';
export const GET_WEARABLE_HISTORY_REQUEST = 'GET_WEARABLE_HISTORY_REQUEST';
export const GET_WEARABLE_HISTORY_SUCCESS = 'GET_WEARABLE_HISTORY_SUCCESS';
export const GET_WEARABLE_HISTORY_FAILURE = 'GET_WEARABLE_HISTORY_FAILURE';
export const GET_SERVICE_HISTORY_REQUEST = 'GET_SERVICE_HISTORY_REQUEST';
export const GET_SERVICE_HISTORY_SUCCESS = 'GET_SERVICE_HISTORY_SUCCESS';
export const GET_SERVICE_HISTORY_FAILURE = 'GET_SERVICE_HISTORY_FAILURE';
export const RESET_WEARABLE = 'RESET_WEARABLE';
export const RESET_SERVICE_HISTORY = 'RESET_SERVICE_HISTORY';
export const GET_SERVICE_CONFIG_REQUEST = 'GET_SERVICE_CONFIG_REQUEST';
export const GET_SERVICE_CONFIG_SUCCESS = 'GET_SERVICE_CONFIG_SUCCESS';
export const GET_SERVICE_CONFIG_FAILURE = 'GET_SERVICE_CONFIG_FAILURE';
export const UPDATE_SERVICE_PERMS_SUCCESS = 'UPDATE_SERVICE_PERMS_SUCCESS';
export const SET_INITIAL_SERVICE_PERM = 'SET_INITIAL_SERVICE_PERM';
export const UPDATE_SERVICE_PERMS_REQUEST = 'UPDATE_SERVICE_PERMS_REQUEST';
export const UPDATE_SERVICE_PERMS_FAILURE = 'UPDATE_SERVICE_PERMS_FAILURE';
export const UPDATE_WEARABLE_PERMS_SUCCESS = 'UPDATE_WEARABLE_PERMS_SUCCESS';
export const UPDATE_WEARABLE_PERMS_REQUEST = 'UPDATE_WEARABLE_PERMS_REQUEST';
export const UPDATE_WEARABLE_PERMS_FAILURE = 'UPDATE_WEARABLE_PERMS_FAILURE';
export const CHANGE_WEARABLE_EMAIL_REQUEST = 'CHANGE_WEARABLE_EMAIL_REQUEST';
export const CHANGE_WEARABLE_EMAIL_SUCCESS = 'CHANGE_WEARABLE_EMAIL_SUCCESS';
export const CHANGE_WEARABLE_EMAIL_FAILURE = 'CHANGE_WEARABLE_EMAIL_FAILURE';
