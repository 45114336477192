import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider, Paper, Grid, Button } from '@material-ui/core';
import HeaderWithButton from '../../components/HeaderWithButton';
import AlertDialog from '../../components/AlertDialog';
import ConfirmationTest from '../../components/ConfirmationTest';

import { autorityNameFormat, exportToJsonFile } from '../../utils/utils';
import { deleteClientAction } from './state/clients-actions';

import styles from './ClientDetails.styles';

import imageNotFound from '../../images/imageNotFound.png';

const ClientDetails = ({ classes, clientData }) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const { clientId, name, authorities, adminPin, operatorPin, mobileAppDetails, entityId } = clientData;
  const [deletable, setDeletable] = useState(false);

  let history = useHistory();
  const dispatch = useDispatch();

  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }

  function handleDeleteClient() {
    if (deletable) {
      dispatch(deleteClientAction(clientId));
      history.goBack();
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <HeaderWithButton
              headerText={`Application Details`}
              buttonLabel={authorities[0].authority === 'ROLE_APPLIANCE' || authorities[0].authority === 'ROLE_MOBILE' ? 'Edit' : null}
              onClick={() => history.push(`/dashboard/clients/edit/${clientData.clientId}`)}
              buttonLabelSecondary="Delete"
              warning
              onClickSecondary={() => setShowDeleteAlert(true)}
              ExtraButton={() => (
                <Button variant="outlined" onClick={() => exportToJsonFile(clientData, `${clientId}.json`)}>
                  Export application
                </Button>
              )}
            />
            <Typography variant="body2" gutterBottom>
              Entity Id
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {entityId}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Name
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {name}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              ClientId
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {clientId}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom>
              Client Type
            </Typography>
            <Typography variant="body1" color="textPrimary" gutterBottom>
              {autorityNameFormat(authorities[0].authority)}
            </Typography>
            {adminPin && (
              <div>
                <Divider className={classes.divider} />
                <Typography variant="body2" gutterBottom>
                  Admin Pin (Used to switch env)
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {adminPin}
                </Typography>
              </div>
            )}
            {operatorPin && (
              <div>
                <Divider className={classes.divider} />
                <Typography variant="body2" gutterBottom>
                  Operator Pin (Used to unlock appliance)
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {operatorPin}
                </Typography>
              </div>
            )}
            <Divider className={classes.divider} />
            {mobileAppDetails && (
              <>
                <Typography className={classes.divider} variant="h6" color="textPrimary" gutterBottom>
                  Mobile App Details
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Android App Url
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {mobileAppDetails.androidAppUrl}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="body2" gutterBottom>
                  App Store Name
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {mobileAppDetails.appStoreName}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="body2" gutterBottom>
                  iOS App Url
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {mobileAppDetails.iosAppUrl}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="body2" gutterBottom>
                  Support Email
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {mobileAppDetails.supportEmail}
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="body2" gutterBottom>
                  Support Url
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {mobileAppDetails.supportUrl}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Email Banner
                </Typography>
                <img className={classes.image} src={mobileAppDetails.emailBanner} onError={handleImageLoadError} />
                <Divider className={classes.divider} />
              </>
            )}

            {authorities[0].authority === 'ROLE_APPLIANCE' && (
              <>
                <Typography variant="body2" gutterBottom>
                  Requires Security Module
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {clientData?.securityModuleRequired ? 'YES' : 'NO'}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Assigned Security Module
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {clientData?.securityModuleSerialNumber}
                </Typography>
              </>
            )}

            <AlertDialog
              showAlert={showDeleteAlert}
              handleClose={() => setShowDeleteAlert(false)}
              title="Warning!"
              body1="Please confirm deleting this Application by entering the clientId below"
              component={<ConfirmationTest testString={clientId} testResult={(result) => setDeletable(result)} />}
              actionButtonLabel="submit"
              handleAction={() => {
                handleDeleteClient();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

ClientDetails.propTypes = {
  clientData: PropTypes.shape({
    entityId: PropTypes.string,
    clientId: PropTypes.string,
    name: PropTypes.string,
    authorities: PropTypes.arrayOf(PropTypes.object),
    resourceIds: PropTypes.arrayOf(PropTypes.string),
    adminPin: PropTypes.string,
    operatorPin: PropTypes.string,
    securityModuleSerialNumber: PropTypes.string,
    securityModuleRequired: PropTypes.bool,
    mobileAppDetails: PropTypes.shape({
      androidAppUrl: PropTypes.string,
      iosAppUrl: PropTypes.string,
      supportUrl: PropTypes.string,
      supportEmail: PropTypes.string,
      emailBanner: PropTypes.string,
      appStoreName: PropTypes.string
    })
  }).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClientDetails);
