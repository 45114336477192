import CryptoJS from 'crypto-js';

export const decodeJwt = (token) => {
  const jwtBody = token.split('.')[1];
  return JSON.parse(
    CryptoJS.enc.Base64.parse(jwtBody).toString(CryptoJS.enc.Utf8)
  );
};

export const decodeMobileDevice = (string) => {
  return JSON.parse(
    CryptoJS.enc.Base64.parse(string).toString(CryptoJS.enc.Utf8)
  );
};

export const isActiveJwt = (token) => {
  const decodedToken = decodeJwt(token);
  const currentTime = Date.now() / 1000;
  if (decodedToken !== undefined) {
    return decodedToken.exp && decodedToken.exp > currentTime;
  } else return false;
};

export const randomPassword = (length = 16) => {
  const chars =
    'abcdefghijklmnopqrstuvwxyz!@#$%^&()-+<>ABCDEFGHIJKLMNOP1234567890';
  let pass = '';
  for (let x = 0; x < length; x++) {
    const i = Math.floor(Math.random() * chars.length);
    pass += chars.charAt(i);
  }
  return pass;
};