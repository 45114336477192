import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Paper, Typography, TablePagination, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import HeaderWithButton from '../../components/HeaderWithButton';

import { autorityNameFormat } from '../../utils/utils';

import styles from './ClientsOverview.styles';

const ClientsOverview = ({ classes, entityId, clientsData, error }) => {
  let history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <HeaderWithButton
        headerText="Applications"
        buttonLabel="Add an Application"
        onClick={() => history.push(`/dashboard/clients/create/${entityId}`)}
      />
      {clientsData && Array.isArray(clientsData) ? (
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="Clients-table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Client Type</TableCell>
                  <TableCell>Client ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                  <TableRow
                    key={i}
                    hover
                    onClick={() => {
                      history.push(`/dashboard/clients/client/${row.clientId}`);
                    }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{autorityNameFormat(row.authorities?.[0].authority)}</TableCell>
                    <TableCell>{row.clientId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={clientsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Typography>No data available or error fetching applications.</Typography>
      )}

      {error && <Typography>{error}</Typography>}
    </div>
  );
};

ClientsOverview.propTypes = {
  entityId: PropTypes.string.isRequired,
  clientsData: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClientsOverview);
