import axios from 'axios';
import { isActiveJwt } from './utils/cryptography';
import store from './redux/store';
import { signOutAction } from './containers/login/state/sign-in-actions';

const httpClient = axios;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

const axiosOnSuccessRequestInterceptor = async (config) => {
  const storedTokenDetails = await sessionStorage.getItem('tokenDetails');
  if (storedTokenDetails) {
    const tokenDetails = JSON.parse(storedTokenDetails);

    if (!isActiveJwt(tokenDetails)) store.dispatch(signOutAction());
    // eslint-disable-next-line no-param-reassign

    if (!config.url.includes('s3.eu-west-2.amazonaws.com'))
      config.headers.Authorization = `Bearer ${tokenDetails}`;
  }
  return config;
};

export const axiosOnErrorRequestInterceptor = (error) => Promise.reject(error);

export const axiosOnSuccessResponseInterceptor = (response) => response;

const axiosOnErrorResponseInterceptor = (error) => Promise.reject(error);

// Interceptors
axios.interceptors.request.use(
  axiosOnSuccessRequestInterceptor,
  axiosOnErrorRequestInterceptor
);

axios.interceptors.response.use(
  axiosOnSuccessResponseInterceptor,
  axiosOnErrorResponseInterceptor
);

export default httpClient;
