import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withFormik, FieldArray } from 'formik';
import { withStyles, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Button from '@material-ui/core/Button';
import DashboardHOC from '../../components/DashboardHOC';

import { convertFileToBase64 } from '../../utils/utils';
import { createDeviceAction, resetCreatedDevice } from './state/devices-actions';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import ErrorAlert from '../../components/ErrorAlert';

import styles from './DevicesCreate.styles';
import { readZipFile } from '../../utils/zipUtils';

const type = [
  { value: 'JEWELRY', label: 'Jewelry' },
  { value: 'WATCH_OR_WRISTBAND', label: 'Watch or Wristband' },
  { value: 'TAG', label: 'Tag' }
];

const Form = (props) => {
  const { devicesState, resetDevice } = props;

  const [errorImportDevice, setErrorImportDevice] = useState();
  const [readOnly, setReadOnly] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (devicesState.createdDeviceData) {
      const { brand, model } = devicesState.createdDeviceData;
      history.replace(`/dashboard/device/brand/${brand}/model/${model.name}/version/${model.version}`);
      resetDevice();
    }
  }, [devicesState, history, resetDevice]);

  const {
    match,
    classes,
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue
  } = props;

  return (
    <DashboardHOC>
      {errorImportDevice && <ErrorAlert title={'Error importing device from file'} error={errorImportDevice} />}

      <div style={{ maxWidth: '800px' }}>
        <HeaderWithButton
          headerText="Add Device"
          ExtraButton={() => (
            <>
              <input
                id="myInput"
                type="file"
                style={{ opacity: 0 }}
                onChange={(event) => {
                  setErrorImportDevice(null);
                  readZipFile(event, { oem: match.params.oem }, { setErrorImportDevice, setFieldValue, setReadOnly });
                }}
              />
              <label htmlFor="myInput">
                <Button color="primary" aria-label="upload picture" component="span" variant="outlined">
                  Import Device from File
                </Button>
              </label>
            </>
          )}
        />
        <form onSubmit={handleSubmit}>
          <Card className={classes.card}>
            <CardContent>
              <InputField id="entityId" label="Entity Id" value={match.params.oem} disabled margin="dense" variant="outlined" fullWidth />
              <InputField
                id="brand"
                label="Brand"
                disabled={readOnly}
                value={values.brand}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.brand ? errors.brand : ''}
                error={touched.brand && Boolean(errors.brand)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                id="model"
                label="Model"
                disabled={readOnly}
                value={values.model}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.model ? errors.model : ''}
                error={touched.model && Boolean(errors.model)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                id="version"
                label="Version"
                value={values.version}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.version ? errors.version : ''}
                error={touched.version && Boolean(errors.version)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                id="tdp"
                label="TDP"
                type="tdp"
                value={values.tdp}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.tdp ? errors.tdp : ''}
                error={touched.tdp && Boolean(errors.tdp)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                id="tdpIdentifier"
                label="TDP Identifier"
                value={values.tdpIdentifier}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.tdpIdentifier ? errors.tdpIdentifier : ''}
                error={touched.tdpIdentifier && Boolean(errors.tdpIdentifier)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                disabled={readOnly}
                id="description"
                label="Description"
                type="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.description ? errors.description : ''}
                error={touched.description && Boolean(errors.description)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                id="storeLink"
                label="Store url"
                type="storeLink"
                value={values.storeLink}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.storeLink ? errors.storeLink : ''}
                error={touched.storeLink && Boolean(errors.storeLink)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                id="brandMobileVideoTrailer"
                label="Video trailer url"
                type="brandMobileVideoTrailer"
                value={values.brandMobileVideoTrailer}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.brandMobileVideoTrailer ? errors.brandMobileVideoTrailer : ''}
                error={touched.brandMobileVideoTrailer && Boolean(errors.brandMobileVideoTrailer)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <InputField
                select
                id="type"
                label="Type"
                value={values.type}
                onChange={handleChange('type')}
                helperText={touched.type ? errors.type : ''}
                error={touched.type && Boolean(errors.type)}
                margin="dense"
                variant="outlined"
                fullWidth
              >
                {type.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
              <InputField
                id="typeCode"
                label="Type Code"
                type="typeCode"
                value={values.typeCode}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.typeCode ? errors.typeCode : ''}
                error={touched.typeCode && Boolean(errors.typeCode)}
                margin="dense"
                variant="outlined"
                fullWidth
              />
              <Typography gutterBottom>
                Rcos NOT supported{' '}
                <Checkbox checked={values.rcosNotSupported} onChange={handleChange} id="rcosNotSupported" value={values.rcosNotSupported} />
              </Typography>
              <Typography gutterBottom>
                Tokenisation NOT supported{' '}
                <Checkbox
                  checked={values.tokenisationNotSupported}
                  onChange={handleChange}
                  id="tokenisationNotSupported"
                  value={values.tokenisationNotSupported}
                />
              </Typography>
              <Typography gutterBottom>
                Promo Ready NOT supported{' '}
                <Checkbox
                  checked={values.promoReadyNotSupported}
                  onChange={handleChange}
                  id="promoReadyNotSupported"
                  value={values.promoReadyNotSupported}
                />
              </Typography>
              <Typography gutterBottom>
                Excluded from website{' '}
                <Checkbox
                  onChange={handleChange}
                  id="excludedFromWebsite"
                  value={values.excludedFromWebsite}
                  checked={values.excludedFromWebsite}
                />
              </Typography>
              <Typography gutterBottom>
                Featured on website{' '}
                <Checkbox
                  onChange={handleChange}
                  id="featuredOnWebsite"
                  value={values.featuredOnWebsite}
                  checked={values.featuredOnWebsite}
                />
              </Typography>
              <div className={classes.uploadImage}>
                <Typography gutterBottom>Default Image</Typography>
                <input
                  disabled={readOnly}
                  name="file"
                  accept="image/*"
                  multiple={false}
                  onChange={async (event) => {
                    const file = event.currentTarget.files[0];
                    const base64Image = await convertFileToBase64(file);
                    setFieldValue(`defaultImage`, base64Image);

                    setFieldValue(`defaultImagePreviewUrl`, URL.createObjectURL(file));
                  }}
                  className={classes.input}
                  id="defaultImage"
                  type="file"
                />
                {!values.defaultImagePreviewUrl && (
                  <div className={classes.image}>
                    <label htmlFor="defaultImage">
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </div>
                )}
                {values.defaultImagePreviewUrl && (
                  <div>
                    <img alt="default" className={classes.image} src={values.defaultImagePreviewUrl} />
                  </div>
                )}
                {values.defaultImagePreviewUrl && !readOnly && (
                  <IconButton
                    aria-label="remove picture"
                    component="span"
                    onClick={() => {
                      setFieldValue('defaultImagePreviewUrl', '');
                      setFieldValue('defaultImage', '');
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                )}
              </div>
              <FieldArray
                name="variants"
                render={(arrayHelpers) => (
                  <div>
                    {values.variants.map((variant, variantIndex) => (
                      <div className={classes.variantContainer} key={variantIndex}>
                        <div>
                          <Typography variant="body2">{`${variantIndex + 1}.`}</Typography>
                        </div>
                        <span className={classes.variantInput}>
                          <InputField
                            disabled={readOnly}
                            placeholder="description"
                            value={values.variants[variantIndex].color}
                            name={`variants[${variantIndex}].color`}
                            onChange={(e) => {
                              setFieldValue(`variants[${variantIndex}].color`, e.target.value);
                            }}
                            type="input"
                          />
                        </span>
                        <input
                          disabled={readOnly}
                          name="file"
                          accept="image/*"
                          multiple={false}
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            const base64Image = await convertFileToBase64(file);
                            setFieldValue(`variants[${variantIndex}].image`, base64Image);

                            setFieldValue(`variants[${variantIndex}].imagePreview`, URL.createObjectURL(file));
                          }}
                          className={classes.input}
                          id={`variantImage-${variantIndex}`}
                          type="file"
                        />
                        {!values.variants[variantIndex].imagePreview && !readOnly && (
                          <label htmlFor={`variantImage-${variantIndex}`}>
                            <IconButton color="primary" aria-label="upload picture" component="span">
                              <PhotoCamera />
                            </IconButton>
                          </label>
                        )}
                        {values.variants[variantIndex].imagePreview && (
                          <div>
                            <img alt="variant" className={classes.image} src={values.variants[variantIndex].imagePreview} />
                          </div>
                        )}

                        {!readOnly && (
                          <IconButton onClick={() => arrayHelpers.remove(variantIndex)}>
                            <DeleteIcon color="error" type="button" />
                          </IconButton>
                        )}
                      </div>
                    ))}
                    {!readOnly && (
                      <div className={classes.addVariantButton}>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() =>
                            arrayHelpers.push({
                              color: '',
                              image: '',
                              imagePreview: ''
                            })
                          }
                        >
                          Add Variant
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />
            </CardContent>
            <CardActions className={classes.actions}>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                SUBMIT
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  setReadOnly(false);
                  handleReset();
                }}
              >
                CLEAR
              </Button>
            </CardActions>
          </Card>
          {devicesState.errorCreateDevice && <ErrorAlert title={'Error creating device'} error={devicesState.errorCreateDevice} />}
        </form>
      </div>
    </DashboardHOC>
  );
};

const isValidUrl = (url) => {
  try {
    const validUrl = new URL(url);
    return validUrl.protocol === 'https:' || validUrl.protocol === 'http:';
  } catch (e) {
    console.log({ e });
    return false;
  }
};

const DevicesCreate = withFormik({
  mapPropsToValues: ({
    defaultImage,
    brand,
    model,
    version,
    tdp,
    tdpIdentifier,
    type,
    typeCode,
    variants,
    entityId,
    description,
    storeLink,
    brandMobileVideoTrailer,
    excludedFromWebsite,
    featuredOnWebsite,
    rcosNotSupported,
    tokenisationNotSupported,
    promoReadyNotSupported
  }) => {
    return {
      entityId: entityId || '',
      defaultImage: defaultImage || '',
      model: model || '',
      brand: brand || '',
      version: version || '',
      tdp: tdp || '',
      tdpIdentifier: tdpIdentifier || '',
      type: type || '',
      typeCode: typeCode || '',
      variants: variants || [],
      description: description || '',
      storeLink: storeLink || '',
      brandMobileVideoTrailer: brandMobileVideoTrailer || '',
      excludedFromWebsite: excludedFromWebsite || false,
      featuredOnWebsite: featuredOnWebsite || false,
      rcosNotSupported: rcosNotSupported || false,
      tokenisationNotSupported: tokenisationNotSupported || false,
      promoReadyNotSupported: promoReadyNotSupported || false
    };
  },

  validationSchema: Yup.object().shape({
    model: Yup.string().required('Required'),
    version: Yup.string().required('Required'),
    tdp: Yup.number('Tdp must be a number').required('Tdp is required'),
    tdpIdentifier: Yup.string().required('Required'),
    type: Yup.string().required('Select your type category'),
    storeLink: Yup.string().test('is-url-valid', 'URL is not valid', (value) => !value || isValidUrl(value)),
    brandMobileVideoTrailer: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    const { match } = props;

    const {
      defaultImage,
      model,
      version,
      tdp,
      tdpIdentifier,
      type,
      typeCode,
      variants,
      description,
      storeLink,
      brand,
      brandMobileVideoTrailer,
      excludedFromWebsite,
      featuredOnWebsite,
      rcosNotSupported,
      tokenisationNotSupported,
      promoReadyNotSupported
    } = values;

    const deviceModel = {
      entityId: match.params.oem,
      brand,
      defaultImage,
      model: { name: model, version },
      nfcType: 'PASSIVE',
      tdpId: parseInt(tdp),
      tdpIdentifier,
      type,
      typeCode,
      variants: variants.length > 0 ? variants : null,
      description,
      storeLink,
      brandMobileVideoTrailer,
      excludedFromWebsite,
      featuredOnWebsite,
      rcosNotSupported,
      tokenisationNotSupported,
      promoReadyNotSupported
    };

    props.createDevice(deviceModel);
    setSubmitting(false);
  }
})(Form);

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      oem: PropTypes.string
    })
  }).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]),
  values: PropTypes.oneOfType([PropTypes.object]),
  touched: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.object]),
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  devicesState: PropTypes.shape({
    createdDeviceData: PropTypes.object,
    errorCreateDevice: PropTypes.string
  }),
  resetDevice: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  devicesState: state.devices
});

const mapDispatchToProps = (dispatch) => ({
  createDevice: (deviceModel) => dispatch(createDeviceAction(deviceModel)),
  resetDevice: () => dispatch(resetCreatedDevice())
});

const ConnectedCreateForm = connect(mapStateToProps, mapDispatchToProps)(DevicesCreate);

export default withStyles(styles)(ConnectedCreateForm);
