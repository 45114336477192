import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid, CardActions, Divider, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import DashboardHOC from '../../components/DashboardHOC';
import { Card, CardContent, Typography } from '@material-ui/core';
import { createEntityAction, resetCreatedEntity } from './state/entities-actions';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import ThemePreview from './components/ThemePreview';
import ErrorAlert from '../../components/ErrorAlert';
import LoadingIndicator from '../../components/LoadingIndicator';
import { convertFileToBase64, importFromJsonFile } from '../../utils/utils';

import styles from './EntitiesCreatePage.styles';

const EntitiesCreatePage = ({ classes }) => {
  const formikRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let history = useHistory();
  const entitiesState = useSelector((state) => state.entities);
  const { processing, error } = entitiesState;

  useEffect(() => {
    if (entitiesState.createdEntityData) {
      // TODO to redirect to the newly created entity - see create device

      history.replace(`/dashboard/entities`);
      stableDispatch(resetCreatedEntity());
    }
  }, [entitiesState, stableDispatch, history]);

  const handleImportJson = async (ev) => {
    try {
      const json = await importFromJsonFile(ev);

      if (formikRef.current) {
        formikRef.current.setValues({ ...json });
        formikRef.current.setFieldValue('logoPreviewUrl', `data:image/png;base64,${json.logo}`);
        formikRef.current.setFieldValue('coverImagePreviewUrl', `data:image/png;base64,${json.coverImage}`);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <DashboardHOC>
      <div>
        <HeaderWithButton
          headerText="Add an Entity"
          ExtraButton={() => (
            <>
              <input
                type="file"
                onChange={(ev) => {
                  handleImportJson(ev);
                }}
                accept=".json"
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <Button variant="outlined" onClick={() => fileInputRef.current.click()}>
                Import entity
              </Button>
            </>
          )}
        />
        <Formik
          innerRef={formikRef}
          initialValues={{
            name: '',
            logo: null,
            coverImage: null,
            contact: '',
            description: '',
            storeLink: '',
            contactEmail: '',
            contactPhone: '',
            contactWebsite: '',
            excludedFromWebsite: false,
            featuredOnWebsite: false,
            type: 'OEM',
            theme: {
              errorColor: '',
              font: '',
              hoverColor: '',
              primaryColor: '',
              primaryTextColor: '',
              secondaryColor: '',
              secondaryTextColor: '',
              selectedColor: '',
              successColor: ''
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
            const {
              name,
              logo,
              description,
              storeLink,
              contactEmail,
              contactPhone,
              contactWebsite,
              coverImage,
              featuredOnWebsite,
              excludedFromWebsite
            } = values;

            dispatch(
              createEntityAction(
                {
                  name,
                  logo,
                  description,
                  storeLink,
                  contact: {
                    email: contactEmail,
                    phone: contactPhone,
                    website: contactWebsite
                  },
                  coverImage,
                  excludedFromWebsite,
                  featuredOnWebsite
                },
                values.theme,
                values.type
              )
            );
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className={classes.nameLogoContainer}>
                        <div>
                          <InputField
                            error={Boolean(errors.name && touched.name && errors.name)}
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            helperText={errors.name}
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        </div>

                        <div className={classes.uploadImage}>
                          <div className={classes.mh20}>
                            <Typography variant="body1" gutterBottom>
                              Logo
                            </Typography>
                          </div>
                          <input
                            name="file"
                            accept="image/*"
                            multiple={false}
                            onChange={async (event) => {
                              const file = event.currentTarget.files[0];
                              const base64Image = await convertFileToBase64(file);
                              setFieldValue(`logo`, base64Image);

                              setFieldValue(`logoPreviewUrl`, URL.createObjectURL(file));
                            }}
                            className={classes.input}
                            id="logo"
                            type="file"
                          />
                          {!values.logoPreviewUrl && (
                            <div className={classes.image}>
                              <label htmlFor="logo">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  <PhotoCamera />
                                </IconButton>
                              </label>
                            </div>
                          )}
                          {values.logoPreviewUrl && (
                            <div>
                              <img alt="logo preview" className={classes.image} src={values.logoPreviewUrl} />
                            </div>
                          )}
                          {values.logoPreviewUrl && (
                            <IconButton
                              aria-label="remove picture"
                              component="span"
                              onClick={() => {
                                setFieldValue('logoPreviewUrl', '');
                                setFieldValue('logo', '');
                              }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </div>
                      </div>
                      <Typography gutterBottom>
                        Excluded from website{' '}
                        <Checkbox
                          onChange={handleChange}
                          id="excludedFromWebsite"
                          value={values.excludedFromWebsite}
                          checked={values.excludedFromWebsite}
                        />
                      </Typography>
                      <div>
                        <InputField
                          error={Boolean(errors.type && touched.type && errors.type)}
                          disabled
                          fullWidth
                          id="type"
                          label="Type"
                          name="type"
                          helperText={errors.type}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.type}
                        />
                      </div>
                      <div>
                        <InputField
                          error={Boolean(errors.description && touched.description && errors.description)}
                          required={!values.excludedFromWebsite}
                          fullWidth
                          id="description"
                          label="Description"
                          name="description"
                          helperText={errors.description}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                        />
                      </div>
                      <div>
                        <InputField
                          error={Boolean(errors.storeLink && touched.storeLink && errors.storeLink)}
                          required={!values.excludedFromWebsite}
                          fullWidth
                          id="storeLink"
                          label="Store link"
                          name="storeLink"
                          helperText={errors.storeLink}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.storeLink}
                        />
                      </div>
                      <div>
                        <InputField
                          error={Boolean(errors.contactEmail && touched.contactEmail && errors.contactEmail)}
                          required={!values.excludedFromWebsite}
                          fullWidth
                          id="contactEmail"
                          label="Email"
                          name="contactEmail"
                          helperText={errors.contactEmail}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactEmail}
                        />
                      </div>
                      <div>
                        <InputField
                          error={Boolean(errors.contactPhone && touched.contactPhone && errors.contactPhone)}
                          required={!values.excludedFromWebsite}
                          fullWidth
                          id="contactPhone"
                          label="Phone"
                          name="contactPhone"
                          helperText={errors.contactPhone}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactPhone}
                        />
                      </div>
                      <div>
                        <InputField
                          error={Boolean(errors.contactWebsite && touched.contactWebsite && errors.contactWebsite)}
                          required={!values.excludedFromWebsite}
                          fullWidth
                          id="contactWebsite"
                          label="Website"
                          name="contactWebsite"
                          helperText={errors.contactWebsite}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contactWebsite}
                        />
                      </div>

                      <Typography gutterBottom>
                        Featured on website{' '}
                        <Checkbox
                          onChange={handleChange}
                          id="featuredOnWebsite"
                          value={values.featuredOnWebsite}
                          checked={values.featuredOnWebsite}
                        />
                      </Typography>
                      <div className={classes.uploadImage}>
                        <div>
                          <Typography variant="body1" gutterBottom>
                            Cover Image
                          </Typography>
                        </div>
                        <input
                          name="file"
                          accept="image/*"
                          multiple={false}
                          onChange={async (event) => {
                            const file = event.currentTarget.files[0];
                            const base64Image = await convertFileToBase64(file);
                            setFieldValue(`coverImage`, base64Image);

                            setFieldValue(`coverImagePreviewUrl`, URL.createObjectURL(file));
                          }}
                          className={classes.input}
                          id="coverImage"
                          type="file"
                        />
                        {!values.coverImagePreviewUrl && (
                          <div className={classes.image}>
                            <label htmlFor="coverImage">
                              <IconButton color="primary" aria-label="upload picture" component="span">
                                <PhotoCamera />
                              </IconButton>
                            </label>
                          </div>
                        )}
                        {values.coverImagePreviewUrl && (
                          <div>
                            <img alt="coverImage preview" className={classes.image} src={values.coverImagePreviewUrl} />
                          </div>
                        )}
                        {values.coverImagePreviewUrl && (
                          <IconButton
                            aria-label="remove picture"
                            component="span"
                            onClick={() => {
                              setFieldValue('coverImagePreviewUrl', '');
                              setFieldValue('coverImage', '');
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}></Grid>
                  </Grid>
                  <Divider style={{ margin: '20px 0' }} />

                  <Grid container spacing={5}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography variant="h6" gutterBottom>
                        Theme Settings
                      </Typography>
                      <div>
                        <InputField
                          error={Boolean(errors.font && touched.font && errors.font)}
                          fullWidth
                          id="theme.font"
                          label="Font"
                          name="theme.font"
                          helperText={errors.font}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.font}
                        />
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.primaryColor && touched.primaryColor && errors.primaryColor)}
                          id="theme.primaryColor"
                          label="primaryColor"
                          name="theme.primaryColor"
                          helperText={errors.primaryColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.primaryColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.primaryColor}`,
                            borderColor: `${values.theme?.primaryColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.primaryTextColor && touched.primaryTextColor && errors.primaryTextColor)}
                          id="theme.primaryTextColor"
                          label="primaryTextColor"
                          name="theme.primaryTextColor"
                          helperText={errors.primaryTextColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.primaryTextColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.primaryTextColor}`,
                            borderColor: `${values.theme?.primaryTextColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.secondaryColor && touched.secondaryColor && errors.secondaryColor)}
                          id="theme.secondaryColor"
                          label="secondaryColor"
                          name="theme.secondaryColor"
                          helperText={errors.secondaryColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.secondaryColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.secondaryColor}`,
                            borderColor: `${values.theme?.secondaryColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.secondaryTextColor && touched.secondaryTextColor && errors.secondaryTextColor)}
                          id="theme.secondaryTextColor"
                          label="secondaryTextColor"
                          name="theme.secondaryTextColor"
                          helperText={errors.secondaryTextColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.secondaryTextColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.secondaryTextColor}`,
                            borderColor: `${values.theme?.secondaryTextColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.errorColor && touched.errorColor && errors.errorColor)}
                          id="theme.errorColor"
                          label="errorColor"
                          name="theme.errorColor"
                          helperText={errors.errorColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.errorColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.errorColor}`,
                            borderColor: `${values.theme?.errorColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.hoverColor && touched.hoverColor && errors.hoverColor)}
                          id="theme.hoverColor"
                          label="hoverColor"
                          name="theme.hoverColor"
                          helperText={errors.hoverColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.hoverColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.hoverColor}`,
                            borderColor: `${values.theme?.hoverColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.selectedColor && touched.selectedColor && errors.selectedColor)}
                          id="theme.selectedColor"
                          label="selectedColor"
                          name="theme.selectedColor"
                          helperText={errors.selectedColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.selectedColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.selectedColor}`,
                            borderColor: `${values.theme?.selectedColor}`
                          }}
                        ></div>
                      </div>
                      <div className={classes.colorContainer}>
                        <InputField
                          error={Boolean(errors.successColor && touched.successColor && errors.successColor)}
                          id="theme.successColor"
                          label="successColor"
                          name="theme.successColor"
                          helperText={errors.successColor}
                          autoFocus
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.theme?.successColor}
                        />
                        <div
                          className={classes.colorBox}
                          style={{
                            backgroundColor: `${values.theme?.successColor}`,
                            borderColor: `${values.theme?.successColor}`
                          }}
                        ></div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      {values.theme && <ThemePreview theme={values.theme} />}
                    </Grid>
                  </Grid>
                  <CardActions className={classes.actions}>
                    <Button type="submit" variant="contained" color="primary" disabled={processing}>
                      Submit
                    </Button>
                    <Button color="secondary" onClick={handleReset}>
                      Clear
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>

        {processing ? <LoadingIndicator title="Processing entities data" /> : null}
      </div>
      {error && <ErrorAlert title={'Error retrieving entity data'} error={error} />}
    </DashboardHOC>
  );
};

EntitiesCreatePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EntitiesCreatePage);
