import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CardActions,
  MenuItem,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormControlLabel,
  Typography,
  Checkbox
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import DashboardHOC from '../../components/DashboardHOC';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import AlertDialog from '../../components/AlertDialog';
import LoadingIndicator from '../../components/LoadingIndicator';
import useSerialApi from '../../utils/serial-handler';

import { randomPassword } from '../../utils/cryptography';
import { createClientAction, resetCreatedClient, uploadClientAction } from './state/clients-actions';
import { getSecurityDonglesAction } from '../multos/state/security-dongle-actions';
import { getEntitiesAction } from '../entities/state/entities-actions';

import styles from './ClientsCreatePage.styles';
import { importFromJsonFile } from '../../utils/utils';
import usePrevious from '../../hooks/usePrevious';

const ClientsCreatePage = ({ classes, match }) => {
  const { updateClientCredentials, status, setupInProgress } = useSerialApi();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  let history = useHistory();
  const clientsState = useSelector((state) => state.clients);
  const securityDonglesState = useSelector((state) => state.securityDongles);

  const {
    createdClientData,
    processing,
    errorCreateClient,
    processingCreateClient,
    errorUploadClient,
    processingUploadClient
  } = clientsState;
  const { securityDonglesData, error } = securityDonglesState;

  const [uuid] = useState(uuidv4());
  const [securityModuleRequired, setRequiresSecurityModule] = useState(false);
  const [showAlertSecret, setShowAlertSecret] = useState(false);
  const [secretRevealed, setSecretRevealed] = useState(false);
  const [importedClient, setImportedClient] = useState(null);
  const [errorImportingClient, setErrorImportingClient] = useState('');
  const [clientUploaded, setClientUploaded] = useState(false);

  const type = [
    { value: 'IFRAME', label: 'iframe' },
    { value: 'MOBILE', label: 'Mobile' },
    { value: 'APPLIANCE', label: 'Appliance' },
    { value: 'THIRD_PARTY_INDEPENDENT', label: 'Third Party' }
  ];

  const entitiesState = useSelector((state) => state.entities);

  const prevProcessingUpload = usePrevious(processingUploadClient);

  const { entities, processing: processingGetEntities, error: errorGetEntities } = entitiesState;

  useEffect(() => {
    if (prevProcessingUpload && !errorUploadClient) {
      setClientUploaded(true);
    }
  }, [prevProcessingUpload, errorUploadClient]);

  useEffect(() => {
    if (!entities) {
      dispatch(getEntitiesAction());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSecurityDonglesAction('available'));
    return () => {
      stableDispatch(resetCreatedClient());
    };
  }, [stableDispatch]);

  useEffect(() => {
    if (createdClientData) {
      // TODO to redirect to the newly created entity - see create device
      history.replace(`/dashboard/clients/client/${uuid}`);
    }
  }, [createdClientData, history, match]);

  const handleImportJson = async (ev) => {
    setErrorImportingClient('');
    try {
      const json = await importFromJsonFile(ev);

      setImportedClient(json);
    } catch (err) {
      console.log({ err });
      setErrorImportingClient(err || 'Error importing client from json file');
    }
  };

  const handleUploadClient = () => {
    // eslint-disable-next-line no-unused-vars
    const { meta, ...clientData } = importedClient;

    dispatch(uploadClientAction(clientData));
  };

  if (importedClient) {
    return (
      <DashboardHOC>
        <Card>
          <CardContent>
            {clientUploaded ? <h1>Client successfully uploaded</h1> : <pre>{JSON.stringify(importedClient, null, 2)}</pre>}
            <CardActions className={classes.actions}>
              <Button variant="contained" color="primary" onClick={() => (clientUploaded ? history.goBack() : handleUploadClient())}>
                {clientUploaded ? 'Done' : 'Submit'}
              </Button>
              {!clientUploaded && (
                <Button
                  color="secondary"
                  onClick={() => {
                    setImportedClient(null);
                    setClientUploaded(false);
                  }}
                >
                  Clear
                </Button>
              )}
            </CardActions>
          </CardContent>
        </Card>
        {errorImportingClient && (
          <Alert className={classes.errorAlert} severity="error" variant="outlined">
            {errorImportingClient}
          </Alert>
        )}
        {errorUploadClient && (
          <Alert className={classes.errorAlert} severity="error" variant="outlined">
            {errorUploadClient}
          </Alert>
        )}
        {processingUploadClient && <LoadingIndicator />}
      </DashboardHOC>
    );
  }

  return (
    <DashboardHOC>
      <div>
        <Formik
          initialValues={{
            entityId: match.params.id,
            name: '',
            clientType: '',
            adminPin: '',
            operatorPin: '',
            clientSecret: randomPassword(),
            securityModuleRequired: false,
            securityModuleSerialNumber: ''
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const {
              entityId,
              clientType,
              name,
              adminPin,
              operatorPin,
              clientSecret,
              securityModuleSerialNumber,
              clientId,
              resourceIds
            } = values;

            const clientModel = {
              clientId: clientId || uuid,
              entityId,
              name,
              clientType,
              adminPin,
              operatorPin,
              clientSecret,
              securityModuleRequired,
              securityModuleSerialNumber,
              resourceIds: resourceIds || []
            };

            if (securityModuleRequired) {
              const updateClientCredentialsResult = await updateClientCredentials(securityModuleSerialNumber, uuid, clientSecret);
              if (updateClientCredentialsResult) {
                dispatch(createClientAction(clientModel));
              }
            } else {
              dispatch(createClientAction(clientModel));
            }
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent>
                  {setupInProgress ? (
                    <div className={classes.loadingStyle}>
                      <LoadingIndicator title={status} />
                    </div>
                  ) : (
                    <div>
                      <HeaderWithButton
                        headerText={`Add an Application`}
                        ExtraButton={() => (
                          <>
                            <input
                              type="file"
                              onChange={(ev) => {
                                handleImportJson(ev);
                              }}
                              accept=".json"
                              ref={fileInputRef}
                              style={{ display: 'none' }}
                            />
                            <Button variant="outlined" onClick={() => fileInputRef.current.click()}>
                              Import application
                            </Button>
                          </>
                        )}
                      />
                      <div>
                        <InputField
                          select
                          required
                          id="entityId"
                          label="EntityId"
                          value={values.entityId}
                          onChange={handleChange('entityId')}
                          helperText={touched.entityId ? errors.entityId : ''}
                          error={touched.entityId && Boolean(errors.type)}
                          margin="dense"
                          variant="outlined"
                        >
                          {entities?.map((entity) => (
                            <MenuItem key={entity.id} value={entity.id}>
                              {entity.name}
                            </MenuItem>
                          ))}
                        </InputField>

                        <InputField
                          select
                          required
                          id="clientType"
                          label="Application Type"
                          value={values.clientType}
                          onChange={handleChange('clientType')}
                          helperText={touched.clientType ? errors.clientType : ''}
                          error={touched.clientType && Boolean(errors.type)}
                          margin="dense"
                          variant="outlined"
                        >
                          {type.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>

                      <div>
                        <InputField
                          className={classes.smallInput}
                          error={Boolean(errors.name && touched.name && errors.name)}
                          required
                          id="name"
                          label="Name"
                          name="name"
                          helperText={errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          inputProps={{ maxLength: 32 }}
                          fullWidth
                        />
                      </div>
                      {values.clientType === 'APPLIANCE' && (
                        <div>
                          <div>
                            <InputField id="clientId" label="Client Id" name="clientId" disabled value={uuid} fullWidth />
                          </div>
                          <div>
                            <FormControl className={clsx(classes.textField)} variant="outlined">
                              <InputLabel htmlFor="clientSecret">Client Secret</InputLabel>
                              <OutlinedInput
                                className={classes.smallInput}
                                margin="dense"
                                id="clientSecret"
                                disabled
                                type="password"
                                autoComplete="off"
                                label="Client Secret"
                                name="clientSecret"
                                onClick={() => {
                                  if (!secretRevealed) {
                                    setShowAlertSecret(true);
                                    setSecretRevealed(true);
                                  }
                                }}
                                endAdornment={
                                  secretRevealed ? null : (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                          if (!secretRevealed) {
                                            setShowAlertSecret(true);
                                            setSecretRevealed(true);
                                          }
                                        }}
                                      >
                                        {showAlertSecret ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.clientSecret}
                              />
                              {!secretRevealed ? (
                                <Typography variant="subtitle2" gutterBottom>
                                  You can reveal the client secret only once. Please use it carefully.
                                </Typography>
                              ) : (
                                <>
                                  <Typography variant="subtitle2" gutterBottom>
                                    Client Secret already revealed. You can generate a new Secret Key using the button below
                                  </Typography>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      setFieldValue('clientSecret', randomPassword());
                                      setSecretRevealed(false);
                                    }}
                                  >
                                    Generate New Client Secret Key
                                  </Button>
                                </>
                              )}
                              <div>
                                <InputField
                                  className={classes.smallInput}
                                  error={Boolean(errors.adminPin && touched.adminPin && errors.adminPin)}
                                  required
                                  id="adminPin"
                                  label="Admin Pin (change environment)"
                                  name="adminPin"
                                  helperText={errors.adminPin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.adminPin}
                                  inputProps={{ maxLength: 6 }}
                                />
                              </div>
                              <div>
                                <InputField
                                  className={classes.smallInput}
                                  error={Boolean(errors.operatorPin && touched.operatorPin && errors.operatorPin)}
                                  required
                                  id="operatorPin"
                                  label="Operator Pin (unlock appliance)"
                                  name="operatorPin"
                                  helperText={errors.operatorPin}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.operatorPin}
                                  inputProps={{ maxLength: 6 }}
                                />
                              </div>
                            </FormControl>
                            <AlertDialog
                              showAlert={showAlertSecret}
                              handleClose={() => setShowAlertSecret(false)}
                              title="Warning!"
                              body1="This is the only time this secret access key can be viewed. You cannot recover it later. You can always generate a new key"
                              body2={`Your Secret: ${values.clientSecret}`}
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={securityModuleRequired}
                                  onChange={() => {
                                    setRequiresSecurityModule(!securityModuleRequired);
                                  }}
                                  name="securityModuleRequired"
                                  color="primary"
                                />
                              }
                              label="Requires Security Module"
                            />
                          </div>
                          <div>
                            <InputField
                              className={classes.smallInput}
                              select
                              required={securityModuleRequired ? true : false}
                              id="securityModuleSerialNumber"
                              label="Security Module"
                              value={values.securityModuleSerialNumber}
                              onChange={handleChange('securityModuleSerialNumber')}
                              helperText={touched.securityModuleSerialNumber ? errors.securityModuleSerialNumber : ''}
                              error={touched.securityModuleSerialNumber && Boolean(errors.type)}
                              margin="dense"
                              variant="outlined"
                            >
                              <MenuItem key={'none'} value={null}>
                                None
                              </MenuItem>
                              {securityDonglesData?.map((securityDongle) => (
                                <MenuItem key={securityDongle.serialNumber} value={securityDongle.serialNumber}>
                                  {securityDongle.serialNumber}
                                </MenuItem>
                              ))}
                            </InputField>
                          </div>
                        </div>
                      )}
                      <CardActions className={classes.actions}>
                        <Button type="submit" variant="contained" color="primary" disabled={processing || processingCreateClient}>
                          Submit
                        </Button>
                        <Button color="secondary" onClick={handleReset}>
                          Clear
                        </Button>
                      </CardActions>
                    </div>
                  )}
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
        {errorCreateClient && (
          <Alert className={classes.errorAlert} severity="error" variant="outlined">
            {errorCreateClient}
          </Alert>
        )}
        <br />
        {errorGetEntities && (
          <Alert className={classes.errorAlert} severity="error" variant="outlined">
            {errorGetEntities}
          </Alert>
        )}
        {(processingCreateClient || processingGetEntities) && <LoadingIndicator />}
      </div>
    </DashboardHOC>
  );
};

ClientsCreatePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ClientsCreatePage);
