import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, CardActions } from '@material-ui/core';
import DashboardHOC from '../../components/DashboardHOC';
import InputField from '../../components/InputField';
import HeaderWithButton from '../../components/HeaderWithButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import useSerialApi from '../../utils/serial-handler';
import styles from './MultosConfigure.style';

import { createSecurityDongleAction } from './state/security-dongle-actions';
import { Alert } from '@material-ui/lab';
import { importFromJsonFile } from '../../utils/utils';

const MultosSetupDevice = ({ classes }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const securityDonglesState = useSelector((state) => state.securityDongles);
  const { createdDongleData, error, processing } = securityDonglesState;
  const [importedMultos, setImportedMultos] = useState(null);
  const [errorImportingMultos, setErrorImportingMultos] = useState('');
  const [multosUploaded, setMultosUploaded] = useState(false);

  const { configureMultos, status, setupInProgress } = useSerialApi();

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (createdDongleData) {
      history.replace(`/dashboard/utils/multos`);
    }
  }, [createdDongleData, history]);

  const handleImportJson = async (ev) => {
    setErrorImportingMultos('');
    try {
      const json = await importFromJsonFile(ev);

      setImportedMultos(json);
    } catch (err) {
      console.log({ err });
      setErrorImportingMultos(err || 'Error importing multos device from json file');
    }
  };
  const handleUploadMultos = () => {
    // eslint-disable-next-line no-unused-vars
    const { meta, ...multosData } = importedMultos;

    dispatch(createSecurityDongleAction(multosData));
  };

  if (importedMultos) {
    return (
      <DashboardHOC>
        <h2>Multos device data to be uploaded, please check it carefully!</h2>
        <Card>
          <CardContent>
            {<pre>{JSON.stringify(importedMultos, null, 2)}</pre>}
            <CardActions className={classes.actions}>
              <Button variant="contained" color="primary" onClick={() => handleUploadMultos()}>
                {'Submit'}
              </Button>
              {!multosUploaded && (
                <Button
                  color="secondary"
                  onClick={() => {
                    setImportedMultos(null);
                    setMultosUploaded(false);
                  }}
                >
                  Clear
                </Button>
              )}
            </CardActions>
          </CardContent>
        </Card>
        {errorImportingMultos && (
          <Alert className={classes.errorAlert} severity="error" variant="outlined">
            {errorImportingMultos}
          </Alert>
        )}
        {error && (
          <Alert className={classes.errorAlert} severity="error" variant="outlined">
            {'Error creating a new security device'}
          </Alert>
        )}
        {processing && <LoadingIndicator />}
      </DashboardHOC>
    );
  }

  return (
    <DashboardHOC>
      <HeaderWithButton
        headerText={`Import or Configure Multos`}
        ExtraButton={() => (
          <>
            <input
              type="file"
              onChange={(ev) => {
                handleImportJson(ev);
              }}
              accept=".json"
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <Button variant="outlined" onClick={() => fileInputRef.current.click()}>
              Import multos device from file
            </Button>
          </>
        )}
      />
      <div>
        <Formik
          initialValues={{
            serialNumber: ''
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const { serialNumber } = values;

            const multosModel = {
              serialNumber,
              type: 'MULTOS_DONGLE',
              modulus: ''
            };
            setSubmitting(true);

            const publicKey = await configureMultos(multosModel);
            multosModel.modulus = publicKey;

            dispatch(createSecurityDongleAction(multosModel));

            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent>
                  {setupInProgress ? (
                    <div className={classes.loadingStyle}>
                      <LoadingIndicator title={status} />
                    </div>
                  ) : (
                    <div>
                      <HeaderWithButton headerText={`Configure a Multos Device`} />
                      <div>
                        <p>
                          Create a 3-byte Dongle serial number, suggest we just start at 000001 and increment from there.Generate three
                          Random 6-digit PIN codes for PIN-G, PIN-K and PIN-SO Now write the PIN codes to the application
                        </p>
                      </div>
                      <div>
                        <InputField
                          className={classes.smallInput}
                          error={Boolean(errors.serial && touched.serial && errors.serial)}
                          required
                          id="serialNumber"
                          label="Serial"
                          name="serialNumber"
                          helperText={errors.serial}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.serial}
                          inputProps={{ maxLength: 6 }}
                          fullWidth
                        />
                      </div>
                      <CardActions className={classes.actions}>
                        <Button type="submit" variant="contained" color="primary">
                          Submit
                        </Button>
                        <Button color="secondary" onClick={handleReset}>
                          Clear
                        </Button>
                      </CardActions>
                    </div>
                  )}
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </div>
    </DashboardHOC>
  );
};

MultosSetupDevice.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(MultosSetupDevice);
