import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import WatchIcon from '@material-ui/icons/Watch';
import UsbIcon from '@material-ui/icons/Usb';

import AdminContainer from './AdminContainerHOC';

export const MainSidebarMenu = () => {
  const history = useHistory();

  const EntityProfile = ({ entityid }) => {
    return (
      <ListItem
        button
        onClick={() => {
          history.push(`/dashboard/entities/${entityid}`);
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="My Profile" />
      </ListItem>
    );
  };

  EntityProfile.propTypes = { entityid: PropTypes.string };

  const SystemAndEntities = () => (
    <>
      <ListItem button onClick={() => history.push('/dashboard/system')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="System" />
      </ListItem>
      <ListItem button onClick={() => history.push('/dashboard/entities')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Entities" />
      </ListItem>
    </>
  );

  return (
    <div>
      <ListItem button onClick={() => history.push('/dashboard')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <AdminContainer type="internal">
        <SystemAndEntities />
      </AdminContainer>
      <AdminContainer type="internal" mode="readOnly">
        <SystemAndEntities />
      </AdminContainer>
      <AdminContainer type="external" mode="readOnly">
        <EntityProfile />
      </AdminContainer>
    </div>
  );
};

export const SecondarySidebarMenu = () => {
  const history = useHistory();
  return (
    <div>
      <ListSubheader inset>CUSTOMER SUPPORT</ListSubheader>
      <ListItem button onClick={() => history.push('/dashboard/support/wearables')}>
        <ListItemIcon>
          <WatchIcon />
        </ListItemIcon>
        <ListItemText primary="Wearables" />
      </ListItem>
      <ListItem button onClick={() => history.push('/dashboard/support/accounts')}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Accounts" />
      </ListItem>
    </div>
  );
};

export const UtilsSideMenu = () => {
  const history = useHistory();
  return (
    <div>
      <AdminContainer type="internal">
        <ListSubheader inset>UTILS</ListSubheader>
        <ListItem button onClick={() => history.push('/dashboard/utils/multos')}>
          <ListItemIcon>
            <UsbIcon />
          </ListItemIcon>
          <ListItemText primary="Multos" />
        </ListItem>
      </AdminContainer>
    </div>
  );
};
