import { useState } from 'react';

const useSerialApi = () => {
  const [publicKey, setPublicKey] = useState();
  const [serial, setSerial] = useState();
  const [clientId, setClientId] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [setupInProgress, setProcessing] = useState(false);
  const [showDebugLog, setShowDebugLog] = useState(true);

  const PIN_G_VALUE = '01';
  const PIN_K_VALUE = '02';
  const PIN_SO_VALUE = '03';

  const SERIAL_FILE_LOCATION = '0001';
  const SERIAL_FILE_SIZE = '0008';
  const CLIENT_ID_FILE_LOCATION = '0002';
  const CLIENT_ID_FILE_SIZE = '0036';
  const CLIENT_SECRET_FILE_LOCATION = '0003';
  const CLIENT_SECRET_FILE_SIZE = '0016';

  async function testMessages() {
    setProcessing(true);
    await sleep(1000);
    setMessage('TASK 1');
    await sleep(1000);
    setMessage('CREATING RSA PUBLIC KEY ... MAY TAKE A WHILE ');
    await sleep(3000);
    setMessage('TASK 3');
    await sleep(1000);
    setMessage('TASK 4');
    await sleep(1000);
    setProcessing(false);
  }

  async function configureMultos(configData) {
    const { serialNumber } = configData;

    setError(null);

    let port;
    let writer;
    let reader;
    if ('serial' in navigator) {
      try {
        const filter = { usbVendorId: 1240 };
        port = await navigator.serial.requestPort({ filters: [filter] });

        await navigator.serial.getPorts();

        setProcessing(true);

        setMessage('INITALISING MULTOS');

        await port.open({ baudRate: 9600, bufferSize: 10000 });
        writer = port.writable.getWriter();
        reader = port.readable.getReader();

        await initaliseMultos(writer, reader);
        await selectTrustCore(writer, reader);

        setMessage('SETTING UP PINS');
        await setUpPin(writer, reader, PIN_G_VALUE, '123456');
        await setUpPin(writer, reader, PIN_K_VALUE, '123456');
        await setUpPin(writer, reader, PIN_SO_VALUE, '123456');

        setMessage('CREATING FILES');
        await createFile(writer, reader, SERIAL_FILE_LOCATION, SERIAL_FILE_SIZE); // Create Serial File with size
        await createFile(writer, reader, CLIENT_ID_FILE_LOCATION, CLIENT_ID_FILE_SIZE); // Create Client Id File
        await createFile(writer, reader, CLIENT_SECRET_FILE_LOCATION, CLIENT_SECRET_FILE_SIZE); // Create Secret File

        setMessage('WRITING SERIAL NUMBER ' + serialNumber);

        await updateSerialNumber(writer, reader, serialNumber);

        setMessage('CREATING RSA PUBLIC KEY .. MAY TAKE A WHILE');
        await createRsaKey(writer, reader);

        setMessage('READING RSA PUBLIC KEY ');
        const rsaPublicKey = await getPublicKey(writer, reader);

        setProcessing(false);
        reader.releaseLock();
        writer.releaseLock();
        await port.close();
        console.log('closed port');

        return rsaPublicKey;
      } catch (err) {
        alert(err);
        console.log(err);
        setProcessing(false);
        reader.releaseLock();
        writer.releaseLock();

        await port.close();
        console.log('closed port');
        setProcessing(false);
        setError(err);
      }
    }
  }

  async function updateClientCredentials(serialNumber, clientId, secret) {
    setError(null);

    let port;
    let writer;
    let reader;
    if ('serial' in navigator) {
      try {
        const filter = { usbVendorId: 1240 };
        port = await navigator.serial.requestPort({ filters: [filter] });

        await navigator.serial.getPorts();

        setMessage('INITALISING MULTOS');
        setProcessing(true);

        await port.open({ baudRate: 9600, bufferSize: 10000 });
        writer = port.writable.getWriter();
        reader = port.readable.getReader();

        await initaliseMultos(writer, reader);
        await selectTrustCore(writer, reader);

        const multosSerial = await getSerial(writer, reader);
        setSerial(multosSerial);

        if (serialNumber !== multosSerial) {
          throw new Error('Serial number of multos does not match - ' + serialNumber + ' : ' + multosSerial);
        }

        setMessage('WRITING CREDENTIALS');

        await updateClientId(writer, reader, clientId);

        await updateClientSecret(writer, reader, secret);

        reader.releaseLock();
        writer.releaseLock();
        await port.close();

        console.log('closed port');
        return true;
      } catch (err) {
        alert(err);
        console.log(err);
        setProcessing(false);
        if (port) {
          reader.releaseLock();
          writer.releaseLock();
          await port.close();
        }

        console.log('closed port');
        setError(err);
        return false;
      }
    }
  }

  /* eslint-disable no-undef */
  async function sendCommand(writer, reader, command) {
    if (showDebugLog) console.log('command -> ' + command);
    await sleep(10);
    await writer.write(Uint8Array.from(Buffer.from(command, 'hex')));
    await sleep(10);
    const { value } = await reader.read();

    const response = Buffer.from(value).toString('hex');
    if (showDebugLog) console.log('response <- ' + response);

    return response;
  }

  // sleep time expects milliseconds
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  async function verifyPinK(writer, reader) {
    console.log('VERIFY PIN-K');
    await sendCommand(writer, reader, '55be0003800012');
    await sendCommand(writer, reader, '55be011280200002000c313233343536ffffffffffff');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      throw new Error('Failed to vetify pin K - ' + finalResponse);
    }
    console.log('PIN-K VERIFIED');
  }

  async function verifyPinG(writer, reader) {
    console.log('VERIFY PIN-G');
    await sendCommand(writer, reader, '55be0003800012');
    await sendCommand(writer, reader, '55be011280200001000c313233343536ffffffffffff');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      throw new Error('Failed to vetify pin G - ' + finalResponse);
    }
    console.log('PIN-G VERIFIED');
  }

  async function setCryptoTemplate(writer, reader) {
    console.log('SET CRYPTO TEMPLATE');
    await sendCommand(writer, reader, '55be000380000d');
    await sendCommand(writer, reader, '55be010d802241b8000780010181026100');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      throw new Error('Failed to set crypto template - ' + finalResponse);
    }
    console.log('CRYPTO TEMPLATE SET');
  }

  async function decrypt(writer, reader, challenge) {
    await selectTrustCore(writer, reader);
    await setCryptoTemplate(writer, reader);
    await verifyPinG(writer, reader);

    console.log('DECRYPT CHALLENGE');

    if (challenge.length !== 512) {
        throw new Error('Challenge has wrong size');
    }

    await sendCommand(writer, reader, '55be0003800108');
    await sendCommand(writer, reader, '55be013a802a80860100' + challenge.substring(0,104));
    await sendCommand(writer, reader, '55be013a' + challenge.substring(104, 220));
    await sendCommand(writer, reader, '55be013a' + challenge.substring(220, 336));
    await sendCommand(writer, reader, '55be013a' + challenge.substring(336, 452));
    await sendCommand(writer, reader, '55be0120' + challenge.substring(452, 512) + '0000');

    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const res1 = await sendCommand(writer, reader, '55bf823c');
    const res2 = await sendCommand(writer, reader, '55bf823c');

    const decryptedChallenge = res1.concat(res2);
    const finalResponse = await sendCommand(writer, reader, '55bf8202');

    if (finalResponse !== '9000') {
        throw new Error('Failed to decrypt challenge - ' + finalResponse);
    }

    console.log('CHALLENGE DECRYPTED');
    return decryptedChallenge;
  }

  async function createRsaKey(writer, reader) {
    console.log('CREATE RSA KEY');
    await verifyPinK(writer, reader);
    await sendCommand(writer, reader, '55be000380000b');
    await sendCommand(writer, reader, '55be010b8046000000036100000028');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      throw new Error('Failed to create RSA Key - ' + finalResponse);
    }
    console.log('CREATED RSA KEY');
  }

  async function initaliseMultos(writer, reader) {
    console.log('SET CLOCK SPEED');
    await sendCommand(writer, reader, '5a027001');

    console.log('SET MAX BUFFER SIZE');
    await sendCommand(writer, reader, '55be8302003c');

    console.log('SOFT RESET');
    await sendCommand(writer, reader, '55be000106');
    await sendCommand(writer, reader, '55be8302003c');
  }

  async function selectTrustCore(writer, reader) {
    console.log('SELECT TRUST CORE');
    await sendCommand(writer, reader, '55be0003800010');
    await sendCommand(writer, reader, '55be0110' + '00a4040c000aa00000014454434f5245');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      throw new Error('Failed to select trustcore - ' + finalResponse);
    }
    console.log('Selected TrustCore successfully');
  }

  async function getPublicKey(writer, reader) {
    console.log('GET PUBLIC KEY');
    await sendCommand(writer, reader, '55be0003800006');
    await sendCommand(writer, reader, '55be0106' + '8040610000' + '00');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const pk1 = await sendCommand(writer, reader, '55bf823c');
    const pk2 = await sendCommand(writer, reader, '55bf823c');
    const pk3 = await sendCommand(writer, reader, '55bf823c');
    const pk4 = await sendCommand(writer, reader, '55bf823c');
    const pk5 = await sendCommand(writer, reader, '55bf8212');

    const publicKeyResp = pk1.concat(pk2, pk3, pk4, pk5);

    if (publicKeyResp.slice(publicKeyResp.length - 4) !== '9000') {
      throw new Error('Failed to select fetch publick key - ' + publicKeyResp);
    }

    return publicKeyResp.substring(0, publicKeyResp.length - 4);
  }

  async function readMultosDevice() {
    setError(null);

    if ('serial' in navigator) {
      try {
        setProcessing(true);

        const filter = { usbVendorId: 1240 };
        const port = await navigator.serial.requestPort({ filters: [filter] });
        await navigator.serial.getPorts();
        await port.open({ baudRate: 9600, bufferSize: 10000 });
        let writer = port.writable.getWriter();
        let reader = port.readable.getReader();

        await initaliseMultos(writer, reader);
        await selectTrustCore(writer, reader);

        const multosClientId = await getClientId(writer, reader);
        setClientId(multosClientId);

        const multosSerial = await getSerial(writer, reader);
        setSerial(multosSerial);

        const secret = await getSecret(writer, reader);
        setClientSecret(secret);

        const publickKey = await getPublicKey(writer, reader);
        setPublicKey(publickKey);

        setProcessing(false);

        reader.releaseLock();
        writer.releaseLock();
        await port.close();
        console.log('CLOSED');
      } catch (err) {
        alert(err);
        console.log(err);
        setProcessing(false);
        setError(err);
      }
    }
  }

  function hex2string(hex) {
    var str = '';
    for (var i = 0; i < hex.length; i += 2) {
      var v = parseInt(hex.substr(i, 2), 16);
      if (v) str += String.fromCharCode(v);
    }
    return str;
  }

  function stringToHex(str, hex) {
    try {
      hex = unescape(encodeURIComponent(str))
        .split('')
        .map(function(v) {
          return v.charCodeAt(0).toString(16);
        })
        .join('');
    } catch (e) {
      hex = str;
      console.log('invalid text input: ' + str);
    }
    return hex;
  }

  async function setUpPin(writer, reader, pinType, pin) {
    if (pinType === null || pinType === undefined || pin === null || pin === undefined) {
      throw new Error('No pin type or pin specified');
    }
    if (pin.length !== 6) {
      throw new Error('Invalid pin length, must be 6!');
    }

    console.log('SETUP PIN ' + pinType + ' : ' + pin);
    console.log('55be011e802400' + pinType + '001831323334ffffffffffffffff313233343536ffffffffffff');
    await sendCommand(writer, reader, '55be000380001e');
    await sendCommand(writer, reader, '55be011e802400' + pinType + '001831323334ffffffffffffffff313233343536ffffffffffff');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      console.log('FAILED TO SETUP PIN WITH RESPONSE ' + finalResponse);
      throw new Error('FAILED TO SETUP PIN WITH RESPONSE ' + finalResponse);
    }
  }

  async function readClientId(writer, reader) {
    await sendCommand(writer, reader, '55be0003800006');
    await sendCommand(writer, reader, '55be010680b000000024');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const clientId = await sendCommand(writer, reader, '55bf8226');
    console.log('Client Id', clientId);

    return clientId.substring(0, clientId.length - 4);
  }

  async function readSecret(writer, reader) {
    await sendCommand(writer, reader, '55be0003800006');
    await sendCommand(writer, reader, '55be010680b000000010');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const secret = await sendCommand(writer, reader, '55bf8212');
    console.log('Secret ', secret);

    return secret.substring(0, secret.length - 4);
  }

  async function getMultosPublicKey() {
    setError(null);

    if ('serial' in navigator) {
      try {
        // The Web Serial API is supported.
        const filter = { usbVendorId: 1240 };
        const port = await navigator.serial.requestPort({ filters: [filter] });

        await navigator.serial.getPorts();

        setProcessing(true);

        await port.open({ baudRate: 9600 });
        const writer = port.writable.getWriter();

        const reader = port.readable.getReader();

        await initaliseMultos(writer, reader);
        await selectTrustCore(writer, reader);
        await verifyPinK(writer, reader);
        await createRsaKey(writer, reader);
        await getPublicKey(writer, reader);

        setProcessing(false);
        reader.releaseLock();
        writer.releaseLock();

        await port.close();
      } catch (err) {
        setProcessing(false);
        setError(err);
      }
    }
  }

  async function writeSerialNumber(writer, reader, serialNumber) {
    console.log('WRITING SERIAL ' + serialNumber);
    await sendCommand(writer, reader, '55be0003800009');
    await sendCommand(writer, reader, '55be010980d600000003' + serialNumber);
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      console.log('Failed to set serial number ' + finalResponse);
      throw new Error('Failed to set serial number');
    }
    console.log('Succesfully written serial number ' + serialNumber);
  }

  async function writeClientID(writer, reader, clientID) {
    console.log('WRITING CLIENT ID ' + clientID);
    const encodedClientID = stringToHex(clientID);
    console.log('55be012a80d600000024' + encodedClientID);
    await sendCommand(writer, reader, '55be000380002a');
    await sendCommand(writer, reader, '55be012a80d600000024' + encodedClientID);
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      console.log('Failed to set client id ' + finalResponse);
      throw new Error('Failed to set client id ');
    }
    console.log('Succesfully written client id ' + clientID);
  }

  async function writeClientSecret(writer, reader, clientSecret) {
    console.log('WRITING CLIENT SECRET ' + clientSecret);
    const ecodedClientSecret = stringToHex(clientSecret);

    await sendCommand(writer, reader, '55be0003800016');
    await sendCommand(writer, reader, '55be011680d600000010' + ecodedClientSecret);
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');
    if (finalResponse !== '9000') {
      console.log('Failed to set secret id ' + finalResponse);
      throw new Error('Failed to set secret id ');
    }
    console.log('Succesfully written secret id ' + clientSecret);
  }

  /**
   * SERIAL NUMBER COMMANDS
   */
  async function updateSerialNumber(writer, reader, serialNumber) {
    console.log('UPDATING SERIAL NUMBER');
    await verifyPinK(writer, reader);
    await selectFile(writer, reader, '0001');
    await writeSerialNumber(writer, reader, serialNumber);
  }

  async function updateClientId(writer, reader, clientID) {
    console.log('UPDATING CLIENT ID');
    await verifyPinK(writer, reader);
    await selectFile(writer, reader, '0002');
    await writeClientID(writer, reader, clientID);
  }

  async function updateClientSecret(writer, reader, clientSecret) {
    console.log('UPDATING CLIENT SECRET');
    await verifyPinK(writer, reader);
    await selectFile(writer, reader, '0003');
    await writeClientSecret(writer, reader, clientSecret);
  }

  async function readSerialNumber(writer, reader) {
    console.log('READING SERIAL NUMBER');
    await sendCommand(writer, reader, '55be0003800006');
    await sendCommand(writer, reader, '55be010680b000000003');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const serialNumber = await sendCommand(writer, reader, '55bf8205');
    if (serialNumber.slice(serialNumber.length - 4) !== '9000') {
      console.log('could not retrieve serial number');
      return;
    }

    console.log('SUCCESSULLY READ SERIAL NUMBER ' + serialNumber.length - 4);

    return serialNumber.substring(0, serialNumber.length - 4);
  }

  async function readFileData(writer, reader, noOfBytesToRead) {
    console.log('READING SERIAL NUMBER');
    await sendCommand(writer, reader, '55be0003800006');
    await sendCommand(writer, reader, '55be010680b000000003');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');

    const serialNumber = await sendCommand(writer, reader, '55bf8205');
    if (serialNumber.slice(serialNumber.length - 4) !== '9000') {
      console.log('could not retrieve serial number');
      return;
    }

    console.log('SUCCESSULLY READ SERIAL NUMBER ' + serialNumber.length - 4);

    return serialNumber.substring(0, serialNumber.length - 4);
  }

  async function getSerial(writer, reader) {
    await selectFile(writer, reader, '0001');
    return await readSerialNumber(writer, reader);
  }

  async function getClientId(writer, reader) {
    await selectFile(writer, reader, '0002');
    const clientID = hex2string(await readClientId(writer, reader));
    if (clientID.length === 0) {
      return 'NONE';
    } else {
      return clientID;
    }
  }

  async function getSecret(writer, reader) {
    await selectFile(writer, reader, '0003');
    const secret = hex2string(await readSecret(writer, reader));
    if (secret.length === 0) {
      return 'NONE';
    } else {
      return secret;
    }
  }

  async function createFile(writer, reader, fileLocation, size) {
    console.log('CREATING FILE ' + fileLocation + '-' + size);

    await verifyPinK(writer, reader);
    await sendCommand(writer, reader, '55be0003800014');
    await sendCommand(writer, reader, '55be011480e00000000e00' + fileLocation + size + '000000010000000400');
    await sendCommand(writer, reader, '55bf8001');
    await sendCommand(writer, reader, '55bf8102');
    const finalResponse = await sendCommand(writer, reader, '55bf8202');

    if (finalResponse !== '9000') {
      console.log('Failed to create file ' + finalResponse);
      throw new Error('Failed to create file ' + finalResponse);
    }
    console.log('Succesfully created file ' + fileLocation);
  }

  async function selectFile(writer, reader, fileLocation) {
    console.log('SELECTING FILE ' + fileLocation);
    await sendCommand(writer, reader, '55be000380000a');
    await sendCommand(writer, reader, '55be010a80a400000002' + fileLocation + '0000');
    await sendCommand(writer, reader, '55bf8001');

    const status = await sendCommand(writer, reader, '55bf8102'); // 0006 expected // 0002 when no file?
    if (status !== '0006') {
      console.log('Failed to select ' + fileLocation);
      throw new Error('Failed to select ' + fileLocation);
    }
    await sendCommand(writer, reader, '55bf8206');
  }

  return {
    multosSerial: serial,
    multosClientId: clientId,
    clientSecret,
    status: message,
    error,
    setupInProgress,
    publicKey,
    getMultosPublicKey,
    readMultosDevice,
    configureMultos,
    updateClientCredentials,
    testMessages
  };
};

export default useSerialApi;
