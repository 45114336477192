import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './app-theme';
import AppRouter from './app-router';
import history from './history';
import store from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <AppRouter history={history} />
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
