import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import styles from './SearchWearablesForm.styles';

const validationSchema = yup.object().shape({
  searchValue: yup
    .string()
    .when('searchType', {
      is: (searchType) => searchType === 'asn',
      then: yup
        .string()
        .matches(/^[A-z0-9]{20}$/, 'Invalid ASN')
        .required('ASN is required!')
    })
    .when('searchType', {
      is: (searchType) => searchType === 'email',
      then: yup
        .string()
        .email('Invalid email')
        .required('Email is required!')
    })
    .when('searchType', {
      is: (searchType) => searchType === 'serialNumber',
      then: yup
        .string()
        .matches(/^[A-z0-9]{20}$/, 'Invalid serial number')
        .required('Serial number is required!')
    })
    .when('searchType', {
      is: (searchType) => searchType === 'visualId',
      then: yup
        .string()
        .required('Visual Id is required!')
        .test(
          'len',
          'Visual Id length must be between 10 and 14 characters',
          (val) => val?.length > 9 && val?.length < 15
        )
        .matches(/^[A-z0-9]*$/, 'Invalid Visual Id, please check')
    })
    .when('searchType', {
      is: (searchType) => searchType === 'token',
      then: yup
        .string()
        .required('Token is required!')
        .test('len', 'Token length must be 48', (val) => val?.length === 48)
        .matches(/^[A-z0-9]*$/, 'Invalid Token, please check')
    })
});

const SearchWearablesForm = ({ classes, handleSubmit, error }) => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Search Wearables
            </Typography>
            <Typography variant="body2" gutterBottom>
              Select search criteria: ASN, Email or Serial number
            </Typography>
            <br></br>
            <Formik
              initialValues={{ searchType: 'asn', searchValue: '' }}
              onSubmit={(values) => {
                const { searchType, searchValue } = values;

                handleSubmit(searchType, searchValue.replace(/\s+/g, ''));
              }}
              validationSchema={validationSchema}
            >
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Grid container direction="row">
                    <Field
                      as={TextField}
                      select
                      name="searchType"
                      className={classes.textField}
                    >
                      <MenuItem value="asn">ASN</MenuItem>
                      <MenuItem value="visualId">Visual Id</MenuItem>
                      {/*<MenuItem value="token">Token</MenuItem>*/}
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="serialNumber">Serial Number</MenuItem>
                    </Field>

                    <Divider className={classes.divider} />

                    <Field
                      error={!!errors.searchValue}
                      type="input"
                      name="searchValue"
                      as={TextField}
                      style={{
                        minWidth: values?.searchType === 'token' ? 450 : 240
                      }}
                      onChange={(e) => {
                        setFieldValue(
                          'searchValue',
                          e.target.value.replace(/\s+/g, '')
                        );
                      }}
                    />

                    <IconButton type="submit" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Grid>
                  <ErrorMessage name="searchValue">
                    {(msg) => (
                      <div style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" color="error">
                          {msg}
                        </Typography>
                      </div>
                    )}
                  </ErrorMessage>
                </Form>
              )}
            </Formik>
            {error && (
              <div>
                <Alert variant="outlined" severity="error">
                  {error}
                </Alert>
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

SearchWearablesForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

export default withStyles(styles)(SearchWearablesForm);
