import history from '../history';
import { signOutAction } from '../containers/login/state/sign-in-actions';

export default ({ dispatch }) => (next) => (action) => {
  const { types, callAPI = {}, payload = {} } = action;

  if (!types) {
    // Normal action: pass it on
    return next(action);
  }

  if (!Array.isArray(types) || types.length !== 3 || !types.every((type) => typeof type === 'string')) {
    throw new Error('Expected an array of three string types.');
  }

  if (typeof callAPI !== 'function') {
    throw new Error('Expected callAPI to be a function.');
  }

  const [request, success, failure] = types;

  dispatch({
    type: request
  });

  return callAPI().then(
    (response) => {
      console.log({ response });
      dispatch({
        ...payload,
        type: success,
        response
      });
    },
    (error) => {
      console.log({ error });
      if (error.response?.status === 401 || error.response?.status === 403) {
        dispatch(signOutAction());
        history.push('/', { from: { pathname: history.location?.pathname } });
      }

      dispatch({
        ...payload,
        type: failure,
        error:
          error.response && error.response.data
            ? error.response.data.message
            : (error.response instanceof String && error.response) || error.message
      });
    }
  );
};
