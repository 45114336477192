import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_SECURITY_DONGLES_REQUEST,
  GET_SECURITY_DONGLES_SUCCESS,
  GET_SECURITY_DONGLES_FAILURE,
  CREATE_SECURITY_DONGLES_REQUEST,
  CREATE_SECURITY_DONGLES_SUCCESS,
  CREATE_SECURITY_DONGLES_FAILURE
} from './constants';

const url = getApiUrl();

export function getSecurityDonglesAction(status) {
  return {
    types: [GET_SECURITY_DONGLES_REQUEST, GET_SECURITY_DONGLES_SUCCESS, GET_SECURITY_DONGLES_FAILURE],
    callAPI: () => getSecurityDongles(status)
  };
}

export function createSecurityDongleAction(clientModel) {
  return {
    types: [CREATE_SECURITY_DONGLES_REQUEST, CREATE_SECURITY_DONGLES_SUCCESS, CREATE_SECURITY_DONGLES_FAILURE],
    callAPI: () => createSecurityDongle(clientModel)
  };
}

const getSecurityDongles = (status) => {
  const stateFilter = status !== undefined ? `?status=${status}` : '';

  return httpClient(`${url}/admin/security-modules${stateFilter}`, {
    method: 'get'
  });
};

const createSecurityDongle = (securityDongleModel) => {
  return httpClient(`${url}/admin/security-modules`, {
    method: 'post',
    data: securityDongleModel
  });
};
