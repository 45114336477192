const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  divider: { margin: '10px 0' },
  imageContainer: { height: 80, width: 'auto' },
  image: { maxWidth: '100%', maxHeight: '100%' },
  variantImageContainer: { height: 60, width: 'auto' }
});

export default styles;
