import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
  GET_DEVICE_REQUEST,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  CREATE_DEVICE_REQUEST,
  CREATE_DEVICE_SUCCESS,
  CREATE_DEVICE_FAILURE,
  UPDATE_DEVICE_REQUEST,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
  CREATE_DEVICE_RESET
} from './constants';

const url = getApiUrl();

export function getDevicesAction(oem) {
  return {
    types: [GET_DEVICES_REQUEST, GET_DEVICES_SUCCESS, GET_DEVICES_FAILURE],
    callAPI: () => getDevices(oem)
  };
}

export function getDeviceAction(brand, model, version) {
  return {
    types: [GET_DEVICE_REQUEST, GET_DEVICE_SUCCESS, GET_DEVICE_FAILURE],
    callAPI: () => getDevice(brand, model, version)
  };
}

export function createDeviceAction(deviceModel) {
  return {
    types: [CREATE_DEVICE_REQUEST, CREATE_DEVICE_SUCCESS, CREATE_DEVICE_FAILURE],
    callAPI: () => createDevice(deviceModel)
  };
}

export function updateDeviceAction(brand, model, version, deviceModel) {
  return {
    types: [UPDATE_DEVICE_REQUEST, UPDATE_DEVICE_SUCCESS, UPDATE_DEVICE_FAILURE],
    callAPI: () => updateDevice(brand, model, version, deviceModel)
  };
}

export function deleteDeviceAction(brand, model, version) {
  return {
    types: [DELETE_DEVICE_REQUEST, DELETE_DEVICE_SUCCESS, DELETE_DEVICE_FAILURE],
    callAPI: () => deleteDevice(brand, model, version)
  };
}

export function resetCreatedDevice() {
  return {
    type: CREATE_DEVICE_RESET
  };
}

const getDevices = (entity) => {
  return httpClient(`${url}/admin/devices/?entity=${entity}`, {
    method: 'get'
  });
};

const getDevice = (brand, model, version) => {
  return httpClient(`${url}/admin/devices/brand/${brand}/model/${model}/version/${version}`, {
    method: 'get'
  });
};

const deleteDevice = (brand, model, version) => {
  return httpClient(`${url}/admin/devices/brand/${brand}/model/${model}/version/${version}`, {
    method: 'delete'
  });
};

const createDevice = (deviceModel) => {
  return httpClient(`${url}/admin/devices`, {
    method: 'post',
    data: deviceModel
  });
};

const updateDevice = (brand, model, version, deviceModel) => {
  return httpClient(`${url}/admin/devices/brand/${brand}/model/${model}/version/${version}`, {
    method: 'put',
    data: deviceModel
  });
};
