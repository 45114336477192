import JSZip from 'jszip';
import { getBase64ImageFromUrl } from './utils';

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function readZipFile(event, config, callbacks) {
  const { oem, deviceModel, deviceVersion } = config;
  const { setErrorImportDevice, setFieldValue, setReadOnly } = callbacks;

  function handleFile(f) {
    const zipOrigin = f?.name.includes('__admin') ? 'admin' : f.name.includes('dg') ? 'dg' : null;

    if (!zipOrigin) {
      setErrorImportDevice('Unknown zip file, please import a valid zip');
      return;
    }

    if (f.type !== 'application/zip') {
      setErrorImportDevice('Not a zip file');
      return;
    }

    if (f.size > 3000000) {
      setErrorImportDevice('File too big, please check it and resize/optimize images');
      return;
    }

    if (!f.name.includes(oem)) {
      setErrorImportDevice('Wrong oem, device or unsupported zip. Please check the zip file.');
      return;
    }

    let hasJsonData = false;

    JSZip.loadAsync(f)
      .then(
        function(zip) {
          zip.forEach(async function(relativePath, zipEntry) {
            if (zipEntry.name.includes('json')) {
              hasJsonData = true;
              setReadOnly(false);

              const data = await zipEntry.async('text');
              const {
                model,
                brand,
                tdpId,
                type,
                variants,
                defaultImage,
                modelDisplayName,
                description,
                storeLink,
                brandMobileVideoTrailer,
                excludedFromWebsite,
                featuredOnWebsite
              } = JSON.parse(data);

              if (deviceModel) {
                if (deviceModel !== model.name) {
                  setErrorImportDevice(`Wrong device model (${model.name}), data cannot be imported!`);
                  return;
                }
              }

              if (deviceVersion) {
                if (deviceVersion !== model.version) {
                  setErrorImportDevice(`Wrong device version (${model.version}), data cannot be imported!`);
                  return;
                }
              }

              if (model && type) {
                const defaultImageBase64 = zipOrigin === 'dg' ? defaultImage : await getBase64ImageFromUrl(defaultImage);
                setFieldValue('brand', brand || '');
                setFieldValue('model', model.name || '');
                setFieldValue('version', model.version || '');
                setFieldValue('tdp', tdpId || 0);
                setFieldValue('type', type);
                setFieldValue('modelDisplayName', modelDisplayName || model.name);
                setFieldValue('defaultImagePreviewUrl', zipOrigin === 'dg' ? URL.createObjectURL(b64toBlob(defaultImage)) : defaultImage);
                setFieldValue(`defaultImage`, defaultImageBase64);

                if (variants?.length > 0) {
                  const variantsWithBase64Imgs = await Promise.all(
                    variants.map(async (variant) => {
                      const base64VariantImg = zipOrigin === 'dg' ? variant.image : await getBase64ImageFromUrl(variant.image);
                      return {
                        color: variant.color,
                        imagePreview: zipOrigin === 'dg' ? URL.createObjectURL(b64toBlob(variant.image)) : variant.image,
                        image: base64VariantImg
                      };
                    })
                  );

                  setFieldValue('variants', variantsWithBase64Imgs);
                }
              }

              setFieldValue('description', description || '');
              setFieldValue('storeLink', storeLink || '');
              setFieldValue('brandMobileVideoTrailer', brandMobileVideoTrailer || '');
              setFieldValue('excludedFromWebsite', excludedFromWebsite || false);
              setFieldValue('featuredOnWebsite', featuredOnWebsite || false);
            }
          });

          if (!hasJsonData) {
            setErrorImportDevice("NO JSON DATA available, can't use this file!");
          }
        },
        function() {
          setErrorImportDevice('error reading zip file');
        }
      )
      .catch(() => {
        setErrorImportDevice('error reading zip file');
      });
  }

  handleFile(event.target.files[0]);
}
