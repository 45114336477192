const styles = () => ({
  accountActivityContainer: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

export default styles;
