const styles = () => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: '10px 0'
  },
  button: { margin: '0 5px' },
  buttonWarning: { color: 'red' }
});

export default styles;
