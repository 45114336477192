import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Button } from '@material-ui/core';
import ServiceHistory from './ServiceHistory';
import ServiceConfig from './ServiceConfig';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorAlert from '../../components/ErrorAlert';
import ServicePermissions from './ServicePermissions';

import { getServiceHistoryAction, getServiceConfigAction } from './state/support-wearables-actions';

import imageNotFound from '../../images/imageNotFound.png';

import styles from './ServiceDetails.styles';

const serviceStateTooltips = {
  INACTIVE: 'INACTIVE - The service was not yet activated, it must be activated within 30 days from registration',
  ACTIVE: 'ACTIVE - The service is active and can be used without issues',
  SUSPENDED: 'SUSPENDED - The service was suspended either by user or by the issuer. To resume usage, it must be activated',
  PROCESSING: 'PROCESSING - The service is not ready yet. The wearable is yet to be personalised'
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledRow = withStyles(styles)(function Row({ classes, row }) {
  const [open, setOpen] = React.useState(false);

  const counter = useRef(10);
  const interval = useRef(10);

  const range = useRef({});

  const [serviceHistoryData, setServiceHistoryData] = useState([]);

  const supportWearablesState = useSelector((state) => state.supportWearables);

  const {
    wearableData,
    serviceHistory,
    processingServiceHistory,
    errorServiceHistory,
    errorServicePerms,
    processingServicePerms
  } = supportWearablesState;

  const dispatch = useDispatch();

  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }

  function getHistory(token) {
    counter.current = counter.current + interval.current;

    if (counter.current <= 180) {
      dispatch(getServiceHistoryAction(token, range.current.fromDate, range.current.toDate));
    }
  }

  useEffect(() => {
    range.current.fromDate = moment()
      .subtract(counter.current, 'days')
      .valueOf();

    range.current.toDate = moment()
      .subtract(counter.current - (counter.current === 30 ? 10 : interval.current), 'days')
      .valueOf();
  });

  useEffect(() => {
    if (counter.current === 30) {
      interval.current = 30;
    }
  });

  useEffect(() => {
    if (serviceHistory?.length > 0) {
      setServiceHistoryData((prev) => [...prev, ...serviceHistory]);
    }

    if (supportWearablesState.wearableData?.asn && serviceHistory && serviceHistory?.length === 0 && counter.current <= 180) {
      getHistory();
    }
  }, [serviceHistory]);

  function getConfigId(id) {
    dispatch(getServiceConfigAction(id));
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!supportWearablesState.serviceConfig) {
                getConfigId(row.configId);
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.token}
        </TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">
          <div data-tip={serviceStateTooltips[row.state]} className={classes.serviceStateContainer}>
            {row.state} <InfoOutlined color="secondary" className={classes.infoIcon} />
          </div>
          <ReactTooltip className={classes.tooltip} place="bottom" type="info" effect="float" />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.pv0} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Provider</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Registration Method</strong>
                    </TableCell>
                    {row.productInfo?.fallbackImage?.issuerName && (
                      <TableCell>
                        <strong>Issuer</strong>
                      </TableCell>
                    )}
                    <TableCell>
                      <strong>PAN</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.provider}
                    </TableCell>
                    <TableCell>{row.registrationMethod === 'ANONYMOUS' ? 'IFRAME' : row.registrationMethod}</TableCell>
                    {row.productInfo?.fallbackImage?.issuerName && (
                      <TableCell>
                        <div className={classes.issuerNameImageContainer}>
                          {row.productInfo?.fallbackImage?.issuerName}{' '}
                          {row.productInfo?.fallbackImage?.issuerLogo && (
                            <img
                              alt="issuer logo"
                              onError={handleImageLoadError}
                              className={classes.image}
                              src={row.productInfo?.fallbackImage?.issuerLogo}
                            />
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>{`****${row.productInfo?.imageText || '***'}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Activation Method</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Activation Details</strong>
                    </TableCell>

                    <TableCell>
                      <strong>Selected</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.activationMethods.map((e, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {e.method}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {e.details}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {e.selected ? 'YES' : 'NO'}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {wearableData.asn && row.token && (
                <ServicePermissions
                  asn={wearableData.asn}
                  token={row.token}
                  permissions={row.servicePermissions}
                  error={errorServicePerms}
                  processing={processingServicePerms}
                />
              )}
              {supportWearablesState.processingServiceConfig && <LoadingIndicator title="Getting service config" />}
              {supportWearablesState.serviceConfig && <ServiceConfig serviceConfig={supportWearablesState.serviceConfig} psd2={row.psd2} />}
              {supportWearablesState.errorServiceConfig && <ErrorAlert error="Error getting service config" />}
              <div className={classes.serviceHistoryContainer}>
                {serviceHistoryData.length === 0 && (
                  <Button
                    disabled={processingServiceHistory}
                    className={classes.mv20}
                    onClick={() => getHistory(row.token)}
                    variant="contained"
                    color="primary"
                  >
                    Fetch Service History
                  </Button>
                )}
                {serviceHistoryData.length > 0 && counter.current < 180 && (
                  <Button
                    disabled={processingServiceHistory}
                    className={classes.mv20}
                    onClick={() => getHistory(row.token)}
                    variant="contained"
                    color="primary"
                  >
                    Fetch More Service History
                  </Button>
                )}
                {counter.current >= 180 && <Typography>No more history available</Typography>}
                <>
                  {errorServiceHistory && <Typography gutterBottom>{errorServiceHistory}</Typography>}

                  {processingServiceHistory && (
                    <LoadingIndicator
                      title={`Getting service history from ${moment(range.current.fromDate).format('DD MMM YYYY')} to
                    ${moment(range.current.toDate).format('DD MMM YYYY')}`}
                    />
                  )}
                  {serviceHistoryData && <ServiceHistory serviceHistory={serviceHistoryData} errorServiceHistory={errorServiceHistory} />}
                </>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

StyledRow.propTypes = {
  row: PropTypes.shape({
    token: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    registrationMethod: PropTypes.string,
    productInfo: PropTypes.shape({
      imageText: PropTypes.string
    })
  }).isRequired
};

function ServiceDetails({ classes, rows }) {
  if (rows?.length === 0 || !rows) {
    return (
      <div className={classes.mv20}>
        <Typography variant="body1" align="center" gutterBottom>
          <strong>No Services Currently Registered</strong>
        </Typography>
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>Token ID</StyledTableCell>
            <StyledTableCell align="right">Type</StyledTableCell>
            <StyledTableCell align="right">State</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <StyledRow key={row.token} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ServiceDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(styles)(ServiceDetails);
