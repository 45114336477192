import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import styles from './AccountSummary.styles';
import { decodeMobileDevice } from '../../utils/cryptography';
import MobileDeviceDetails from './MobileDeviceDetails';
import AccountTools from './AccountTools';

const AccountSummary = ({
  classes,
  enabled,
  username,
  deviceInfo,
  hasPushNotificationsEnabled,
  hasRefreshToken,
  handleRevokeRefreshToken
}) => {
  let decodedDevice = {};

  if (deviceInfo) {
    decodedDevice = decodeMobileDevice(deviceInfo);
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Email
            </Typography>
            <div className={classes.iconContainer}>
              <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
                {username}
              </Typography>
            </div>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Enabled
            </Typography>
            <div className={classes.iconContainer}>
              {enabled ? <CheckCircleOutlineIcon color="primary" /> : <HighlightOffIcon color="error" />}
            </div>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Push Notifications Enabled
            </Typography>
            <div className={classes.iconContainer}>
              {hasPushNotificationsEnabled ? <CheckCircleOutlineIcon color="primary" /> : <HighlightOffIcon color="error" />}
            </div>
          </div>
          <div>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Refresh Token
            </Typography>
            {hasRefreshToken ? (
              <div className={classes.iconContainer}>
                <CheckCircleOutlineIcon color="primary" />

                <IconButton onClick={handleRevokeRefreshToken} aria-label="delete">
                  <DeleteOutlineOutlined color="error" />
                </IconButton>
              </div>
            ) : (
              <div className={classes.iconContainer}>
                <HighlightOffIcon color="error" />
              </div>
            )}
          </div>
        </CardContent>
        {Object.keys(decodedDevice).length !== 0 && <MobileDeviceDetails decodedDevice={decodedDevice} />}
        <AccountTools email={username} />
      </Card>
    </>
  );
};

AccountSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceInfo: PropTypes.string,
  enabled: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  hasPushNotificationsEnabled: PropTypes.bool.isRequired,
  hasRefreshToken: PropTypes.bool,
  handleRevokeRefreshToken: PropTypes.func.isRequired
};

export default withStyles(styles)(AccountSummary);
