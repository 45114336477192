import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT
} from './constants';

const url = getApiUrl();

export function signOutAction() {
  return {
    type: SIGN_OUT
  };
}

export function signInAction(email, password) {
  return {
    types: [SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE],
    callAPI: () =>
      signIn(
        { username: email, password },
        '64374f8f-c578-4c4e-9ecc-6edcb3306683'
      )
  };
}

const requestAuthorization = (credentials, clientId) => {
  const params = `client_id=${clientId}&response_type=token&redirect_uri=${url}/security/resources/user`;
  return httpClient(`${url}/security/oauth/authorize?${params}`, {
    method: 'post',
    auth: { ...credentials }
  });
};

const authorize = (jsessionid, awselb) => {
  return httpClient(
    `${url}/security/oauth/authorize?user_oauth_approval=true&authorize=Authorize`,
    {
      method: 'post',
      headers: { jsessionid, awselb }
    }
  );
};

const signIn = (credentials, clientId, retryCounter) => {
  let retry = retryCounter || 2;
  return requestAuthorization(credentials, clientId).then(async (resp) => {
    if (resp.headers.jsessionid === undefined) {
      setTimeout(() => signIn(credentials, clientId, retry++), 1000);
    }
    return authorize(resp.headers.jsessionid, resp.headers.awselb);
  });
};
