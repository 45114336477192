import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import AdminContainer from './AdminContainerHOC';

import styles from './HeaderWithButton.styles';

const HeaderWithButton = ({ classes, headerText, buttonLabel, onClick, onClickSecondary, buttonLabelSecondary, ExtraButton, warning }) => {
  return (
    <div className={classes.header}>
      <Typography variant="h6" gutterBottom color="textPrimary">
        {headerText}
      </Typography>
      <div>
        {buttonLabel && (
          <AdminContainer type="internal">
            <Button className={classes.button} onClick={onClick} variant="contained" color="primary">
              {buttonLabel}
            </Button>
          </AdminContainer>
        )}
        {buttonLabelSecondary && (
          <AdminContainer type="internal">
            <Button onClick={onClickSecondary} variant="outlined" className={clsx(classes.button, warning && classes.buttonWarning)}>
              {buttonLabelSecondary}
            </Button>
          </AdminContainer>
        )}
        {ExtraButton && <ExtraButton />}
      </div>
    </div>
  );
};

HeaderWithButton.propTypes = {
  headerText: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  onClickSecondary: PropTypes.func,
  buttonLabelSecondary: PropTypes.string,
  warning: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  ExtraButton: PropTypes.elementType
};

export default withStyles(styles)(HeaderWithButton);
