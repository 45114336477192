import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import LoadingIndicator from '../../components/LoadingIndicator';
import WearablePermissions from './WearablePermissions';
import WearableTools from './WearableTools';

import { handleImageLoadError } from '../../utils/utils';

import styles from './WearableSummary.styles';

const WearableSummary = ({
  classes,
  wearableDetailsProcessing,
  wearableImage,
  asn,
  serialNumber,
  username,
  device,
  createdDate,
  modifiedDate,
  wearablePermissions
}) => {
  if (wearableDetailsProcessing) return <LoadingIndicator title="Getting wearable details" />;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {wearableImage && !wearableDetailsProcessing && (
          <img className={classes.image} src={wearableImage} alt="wearable" onError={handleImageLoadError} />
        )}

        <div>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Brand
          </Typography>
          <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
            {device?.brand}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Model
          </Typography>
          <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
            {device?.model}
          </Typography>
          {device?.variant?.color && (
            <>
              {' '}
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Description
              </Typography>
              <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
                {device.variant.color}
              </Typography>{' '}
            </>
          )}
        </div>
        <div>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Registered to
          </Typography>
          <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
            {username}
          </Typography>

          <Typography className={classes.title} color="textSecondary" gutterBottom>
            ASN
          </Typography>
          <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
            {asn}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            Serial Number
          </Typography>
          <Typography
            className={classes.detail}
            color={serialNumber ? 'textSecondary' : 'error'}
            variant="body2"
            component="p"
            gutterBottom
          >
            {serialNumber || 'NOT PAIRED'}
          </Typography>
        </div>
        <div className={classes.lastColumn}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Created date
          </Typography>
          <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
            {`${moment(createdDate).format('DD/MM/YYYY HH:mm:ss')}`}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Modified date
          </Typography>
          <Typography className={classes.detail} variant="body2" component="p" gutterBottom>
            {`${moment(modifiedDate).format('DD/MM/YYYY HH:mm:ss')}`}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Permissions
          </Typography>
          <WearablePermissions asn={asn} permissions={wearablePermissions} />
        </div>
      </CardContent>
      <WearableTools email={username} asn={asn} />
    </Card>
  );
};

WearableSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  wearableImage: PropTypes.string,
  wearableDetailsProcessing: PropTypes.bool,
  asn: PropTypes.string.isRequired,
  serialNumber: PropTypes.string,
  username: PropTypes.string.isRequired,
  device: PropTypes.shape({
    variant: PropTypes.shape({
      color: PropTypes.string
    }),
    model: PropTypes.string,
    brand: PropTypes.string
  }).isRequired,
  createdDate: PropTypes.string.isRequired,
  modifiedDate: PropTypes.string.isRequired,
  wearablePermissions: PropTypes.shape({
    canMobilePerso: PropTypes.bool
  })
};

export default withStyles(styles)(WearableSummary);
