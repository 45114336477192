import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  CREATE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPLOAD_CLIENT_REQUEST,
  UPLOAD_CLIENT_SUCCESS,
  UPLOAD_CLIENT_FAILURE,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_RESET,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  CREATE_CLIENT_RESET
} from './constants';

const defaultState = {
  clientsData: null,
  clientData: null,
  processing: false,
  error: '',
  processingCreateClient: false,
  errorCreateClient: '',
  processingUploadClient: false,
  errorUploadClient: '',
  createdClientData: null,
  updatedClientData: null,
  errorUpdateClient: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CLIENTS_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        clientsData: action.response.data
      };
    case GET_CLIENTS_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting clients data'
      };
    case GET_CLIENT_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        clientData: action.response.data
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'Error getting client data'
      };
    case CREATE_CLIENT_REQUEST:
      return {
        ...state,
        processingCreateClient: true,
        errorCreateClient: ''
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        processingCreateClient: false,
        errorCreateClient: '',
        createdClientData: action.response.data
      };
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        processingCreateClient: false,
        errorCreateClient: 'Error creating a new client.'
      };
    case UPLOAD_CLIENT_REQUEST:
      return {
        ...state,
        processingUploadClient: true,
        errorUploadClient: ''
      };
    case UPLOAD_CLIENT_SUCCESS:
      return {
        ...state,
        processingUploadClient: false,
        errorUploadClient: ''
      };
    case UPLOAD_CLIENT_FAILURE:
      return {
        ...state,
        processingUploadClient: false,
        errorUploadClient: 'Error uploading a client.'
      };
    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        processingUpdateClient: true,
        errorUpdateClient: ''
      };
    case UPDATE_CLIENT_RESET:
      return {
        ...state,
        updatedClientData: null,
        errorUpdateClient: ''
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        processingUpdateClient: false,
        errorUpdateClient: '',
        updatedClientData: action.response.data
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        processingUpdateClient: false,
        errorUpdateClient: 'Error updating the client.'
      };
    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        processingDeleteClient: true,
        errorDeleteClient: ''
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        processingDeleteClient: false,
        errorDeleteClient: ''
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        processingDeleteClient: false,
        errorDeleteClient: 'Error updating the client.'
      };

    case CREATE_CLIENT_RESET:
      return {
        ...state,
        createdClientData: null,
        errorCreateClient: ''
      };
    default:
      return state;
  }
}
