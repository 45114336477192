import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DashboardHOC from '../../components/DashboardHOC';
import DashboardOverview from './DashboardOverview';
import LoadingIndicator from '../../components/LoadingIndicator';

import {
  getDashboardAction,
  getFilteredWearablesAction
} from './state/dashboard-actions';

const DashboardPage = ({ history }) => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);

  const fetchFilteredWearables = (filter) => {
    dispatch(getFilteredWearablesAction(filter));
  };

  useEffect(() => {
    dispatch(getDashboardAction());
  }, [dispatch]);

  const {
    countData,
    processing,
    filteredWearables,
    processingFilteredWearables
  } = dashboardState;

  const title = 'Dashboard';
  return (
    <div>
      <DashboardHOC history={history} title={title}>
        {processing ? (
          <LoadingIndicator title="Loading Dashboard Data" />
        ) : (
          <DashboardOverview
            processingFilteredWearables={processingFilteredWearables}
            fetchFilteredWearables={(id) => fetchFilteredWearables(id)}
            filteredWearables={filteredWearables}
            countData={countData}
          />
        )}
      </DashboardHOC>
    </div>
  );
};

DashboardPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default DashboardPage;
