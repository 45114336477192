import {
  GET_WEARABLE_REQUEST,
  GET_WEARABLE_SUCCESS,
  GET_WEARABLE_FAILURE,
  GET_WEARABLE_BY_BRAND_REQUEST,
  GET_WEARABLE_BY_BRAND_SUCCESS,
  GET_WEARABLE_BY_BRAND_FAILURE,
  RESET_WEARABLE,
  RESET_SERVICE_HISTORY,
  GET_WEARABLE_HISTORY_REQUEST,
  GET_WEARABLE_HISTORY_FAILURE,
  GET_WEARABLE_HISTORY_SUCCESS,
  GET_SERVICE_HISTORY_REQUEST,
  GET_SERVICE_HISTORY_SUCCESS,
  GET_SERVICE_HISTORY_FAILURE,
  GET_SERVICE_CONFIG_REQUEST,
  GET_SERVICE_CONFIG_SUCCESS,
  GET_SERVICE_CONFIG_FAILURE,
  UPDATE_SERVICE_PERMS_REQUEST,
  UPDATE_SERVICE_PERMS_SUCCESS,
  UPDATE_SERVICE_PERMS_FAILURE,
  SET_INITIAL_SERVICE_PERM,
  UPDATE_WEARABLE_PERMS_REQUEST,
  UPDATE_WEARABLE_PERMS_SUCCESS,
  UPDATE_WEARABLE_PERMS_FAILURE,
  CHANGE_WEARABLE_EMAIL_REQUEST,
  CHANGE_WEARABLE_EMAIL_SUCCESS,
  CHANGE_WEARABLE_EMAIL_FAILURE
} from './constants';

const defaultState = {
  wearableData: null,
  wearablesData: null,
  wearableHistory: null,
  processing: false,
  processingHistory: false,
  processingServiceConfig: false,
  processingServicePerms: false,
  processingWearablePerms: false,
  serviceHistory: null,
  servicePerms: null,
  wearablePerms: null,
  processingServiceHistory: false,
  errorServiceHistory: '',
  errorWearableHistory: '',
  errorServicePerms: '',
  errorWearablePerms: '',
  error: '',
  processingChangeWearableEmail: false,
  errorChangeWearableEmail: '',
  wearableEmailChanged: false
};

export function getWearableByBrandReducer(state = {}, action) {
  switch (action.type) {
    case GET_WEARABLE_BY_BRAND_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_WEARABLE_BY_BRAND_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        data: action.response.data
      };
    case GET_WEARABLE_BY_BRAND_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error?.message || 'error getting wearable by brand'
      };
    default:
      return state;
  }
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_WEARABLE_REQUEST:
      return {
        ...state,
        serviceConfig: null,
        processing: true,
        wearableEmailChanged: false,
        error: ''
      };
    case GET_WEARABLE_SUCCESS:
      if (Array.isArray(action.response.data))
        return {
          ...state,
          processing: false,
          error: '',
          wearablesData: action.response.data
        };
      else
        return {
          ...state,
          processing: false,
          error: '',
          wearableData: action.response.data
        };
    case GET_WEARABLE_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error || 'Error getting wearable by asn'
      };
    case GET_WEARABLE_HISTORY_REQUEST:
      return {
        ...state,
        processingHistory: true,
        errorWearableHistory: ''
      };
    case GET_WEARABLE_HISTORY_FAILURE:
      return {
        ...state,
        processingHistory: false,
        errorWearableHistory: 'Error getting wearable history'
      };
    case GET_WEARABLE_HISTORY_SUCCESS:
      return {
        ...state,
        processingHistory: false,
        errorWearableHistory: '',
        wearableHistory: action.response.data?.cloudWatchLogs
      };
    case GET_SERVICE_HISTORY_REQUEST:
      return {
        ...state,
        processingServiceHistory: true,
        errorServiceHistory: ''
      };
    case GET_SERVICE_HISTORY_FAILURE:
      return {
        ...state,
        processingServiceHistory: false,
        errorServiceHistory: 'Error getting service history'
      };
    case GET_SERVICE_HISTORY_SUCCESS:
      return {
        ...state,
        processingServiceHistory: false,
        errorServiceHistory: '',
        servicesHistory: {
          ...state.serviceHistory,
          [action.token]: action.response.data?.cloudWatchLogs
        },
        serviceHistory: action.response.data?.cloudWatchLogs
      };
    case GET_SERVICE_CONFIG_REQUEST:
      return {
        ...state,
        processingServiceConfig: true,
        errorServiceConfig: ''
      };
    case GET_SERVICE_CONFIG_FAILURE:
      return {
        ...state,
        processingServiceConfig: false,
        errorServiceConfig: 'Error getting service config'
      };
    case GET_SERVICE_CONFIG_SUCCESS:
      return {
        ...state,
        processingServiceConfig: false,
        errorServiceConfig: '',
        serviceConfig: action.response.data
      };

    case UPDATE_SERVICE_PERMS_REQUEST:
      return {
        ...state,
        processingServicePerms: true,
        errorServicePerms: '',
        servicePerms: null
      };
    case UPDATE_SERVICE_PERMS_FAILURE:
      return {
        ...state,
        processingServicePerms: false,
        errorServicePerms: 'Error updating service permissions'
      };
    case UPDATE_SERVICE_PERMS_SUCCESS:
      return {
        ...state,
        processingServicePerms: false,
        errorServicePerms: '',
        servicePerms: action.payload
      };
    case SET_INITIAL_SERVICE_PERM:
      return {
        ...state,
        servicePerms: action.payload
      };
    case UPDATE_WEARABLE_PERMS_REQUEST:
      return {
        ...state,
        processingWearablePerms: true,
        errorWearablePerms: '',
        wearablePerms: null
      };
    case UPDATE_WEARABLE_PERMS_FAILURE:
      return {
        ...state,
        processingWearablePerms: false,
        errorWearablePerms: 'Error updating wearable permissions'
      };
    case UPDATE_WEARABLE_PERMS_SUCCESS:
      return {
        ...state,
        processingWearablePerms: false,
        errorWearablePerms: '',
        wearablePerms: action.response.data
      };
    case CHANGE_WEARABLE_EMAIL_REQUEST:
      return {
        ...state,
        processingChangeWearableEmail: true,
        errorChangeWearableEmail: '',
        wearableEmailChanged: false
      };
    case CHANGE_WEARABLE_EMAIL_SUCCESS:
      return {
        ...state,
        processingChangeWearableEmail: false,
        errorChangeWearableEmail: '',
        wearableEmailChanged: true
      };
    case CHANGE_WEARABLE_EMAIL_FAILURE:
      return {
        ...state,
        processingChangeWearableEmail: false,
        errorChangeWearableEmail: action.error || 'Error changing wearable email'
      };
    case RESET_WEARABLE:
      return defaultState;
    case RESET_SERVICE_HISTORY:
      return {
        ...state,
        serviceHistory: null
      };
    default:
      return state;
  }
}
