const styles = {
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderTop: '1px solid #ddd'
  },
  title: {
    fontSize: 14
  },
  detail: {
    fontSize: 16
  },
  mobileDeviceDetailsContainer: {
    width: '90%'
  },
  mobileDeviceDetails: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
};

export default styles;
