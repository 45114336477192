import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import imageNotFound from '../../../images/imageNotFound.png';

import styles from './ThemePalette.styles';

const ThemePalette = ({ classes, theme, logo }) => {
  function handleImageLoadError(ev) {
    ev.target.src = imageNotFound;
  }

  const renderTheme = () => {
    return Object.entries(theme).map(([key, value], i) => (
      <div key={i} className={classes.valuesContainer}>
        <div className={classes.values}>
          <Typography>
            <strong>{key}</strong>
          </Typography>
        </div>
        {key === 'font' ? (
          <Typography>{value}</Typography>
        ) : (
          <div className={classes.colorsContainer}>
            <div>
              <Typography>{value}</Typography>
            </div>
            <div
              style={{
                backgroundColor: value
              }}
              className={classes.colorBox}
            ></div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className={classes.mainContainer}>
      {logo && (
        <div>
          <img
            alt="entity logo"
            onError={handleImageLoadError}
            className={classes.logo}
            src={logo}
          />
        </div>
      )}
      <br />
      {renderTheme()}
    </div>
  );
};

ThemePalette.propTypes = {
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  logo: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(styles)(ThemePalette);
