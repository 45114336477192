import moment from 'moment';

import {
  GET_SYSTEM_ERROR_LOGS_REQUEST,
  GET_SYSTEM_ERROR_LOGS_SUCCESS,
  GET_SYSTEM_ERROR_LOGS_FAILURE,
  GET_SYSTEM_MAINTENANCE_REQUEST,
  GET_SYSTEM_MAINTENANCE_SUCCESS,
  GET_SYSTEM_MAINTENANCE_FAILURE,
  POST_SYSTEM_MAINTENANCE_REQUEST,
  POST_SYSTEM_MAINTENANCE_SUCCESS,
  POST_SYSTEM_MAINTENANCE_FAILURE,
  POST_SYSTEM_MAINTENANCE_WHITELISTS_REQUEST,
  POST_SYSTEM_MAINTENANCE_WHITELISTS_SUCCESS,
  POST_SYSTEM_MAINTENANCE_WHITELISTS_FAILURE
} from './constants';

const formatErrorLogs = (log) =>
  log?.map((e) => ({
    ...e,
    event: e.event.replace(/_/g, ' '),
    timestamp: moment(e.timestamp * 1000).format('DD/MM/YY HH:mm:ss'),
    clientUser: `${e.client || ''} ${e.client && e.user ? '/' : ''} ${e.user || ''}`
  }));

const defaultState = {
  errorLogs: null,
  processing: false,
  error: '',
  maintenanceMode: false,
  processingGetSystemMaintenance: false,
  errorGetSystemMaintenance: '',
  processingPostSystemMaintenance: false,
  errorPostSystemMaintenance: '',
  processingPostSystemMaintenanceWhitelists: false,
  errorPostSystemMaintenanceWhitelists: ''
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_SYSTEM_ERROR_LOGS_REQUEST:
      return {
        ...state,
        processing: true,
        error: ''
      };
    case GET_SYSTEM_ERROR_LOGS_SUCCESS:
      return {
        ...state,
        processing: false,
        error: '',
        errorLogs: {
          enrolmentLogs: formatErrorLogs(action.response.data?.enrolmentLogs),
          wearableLogs: formatErrorLogs(action.response.data?.wearableLogs)
        }
      };
    case GET_SYSTEM_ERROR_LOGS_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error || 'Error getting error logs'
      };
    case GET_SYSTEM_MAINTENANCE_REQUEST:
      return {
        ...state,
        processingGetSystemMaintenance: true,
        errorGetSystemMaintenance: ''
      };
    case GET_SYSTEM_MAINTENANCE_SUCCESS:
      return {
        ...state,
        processingGetSystemMaintenance: false,
        errorGetSystemMaintenance: '',
        maintenanceMode: action.response.data?.maintenance,
        usersWhitelist: action.response.data?.whitelists?.users,
        clientsWhitelist: action.response.data?.whitelists?.clients
      };
    case GET_SYSTEM_MAINTENANCE_FAILURE:
      return {
        ...state,
        processingGetSystemMaintenance: false,
        errorGetSystemMaintenance: action.error || 'Error getting system maintenance'
      };
    case POST_SYSTEM_MAINTENANCE_REQUEST:
      return {
        ...state,
        processingPostSystemMaintenance: true,
        errorPostSystemMaintenance: ''
      };
    case POST_SYSTEM_MAINTENANCE_SUCCESS:
      return {
        ...state,
        processingPostSystemMaintenance: false,
        errorPostSystemMaintenance: '',
        maintenanceMode: action.response.data?.maintenance
      };
    case POST_SYSTEM_MAINTENANCE_FAILURE:
      return {
        ...state,
        processingPostSystemMaintenance: false,
        errorPostSystemMaintenance: action.error || 'Error updating system maintenance'
      };
    case POST_SYSTEM_MAINTENANCE_WHITELISTS_REQUEST:
      return {
        ...state,
        processingPostSystemMaintenanceWhitelists: true,
        errorPostSystemMaintenanceWhitelists: ''
      };
    case POST_SYSTEM_MAINTENANCE_WHITELISTS_SUCCESS:
      return {
        ...state,
        processingPostSystemMaintenanceWhitelists: false,
        errorPostSystemMaintenanceWhitelists: '',
        usersWhitelist: action.response.data?.whitelists?.users,
        clientsWhitelist: action.response.data?.whitelists?.clients
      };
    case POST_SYSTEM_MAINTENANCE_WHITELISTS_FAILURE:
      return {
        ...state,
        processingPostSystemMaintenanceWhitelists: false,
        errorPostSystemMaintenanceWhitelists: action.error || 'Error updating system maintenance whitelist'
      };
    default:
      return state;
  }
}
