const styles = {
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderTop: '1px solid #ddd'
  },
  accountToolsContainer: {
    width: '90%'
  },
  toolsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  button: { marginLeft: -5, marginRight: 5 }
};

export default styles;
