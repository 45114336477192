const styles = (theme) => ({
  root: {
    minWidth: 275,
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  image: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    objectFit: 'contain'
  },
  title: {
    fontSize: 14
  },
  detail: {
    fontSize: 16
  },
  loading: {
    alignSelf: 'center'
  },
  lastColumn: {
    minWidth: 190,
    minHeight: 180
  }
});

export default styles;
