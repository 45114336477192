import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_ENTITIES_REQUEST,
  GET_ENTITIES_SUCCESS,
  GET_ENTITIES_FAILURE,
  GET_ENTITY_REQUEST,
  GET_ENTITY_SUCCESS,
  GET_ENTITY_FAILURE,
  POST_ENTITY_REQUEST,
  POST_ENTITY_SUCCESS,
  POST_ENTITY_FAILURE,
  UPDATE_ENTITY_REQUEST,
  UPDATE_ENTITY_SUCCESS,
  UPDATE_ENTITY_FAILURE,
  CREATE_ENTITY_RESET
} from './constants';

const url = getApiUrl();

export function getEntitiesAction() {
  return {
    types: [GET_ENTITIES_REQUEST, GET_ENTITIES_SUCCESS, GET_ENTITIES_FAILURE],
    callAPI: () =>
      httpClient(`${url}/admin/entities`, {
        method: 'get'
      })
  };
}

export function getEntityAction(id) {
  return {
    types: [GET_ENTITY_REQUEST, GET_ENTITY_SUCCESS, GET_ENTITY_FAILURE],
    callAPI: () =>
      httpClient(`${url}/admin/entities/${id}`, {
        method: 'get'
      })
  };
}

export function createEntityAction(
  { name, logo, description, storeLink, contact, coverImage, excludedFromWebsite, featuredOnWebsite },
  theme,
  type
) {
  const data = {
    name,
    logo,
    description,
    storeLink,
    contact,
    coverImage,
    excludedFromWebsite,
    featuredOnWebsite,
    theme,
    type
  };

  return {
    types: [POST_ENTITY_REQUEST, POST_ENTITY_SUCCESS, POST_ENTITY_FAILURE],
    callAPI: () =>
      httpClient(`${url}/admin/entities`, {
        method: 'post',
        data: {
          name,
          logo,
          description,
          storeLink,
          contact,
          coverImage,
          excludedFromWebsite,
          featuredOnWebsite,
          theme,
          type
        }
      })
  };
}

export function updateEntityAction(entityId, data) {
  return {
    types: [UPDATE_ENTITY_REQUEST, UPDATE_ENTITY_SUCCESS, UPDATE_ENTITY_FAILURE],
    callAPI: () =>
      httpClient(`${url}/admin/entities/${entityId}`, {
        method: 'put',
        data
      })
  };
}

export function resetCreatedEntity() {
  return {
    type: CREATE_ENTITY_RESET
  };
}
