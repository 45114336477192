export const GET_ENTITIES_REQUEST = 'GET_ENTITIES_REQUEST';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';
export const GET_ENTITIES_FAILURE = 'GET_ENTITIES_FAILURE';

export const GET_ENTITY_REQUEST = 'GET_ENTITY_REQUEST';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_FAILURE = 'GET_ENTITY_FAILURE';

export const POST_ENTITY_REQUEST = 'POST_ENTITY_REQUEST';
export const POST_ENTITY_SUCCESS = 'POST_ENTITY_SUCCESS';
export const POST_ENTITY_FAILURE = 'POST_ENTITY_FAILURE';

export const UPDATE_ENTITY_REQUEST = 'UPDATE_ENTITY_REQUEST';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_FAILURE = 'UPDATE_ENTITY_FAILURE';

export const CREATE_ENTITY_RESET = 'CREATE_ENTITY_RESET';
