import httpClient from '../../../axios';
import getApiUrl from '../../../config/url';

import {
  GET_OEMS_REQUEST,
  GET_OEMS_SUCCESS,
  GET_OEMS_FAILURE
} from './constants';

const url = getApiUrl();

export function getOemsAction() {
  return {
    types: [GET_OEMS_REQUEST, GET_OEMS_SUCCESS, GET_OEMS_FAILURE],
    callAPI: () => getOems()
  };
}

const getOems = () => {
  return httpClient(`${url}/admin/oems`, {
    method: 'get'
  });
};
